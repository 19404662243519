import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    background: ${theme.palette.background.paper};

    ${theme.breakpoints.down('sm')} {
      background: ${theme.palette.background.default};
    }
  `};
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    display: block;
    margin: 26px 25px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ${theme.breakpoints.down('sm')} {
      overflow: scroll;
      height: 100%;
      margin: 0;
      padding: ${layoutMargin}px 0 0 17px;
      border-radius: ${layoutChildrenRadius}px;
      background: ${theme.palette.background.paper};
    }
  `};
`;

export const Instruments = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    margin-top: 40px;

    ${theme.breakpoints.down('sm')} {
      justify-content: center;
      margin-top: 20px;
    }
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    min-height: 25px;
  `};
`;

export const SubTitle = styled(Wrapper)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.gray[400]};
    margin-top: 10px;
    width: 85%;

    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `};
`;
