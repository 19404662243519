import { getIconBaseProps } from './utils';

export const tableSubRowIndicator = {
  ...getIconBaseProps('tableSubRowIndicator'),
  width: '5px',
  height: '5px',
  viewBox: '0 0 5 5',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M3.0625 2.125L4 3.0625L3.0625 4'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 1V2.3125C1 2.51141 1.07902 2.70218 1.21967 2.84283C1.36032 2.98348 1.55109 3.0625 1.75 3.0625H4'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
