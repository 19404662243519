import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 11px 0 8px 0;
    border-bottom: 1px solid ${theme.palette.background.default};
  `};
`;

export const Header = styled(Wrapper)`
  ${() => css`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 17px 0 17px;
  `};
`;

export const Toggle = styled(Button)`
  ${() => css`
    padding: 5px;
    rotate: 90deg;
    svg {
      width: 13px;
      height: 10px;
    }
  `};
`;
