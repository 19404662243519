import { types, SnapshotIn } from 'mobx-state-tree';

import { EIndicators } from '@trader/types';

const indicatorModel = types
  .model('indicatorModel', {
    name: types.union(
      types.literal(EIndicators.MACD),
      types.literal(EIndicators.VWAP_PRESSER),
      types.literal(EIndicators.VWAP_DIFF)
    ),
    isActive: types.boolean,
    title: types.string,
  })
  .actions(state => ({
    toggleActive: () => {
      state.isActive = !state.isActive;
    },
  }));

type TIndicatorSnapshotInModel = SnapshotIn<typeof indicatorModel>;

const initialState: TIndicatorSnapshotInModel[] = [
  { name: EIndicators.MACD, title: 'MACD', isActive: false },
  // { name: EIndicators.VWAP_PRESSER, title: 'VWAP_PRESSER', isActive: false },
  // { name: EIndicators.VWAP_DIFF, title: 'VWAP_DIFF', isActive: false },
];

export const indicatorsModel = types.optional(
  types.array(indicatorModel),
  initialState
);
