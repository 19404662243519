import styled, { css } from 'styled-components';

import { Button } from '@trader/components';

export const DownloadButton = styled(Button)`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    padding: 10px 20px;
    border-radius: 5px;
    background-color: ${theme.palette.black.main}!important;
    color: ${theme.palette.white.main}!important;
  `};
`;
