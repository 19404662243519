/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import { isMuliBandsEnv } from '@trader/constants';
import { formatDate } from '@trader/utils';
import { IChartingLibraryWidget } from '../../charting_library';
import { useMst } from '@trader/store';

export const useDataBox = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();

  useEffect(() => {
    if (isMuliBandsEnv && isMuliBands) {
      widget?.current?.onChartReady(() => {
        widget?.current
          ?.activeChart()
          .crossHairMoved()
          .subscribe(null, obj => {
            const vol =
              obj?.entityValues &&
              Object.values(obj?.entityValues)[0]?.values.find(
                v => v.title === 'Vol'
              );
            const volume = vol && vol?.value !== '0' ? vol.value : '';

            obj?.entityValues &&
              store.pages.muliBands.strip.updateMovingValues([
                {
                  title: 'Time',
                  value: formatDate(obj.time, 'hh:mm:ss'),
                },
                ...Object.values(obj?.entityValues)[0]?.values,
                ...Object.values(obj?.entityValues)[1]?.values.map(val => ({
                  title: val.title,
                  value: volume ? val.value : '0',
                })),
              ]);
          });
      });
    }
  }, [widget?.current, isMuliBands]);
};
