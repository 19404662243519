import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { Controller, Wrapper } from '@trader/components';
import { defSl, maxSl, minQuantity } from '@trader/constants';

interface ISl {
  isSl: boolean;
  sl: string;
}

export const StopLoss: React.FC = () => {
  const { translate } = useI18next();

  const { control, getValues } = useFormContext<ISl>();

  const [isSl, setIsSl] = useState(getValues().isSl);

  return (
    <>
      <Controller
        type='switcherField'
        label={translate('COMMON.LABELS.STOP_LOSS')}
        name='isSl'
        control={control}
        onCustomChange={event => setIsSl(event.target.checked)}
      />
      {isSl && (
        <Wrapper margin='8px 0 -8px 0!important'>
          <Controller
            type='tradingInput'
            shouldCheckActivityOfButtons
            customLabel={translate('COMMON.LABELS.STOP_LOSS')}
            minValue={minQuantity}
            maxValue={maxSl}
            step={Number(defSl)}
            fixDigitAfterDot={0}
            control={control}
            name='sl'
          />
        </Wrapper>
      )}
    </>
  );
};
