import { useI18next } from '@trader/services';
import { Icon, Tooltip } from '@trader/components';

export const MarketStatusIcon = ({
  isUnavailable,
  openIn,
}: {
  isUnavailable: boolean;
  openIn: string;
}) => {
  const { translate } = useI18next();

  if (isUnavailable) {
    return (
      <Tooltip
        title={
          !openIn
            ? translate('TOOLTIPS.INSTRUMENT_CLOSED_DUE_TO_DAY_OFF')
            : translate('COMMON.LABELS.MARKET_IS_CLOSED', {
                openIn,
              })
        }
      >
        <Icon iconType='marketClosed' style={{ marginTop: '10px' }} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={translate('COMMON.LABELS.MARKET_IS_OPENED')}>
      <Icon iconType='marketOpened' style={{ marginTop: '10px' }} />
    </Tooltip>
  );
};
