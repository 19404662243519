import React from 'react';
import Stack from '@mui/material/Stack';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { useI18next } from '@trader/services';
import { TPlaceOrderSide } from '@trader/types';
import { EConditionType, useBusinessLogic } from '@trader/hooks';

import { IActionButtons } from '../index';
import * as Styled from './styled';

export const BetaDesign: React.FC<IActionButtons> = observer(
  ({
    direction = 'row',
    actionsStyles,
    actionButtonStyles,
    buttonPriceStyles,
    buttonVariant = 'contained',
    ...rest
  }) => {
    const { translate, currentLng } = useI18next();

    const store = useMst();

    const { applyCondition } = useBusinessLogic(
      EConditionType.RegistrationCompleted
    );
    const { applyCondition: applyTradingAllowedCondition } = useBusinessLogic(
      EConditionType.TradingAllowed
    );

    const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

    const trading = store.trading.getTrading('createOrder');
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const instrument = store.entities.instruments.get<TInstrumentEntity>(
      rest.symbol
    );

    const handleClick = (side: TPlaceOrderSide) => () => {
      store.ui.sidebar.openRightBarOrderSection();
      if (
        applyCondition().status !== 'failed' &&
        !instrument.disabledByMarketNews &&
        applyTradingAllowedCondition().status !== 'failed' &&
        instrument.tradingStatus !== 'CLOSE_ONLY' &&
        !isDailyTargetGoalReached
      ) {
        trading.getInstrumentReferenceAsync.run({
          symbol: rest.symbol,
          side,
        });
      }
    };

    return (
      <Stack
        className='action_buttons'
        style={actionsStyles}
        direction={direction}
        spacing='5px'
      >
        <Styled.Button
          $isAsk
          $currentLng={currentLng}
          disabled={
            isInvestmentAccProduct || !instrument.allowedOperations().sell.open
          }
          variant={buttonVariant}
          style={actionButtonStyles}
          hasChildrenWrapper={false}
          onClick={handleClick('Sell')}
        >
          <Styled.Content>
            <Styled.Label component='div'>
              {translate('COMMON.LABELS.SELL')}
            </Styled.Label>
            <Styled.Price style={buttonPriceStyles} component='div'>
              {formatByPipSize(instrument?.ask || rest.ask, rest.pipSize)}
            </Styled.Price>
          </Styled.Content>
        </Styled.Button>
        <Styled.Button
          $currentLng={currentLng}
          variant={buttonVariant}
          disabled={!instrument.allowedOperations().buy.open}
          style={actionButtonStyles}
          hasChildrenWrapper={false}
          onClick={handleClick('Buy')}
        >
          <Styled.Content>
            <Styled.Label component='div'>
              {translate('COMMON.LABELS.BUY')}
            </Styled.Label>
            <Styled.Price style={buttonPriceStyles} component='div'>
              {formatByPipSize(instrument?.bid || rest.bid, rest.pipSize)}
            </Styled.Price>
          </Styled.Content>
        </Styled.Button>
      </Stack>
    );
  }
);
