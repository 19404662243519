import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${modalHorizontalPadding}px 25px ${modalHorizontalPadding}px;
  `}
`;

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `}
`;
export const Main = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin: 26px 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const DmaHeader = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px ${modalHorizontalPadding}px;
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;
export const Info = styled(Typography)`
  ${({ theme }) => css`
    margin-left: 8px;
    font-weight: ${theme.typography.fontWeightMedium};
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    min-width: max-content;
    color: ${theme.palette.gray.dark};
  `}
`;

export const Price = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    justify-content: center;
    align-items: center;
  `}
`;
export const FreMargin = styled(Typography)`
  ${({ theme }) => css`
    width: 100%;
    font-size: ${theme.typography.large.fontSize};
    padding: 0 ${modalHorizontalPadding}px;
    align-items: flex-start;
    margin: 15px 0 5px 0;
  `}
`;
