import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  BalanceDetails,
  Footer,
  Icon,
  Time,
  Tooltip,
} from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';
import { EChallengeMode } from '@trader/types';

import { useServerNetworkQuality } from './hooks/useServerNetworkQuality';
import * as Styled from './styled';

export const BottomBar: React.FC = observer(() => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { translate } = useI18next();
  const { networkQuality, networkQualityDescription } =
    useServerNetworkQuality();

  const store = useMst();
  const user = store.user;
  const account = user.activeTradingAcc();
  const activeChallenge = store.user.getActiveAccountChallenge();
  const isDemoAccount = account?.accountType === EAccountTypeNames.Demo;
  const isBetaDesign = store.app.isBetaDesignEnabled();

  const handleFullScreen = useCallback(() => {
    if (
      !document.fullscreenElement &&
      document.documentElement.requestFullscreen
    ) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  }, []);

  return (
    <Footer>
      <Styled.DesktopContainer>
        <Styled.Block>
          <Styled.User $isNoPaddingLeft>
            {user.profile.firstName} {user.profile.lastName}
          </Styled.User>
          <Styled.User>
            {translate('COMMON.LABELS.USER_ID')}:
            <span>
              {isChallengeAccountType
                ? account?.tradingId
                : account?.platformLogin}
            </span>
          </Styled.User>
          {(!isChallengeAccountType ||
            activeChallenge?.mode === EChallengeMode.Practice) && (
            <Styled.User>
              <Styled.AccountType
                $isDemo={
                  isDemoAccount ||
                  activeChallenge?.mode === EChallengeMode.Practice
                }
              >
                {activeChallenge?.mode === EChallengeMode.Practice
                  ? translate('COMMON.LABELS.PRACTICE')
                  : translate(
                      `COMMON.LABELS.${account?.accountType.toUpperCase()}`
                    )}
              </Styled.AccountType>
            </Styled.User>
          )}
        </Styled.Block>
        <Styled.Block>
          {networkQuality && isBetaDesign && (
            <Styled.UserAction>
              <Tooltip title={networkQualityDescription}>
                <Styled.NetworkQuality $networkQuality={networkQuality}>
                  <Icon iconType='networkQuality' />
                </Styled.NetworkQuality>
              </Tooltip>
            </Styled.UserAction>
          )}
          <Styled.UserAction>
            <span>{translate('COMMON.LABELS.CURRENT_PLATFORM_TIME')}:</span>{' '}
            <Time />
          </Styled.UserAction>
          <Styled.UserAction className='userAction-fullscreen'>
            <span className='fullscreen'>
              {isFullScreen
                ? translate('COMMON.LABELS.PRESS_FOR_EXIT_FULLSCREEN')
                : translate('COMMON.LABELS.PRESS_FOR_FULLSCREEN')}
            </span>
            <Styled.FullScreenBtn
              onClick={handleFullScreen}
              iconType={isFullScreen ? 'fullScreenExit' : 'fullScreen'}
            />
          </Styled.UserAction>
        </Styled.Block>
      </Styled.DesktopContainer>
      <Styled.BalanceDetails>
        <BalanceDetails isShowDetails />
      </Styled.BalanceDetails>
    </Footer>
  );
});
