import React from 'react';
import { observer } from 'mobx-react-lite';

import { MoveableWrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import { Bands, HidingInfo, Order, DataBox, General } from './components';

import * as Styled from './styled';

export const RightMenu: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const dataBoxRef = React.useRef<HTMLDivElement>(null);

  const isDataBoxOpen = store.pages.muliBands.dataBox.isOpen;

  return (
    <Styled.Root>
      <Bands />
      <HidingInfo title={translate('MULI_BANDS.ORDER')} children={<Order />} />
      <HidingInfo
        title={translate('MULI_BANDS.GENERAL')}
        children={<General />}
      />
      {isDataBoxOpen && <DataBox ref={dataBoxRef} />}
      {isDataBoxOpen && <MoveableWrapper elementRefToMove={dataBoxRef} />}
    </Styled.Root>
  );
});
