import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { getLimitPrice } from '@trader/utils';
import { useI18next } from '@trader/services';
import { adapterLineStyle, emptyFn } from '@trader/constants';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';

const orderLineLength = 20;
let orderLineAdapter: IOrderLineAdapter | undefined = undefined;

export const useDisplayPendingOrder = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken;

  const trading = store.trading.getTrading('createOrder');

  const side = trading.side;

  const symbol = trading.instrument?.symbol as string;

  const shouldDisplayPendingOrder =
    !!side && trading.orderType !== 'Market' && store.app.isBetaDesignEnabled();

  const color =
    side === 'Sell'
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green;

  const createOrder = (price: string) => {
    orderLineAdapter = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setText(translate('COMMON.LABELS.PENDING'))
      .setBodyTextColor(theme.palette.common.black)
      .setBodyBorderColor(color)
      .setBodyBackgroundColor(theme.palette.background.paper)
      .setLineColor(color)
      .setLineLength(orderLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity('')
      .onMoving(function () {
        trading.updatePriceFromChart(this.getPrice().toString());
      })
      .onMove(function () {
        trading.updatePriceFromChart(this.getPrice().toString());
      })
      .setPrice(Number(price));
  };

  const clearPendingOrder = useCallback(() => {
    orderLineAdapter?.remove();
    orderLineAdapter = undefined;
  }, []);

  // initialize pending order
  useEffect(() => {
    if (shouldDisplayPendingOrder) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          createOrder(trading.price);
        }
      });
    }
    return () => clearPendingOrder();
  }, [symbol, shouldDisplayPendingOrder, idToken]);

  // update pending order
  useEffect(() => {
    if (shouldDisplayPendingOrder) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady && orderLineAdapter) {
          if (!Number(trading.price) && trading.instrument) {
            const price = getLimitPrice(
              trading.instrument?.stopsLevel,
              trading.instrument?.tickSize,
              trading.instrument?.pipSize,
              trading.instrument?.bid,
              trading.instrument?.ask,
              trading.side
            );

            orderLineAdapter.setPrice(
              trading.priceEditMode === 'Chart'
                ? Number(trading.chartPrice)
                : Number(price)
            );
          } else {
            orderLineAdapter.setPrice(
              trading.priceEditMode === 'Chart'
                ? Number(trading.chartPrice)
                : Number(trading.price)
            );
          }
          orderLineAdapter.setBodyBorderColor(color);
          orderLineAdapter.setLineColor(color);
        }
      });
    }
  }, [
    shouldDisplayPendingOrder,
    trading.price,
    trading.side,
    trading.priceEditMode,
  ]);

  useEffect(() => {
    if (!shouldDisplayPendingOrder) {
      clearPendingOrder();
    }
  }, [shouldDisplayPendingOrder]);
};
