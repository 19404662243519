import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    background: ${theme.palette.background.paper};

    ${theme.breakpoints.down('sm')} {
      background: ${theme.palette.background.default};
    }
  `};
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 26px 25px;

    ${theme.breakpoints.down('sm')} {
      margin: 0;
      padding-top: ${layoutMargin}px;
      border-radius: ${layoutChildrenRadius}px;
      background: ${theme.palette.background.paper};
    }
  `};
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    margin-bottom: 10px;

    ${theme.breakpoints.down('sm')} {
      padding-left: 17px;
    }
  `};
`;
