import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { modalHorizontalPadding } from '@trader/constants';

interface IRoot {
  $isIframeOpen?: boolean;
  height?: number;
}

export const Root = styled(Wrapper)<IRoot>`
  ${({ $isIframeOpen, theme, height }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 410px;
    min-height: 380px;

    ${conditionApply(
      [$isIframeOpen],
      css`
        width: 735px;
        height: 535px;
        min-height: auto;
      `
    )}
    ${conditionApply(
      [height],
      css`
        height: ${height}px;
      `
    )}
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const AddFunds = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border-radius: 5px;
    color: ${theme.palette.common.white};

    &:hover {
      background: ${theme.palette.primary.main};
    }
  `}
`;
export const Funds = styled(Wrapper)`
  ${() => css`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    padding: 0px ${modalHorizontalPadding}px;
    justify-content: space-between;
    margin-bottom: 10px;
  `}
`;

export const Field = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 0px ${modalHorizontalPadding}px;
  `}
`;

interface IFund {
  $isActive: boolean;
}

export const Fund = styled(Button)<IFund>`
  ${({ theme, $isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 45px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.black};
    border: 1px solid ${theme.palette.background.default};
    background: transparent;

    ${conditionApply(
      [$isActive],
      css`
        border: none;
        color: ${theme.palette.common.white};
        background: ${theme.palette.primary.main};

        &:hover {
          background: ${theme.palette.primary.main};
        }
      `
    )}
    ${theme.breakpoints.down('sm')} {
      width: 45%;
    }
  `}
`;
export const ExtraInformation = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.gray[100]};
    width: 75%;
    white-space: break-spaces;
    text-align: center;
    margin-top: 15px;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;
export const Psps = styled(Wrapper)`
  ${() => css`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    padding: 0px ${modalHorizontalPadding}px;
    justify-content: space-between;
  `}
`;
export const Psp = styled(Typography)`
  ${({ theme }) => css`
    width: 100%;
    padding: 20px 20px 13px;
    border: 1px solid ${theme.palette.background.default};
    border-radius: 5px;
    margin-bottom: 17px;
    cursor: pointer;
  `}
`;
export const PspTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.base.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    width: 100%;
    padding-bottom: 10px;
  `}
`;
