import React, { useState } from 'react';

import { Icon, Popover, Typography } from '@trader/components';

import { EDateFilterTypes, TDateFilterInterval } from '@trader/types';
import { calculateDateInterval } from '@trader/utils';
import { useI18next } from '@trader/services';

import { FilterList } from '../table-column-date-filter/components/filterList';
import { CustomDateSelection } from '../table-column-date-filter/components/customDateSelection';

import * as Styled from './styled';

interface IDateFilter {
  initialValues?: {
    filterType?: EDateFilterTypes;
    customInterval?: TDateFilterInterval;
  };
  onApply: (interval: TDateFilterInterval) => void;
}

export const DateFilter: React.FC<IDateFilter> = ({
  initialValues,
  onApply,
}) => {
  const { translate } = useI18next();
  const [isShowContent, setIsShowContent] = useState(false);
  const [selectedFilterType, setSelectedFilterType] =
    useState<EDateFilterTypes | null>(initialValues?.filterType || null);
  const [customInterval, setCustomInterval] =
    useState<TDateFilterInterval | null>(initialValues?.customInterval || null);

  const filterTypeTranslationMap: Record<EDateFilterTypes, string> = {
    thisMonth: translate('FILTERS.THIS_MONTH'),
    pastMonth: translate('FILTERS.PAST_MONTH'),
    thisQuarter: translate('FILTERS.THIS_QUARTER'),
    pastQuarter: translate('FILTERS.PAST_QUARTER'),
    thisYear: translate('FILTERS.THIS_YEAR'),
    pastYear: translate('FILTERS.PAST_YEAR'),
    // TODO: (av) Add translation.
    custom: 'Custom',
  };

  const handleFilterTypeToggle = (value: EDateFilterTypes) => {
    if (selectedFilterType === value) {
      handleReset();
      return;
    }

    if (value === EDateFilterTypes.CUSTOM) {
      setSelectedFilterType(EDateFilterTypes.CUSTOM);
      return;
    }

    setSelectedFilterType(value);
    setIsShowContent(false);
    onApply(calculateDateInterval(value));
  };

  const handleCustomDateApply = (interval: TDateFilterInterval) => {
    setIsShowContent(false);
    setCustomInterval(interval);
    onApply(interval);
  };

  const handleReset = () => {
    setSelectedFilterType(null);
    setIsShowContent(false);
    onApply({ from: null, to: null });
  };

  return (
    <Popover
      behavior='click'
      elevation={4}
      showContent={isShowContent}
      onClose={() => setIsShowContent(false)}
      trigger={
        <Styled.Trigger
          $isSelected={isShowContent}
          onClick={() => setIsShowContent(prev => !prev)}
        >
          <Styled.Label $isSelected={isShowContent}>
            {translate('COMMON.LABELS.DATE')}
          </Styled.Label>
          <Typography>
            {selectedFilterType
              ? filterTypeTranslationMap[selectedFilterType]
              : // TODO: (av) Add translation.
                'DD/MM/YYYY'}
          </Typography>
          <Icon iconType='calendar' />
        </Styled.Trigger>
      }
      content={
        <Styled.Content>
          <div>
            <FilterList
              selectedFilterType={selectedFilterType}
              labelFontSize='14px'
              checkboxSize='14px'
              onFilterTypeClick={handleFilterTypeToggle}
            />
          </div>
          {selectedFilterType === EDateFilterTypes.CUSTOM && (
            <CustomDateSelection
              initialValues={
                customInterval === null ? undefined : customInterval
              }
              onApply={(from, to) => handleCustomDateApply({ from, to })}
            />
          )}
        </Styled.Content>
      }
    />
  );
};
