import styled, { css } from 'styled-components';
import { Icon, Wrapper } from '@trader/components';

export const Trigger = styled(Wrapper)<{ $isStatic?: boolean }>`
  ${({ theme, $isStatic }) => css`
    align-items: center;
    color: ${theme.palette.gray['400']};
    user-select: none;
    cursor: ${!$isStatic && 'pointer'};

    svg {
      margin-left: 4px;
    }
  `};
`;

export const FilterIcon = styled(Icon)<{ $isApplied: boolean }>`
  ${({ theme, $isApplied }) => css`
    margin-left: 4px;

    path {
      stroke: ${$isApplied && theme.palette.primary.main};
    }
  `};
`;
