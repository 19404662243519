export const headerHeight = 55;
export const footerHeight = 45;
export const searchInstrumentHeight = 56;
export const bottomBarHeight = 300;
export const minimizedBottomBarHeight = 200;

// sideBar === leftBar
export const sideBarWidth = 410;
export const betaMaxSideBarWidth = 360;
export const betaMinimizedSideBarWidth = 220;
export const sideBarClosedWidth = 65;
export const rightBarWidth = 250;
export const userDetailsLayoutLeftBarWidth = 210;

export const layoutMargin = 11;

export const layoutChildrenRadius = 5;

export const modalHorizontalPadding = 18;
export const tradingHorizontalSpace = 7;

export const muliBandsPadding = 12;
