import { useCallback, useMemo } from 'react';
import { clone } from 'mobx-state-tree';
import * as yup from 'yup';

import {
  analyzeHourRanges,
  getInstrumentTradingHours,
  isValueTimesStep,
} from '@trader/utils';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { maxMultiplier, maxSl, minMultiplier } from '@trader/constants';

const minStopLoss = 1;

export const useOrderFormValidation = () => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.getSymbol()
  );

  const instrumentTradingHours = useMemo(
    () => getInstrumentTradingHours(instrument.sessions),
    [instrument.sessions]
  );

  const SlValidation = useCallback((isRequired: boolean, value?: number) => {
    if (!isRequired) {
      return true;
    }

    if (!value) {
      return false;
    }

    return +value >= minStopLoss;
  }, []);

  return yup.object().shape({
    orderAmount: yup
      .number()
      .test(
        'increment',
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: instrument.minOrderSizeIncrement,
        }),
        value =>
          !!value && isValueTimesStep(value, instrument.minOrderSizeIncrement)
      )
      .required(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: instrument.minOrderSize,
        })
      )
      .max(
        instrument.maxOrderSize,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: instrument.maxOrderSize,
        })
      )
      .min(
        instrument.minOrderSize,
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: instrument.minOrderSizeIncrement,
        })
      )
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: instrument.minOrderSizeIncrement,
        })
      ),
    topMultiplier: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .min(
        minMultiplier,
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .max(
        maxMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxMultiplier,
        })
      ),
    bottomMultiplier: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .min(
        minMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: minMultiplier,
        })
      )
      .max(
        maxMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxMultiplier,
        })
      ),
    sl: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minStopLoss,
        })
      )
      .max(
        maxSl,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxSl,
        })
      )
      .when(['isSl'], ([isSl], schema) => {
        return schema.test(
          'sl',
          translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
            amount: minStopLoss,
          }),
          value => SlValidation(isSl, value)
        );
      }),
    closeTime: yup
      .date()
      .typeError('Invalid time')
      .when(['isCloseTime'], ([isCloseTime], schema) => {
        return schema.test(
          'closeTime',
          translate('MULI_BANDS.OUT_OF_TRADING_HOURS'),
          value => {
            if (!isCloseTime) {
              return true;
            }

            if (!value) {
              return false;
            }

            const analyzedTradingHours = analyzeHourRanges(
              clone(instrumentTradingHours),
              value
            );

            return analyzedTradingHours.hasMatchedWithCurrentDate;
          }
        );
      }),
  });
};
