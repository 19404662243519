import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';

export const InputLabel = styled(Typography)`
  ${() => css`
    text-wrap: nowrap;
    min-width: 105px;
    max-width: 105px;
  `}
`;
