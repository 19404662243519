import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { Icon, Typography } from '@trader/components';
import { TIconsName } from '@trader/assets';
import { useMenuListItems } from '@trader/hooks';

import * as Styled from './styled';

export const UserDetailsLayoutLeftBar: React.FC = observer(() => {
  const location = useLocation();
  const { getMenu } = useMenuListItems();

  return (
    <Styled.LeftBar>
      {getMenu().map(item => (
        <Styled.LeftBarItem
          key={item.id}
          selected={location.pathname === item.pathname}
          disabled={item.disabled}
          onClick={() => item.action()}
        >
          <Icon iconType={item.icon as TIconsName} />
          <Typography>{item.children}</Typography>
        </Styled.LeftBarItem>
      ))}
    </Styled.LeftBar>
  );
});
