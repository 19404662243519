import React, { useEffect } from 'react';

import { useMst } from '@trader/store';
import { emptyFn } from '@trader/constants';
import {
  EOrderCreatedBy,
  EPositionClosedBy,
  EStripName,
  IBackTestingPosition,
} from '@trader/types';

import { IChartingLibraryWidget } from '../../charting_library';

const toBarTime = 1000;

const existingShapes = new Set<string>();

export const useListOfTrades = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();

  const backTesting = store.pages.muliBands.backTesting;
  const listOfTrades = backTesting.getListOfTrades();

  const shouldDisplayListOfTrades =
    backTesting.id &&
    !backTesting.isPending &&
    listOfTrades.length &&
    backTesting.selectedMultiplierOption;

  const getBarTime = (time: string) => {
    const utcDate = new Date(`${time}Z`);
    return utcDate.getTime() / toBarTime;
  };

  const clearShapes = () => {
    existingShapes.clear();
    widget.current?.activeChart().removeAllShapes();
  };

  const createShape = (
    type: 'close' | 'open',
    position: IBackTestingPosition & { multiplier?: number }
  ) => {
    const time = type === 'open' ? position.openTime : position.closeTime;
    const price = type === 'open' ? position.openPrice : position.closePrice;

    const existingShapeId = `${type}-${position.id + new Date(time).getTime()}`;

    if (existingShapes.has(existingShapeId)) {
      return;
    }

    const shape = type === 'open' ? 'arrow_up' : 'arrow_down';
    const text = `${EStripName[position.bandId]} ${
      EOrderCreatedBy[position.orderCreatedBy]
    } ${
      position.closedBy === EPositionClosedBy.TakeProfit
        ? `TP: ${price}`
        : position.closedBy === EPositionClosedBy.StopLoss
        ? `SL: ${price}`
        : ''
    }`;

    widget.current?.activeChart().createShape(
      {
        time: getBarTime(time),
        price: price,
      },
      {
        shape,
        text,
        disableSelection: true,
        zOrder: 'top',
        // lock: true,
      }
    );
    existingShapes.add(existingShapeId);
  };

  const handleShapes = (timeRange: number) => {
    listOfTrades.forEach(position => {
      const openTime = position.openTime;
      const closeTime = position.closeTime;

      if (openTime && getBarTime(openTime) >= timeRange) {
        createShape('open', position);
      }

      if (closeTime && getBarTime(closeTime) >= timeRange) {
        createShape('close', position);
      }
    });
  };

  useEffect(() => {
    if (shouldDisplayListOfTrades) {
      widget?.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        const defaultRange = widget.current?.activeChart().getVisibleRange();

        if (isDataReady) {
          clearShapes();

          widget.current
            ?.activeChart()
            .onVisibleRangeChanged()
            .unsubscribeAll(null);

          widget.current
            ?.activeChart()
            .onVisibleRangeChanged()
            .subscribe(null, range => {
              const fromLocalTime = new Date(range.from).getTime();
              handleShapes(fromLocalTime);
            });

          if (defaultRange) {
            const fromDefaultRange = new Date(defaultRange?.from).getTime();
            handleShapes(fromDefaultRange);
          }
        }
      });
    } else {
      widget?.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          clearShapes();
        }
      });
    }
  }, [
    shouldDisplayListOfTrades,
    backTesting.selectedMultiplierOption,
    backTesting.selectedBandOption,
  ]);
};
