import styled, { css } from 'styled-components';
import {
  FormControlLabel as FormControlLabelMui,
  Slider as MuiSlider,
} from '@mui/material';

import { Typography } from '../typography';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `};
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    padding-top: 11px;
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      border: 1px solid ${theme.palette.background.default};
      border-radius: 5px;
      padding: 2px 4px;
    }
  `};
`;

export const Slider = styled(MuiSlider)`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.palette.common.black};

    .MuiSlider-rail {
      height: 5px;
      background: ${theme.palette.grey[800]};
    }

    .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: 3px solid #a8aab5;
    }

    .MuiSlider-track {
      color: ${theme.palette.grey[800]};
      border: none;
      height: 5px;
    }
  `};
`;

export const FormControlLabel = styled(FormControlLabelMui)`
  ${({ theme }) => css`
    align-items: flex-start;
    user-select: none;
    margin-right: 0;
    margin-left: 0;

    span {
      ${theme.breakpoints.down('sm')} {
        font-size: ${theme.typography.default.fontSize};
      }
    }
  `}
`;
