import { Decimal } from 'decimal.js';
import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from './pipSize';
import { getMaxDigitWithDot } from '../numberFormats';
import { getStepBasedOnTickSize } from './common';

export const checkOnTickSize = (value: number | string, tickSize: number) => {
  if (!tickSize) {
    return false;
  }

  const decimal = new Decimal(value);

  return !decimal.mod(tickSize).equals(0);
};

export const adjustByTickSize = (
  value: string,
  pipSize: number,
  tickSize: number,
  side: TPlaceOrderSide,
  type: 'price' | 'tp' | 'sl'
) => {
  if (!checkOnTickSize(value, tickSize)) {
    return formatByPipSize(value, pipSize);
  }

  const step = getStepBasedOnTickSize(pipSize, tickSize);
  const fixDigitAfterDot = getMaxDigitWithDot(pipSize);

  const restOfTheValue = calculateRestValue(value, step, fixDigitAfterDot);
  let fittedValue = new Decimal(value).minus(restOfTheValue);

  if (shouldAddStep(side, type)) {
    fittedValue = fittedValue.plus(step);
  }

  return formatByPipSize(fittedValue.toNumber(), pipSize);
};

// Determine if the step should be added
const shouldAddStep = (side: TPlaceOrderSide, type: 'price' | 'tp' | 'sl') => {
  return (
    (side === 'Buy' && (type === 'price' || type === 'tp')) ||
    (side === 'Sell' && type === 'sl')
  );
};

// Calculate the remainder of the value based on step
const calculateRestValue = (
  value: string,
  step: number,
  fixDigitAfterDot: number
) => {
  return (Number(value) % step).toFixed(fixDigitAfterDot);
};
