import styled, { css } from 'styled-components';
import { Checkbox, Typography, Wrapper } from '@trader/components';

interface IBorder {
  $isBorderVisible?: boolean;
}

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  `}
`;

export const Content = styled(Wrapper)<IBorder>`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;

export const TakeProfit = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 20px;
    border-top: 1px solid ${theme.palette.background.default};
  `}
`;
export const StopLoss = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid ${theme.palette.background.default};
    border-bottom: 1px solid ${theme.palette.background.default};
    padding: 0 20px;
  `}
`;

export const InputWrapper = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-top: 20px;
    > div:last-child {
      flex: 2;
    }
  `}
`;

export const Select = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    margin-right: 10px;
    margin-top: 5px;
    width: 70px;
    > div > div > div {
      padding: 2px 3px 2px 7px;
    }
  `}
`;

export const InputLabel = styled(Typography)`
  ${({ theme }) => css`
    text-align: right;
    margin-right: 14px;
    font-size: ${theme.typography.large.fontSize};
    min-width: 105px;
    max-width: 105px;
  `}
`;

export const Label = styled(Wrapper)`
  ${() => css`
    flex: 1;
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-end;
  `}
`;

export const Protection = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`;
export const Toggle = styled(Checkbox)`
  ${() => css`
    width: 20px;
    height: 20px;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.large.fontSize};
  `}
`;
