import styled, { css } from 'styled-components';
import { conditionApply } from '@trader/utils';
import { Wrapper } from '@trader/components';
import { IconButton } from '@mui/material';

interface IMiddleComponent {
  $isMiddleComponent?: boolean;
}

export const Input = styled('input')`
  ${({ disabled, theme }) => css`
    width: 100%;
    height: 22px;
    margin: 0;
    padding: 0;
    border: none;
    text-align: start;
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    background: ${theme.palette.gray[500]};
    color: ${theme.palette.text.primary};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.xl.fontSize};
    }

    &:focus {
      border: none;
      outline: none;
      appearance: none;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    ${conditionApply(
      [disabled],
      `
         background: transparent;
        `
    )};
  `}
`;

export const RootInput = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const Main = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  `}
`;

export const Content = styled(Wrapper)<IMiddleComponent>`
  ${({ theme, $isMiddleComponent }) => css`
    flex-direction: column;
    background: ${theme.palette.gray[500]};
    height: 45px;
    padding: 3px 0 0 10px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-right: 1px;

    ${conditionApply(
      [$isMiddleComponent],
      css`
        margin-right: 0;
      `
    )};
  `}
`;

export const Buttons = styled(Wrapper)`
  ${() => css`
    flex-direction: column;

    :first-child {
      margin-bottom: 1px;
    }
  `}
`;

export const ButtonAction = styled(IconButton)`
  ${({ theme, disabled }) => css`
    width: 30px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    background: ${theme.palette.gray[500]};
    color: ${theme.palette.text.primary};
    border-radius: 0 5px 5px 0;

    ${conditionApply(
      [disabled],
      css`
        background: ${theme.palette.gray[500]} !important;
        color: ${theme.palette.gray.main};
      `
    )};

    ${theme.breakpoints.down('sm')} {
      width: 30px;
      height: 22px;
    }
  `}
`;

export const InputError = styled(Wrapper)`
  ${({ theme }) => css`
    color: ${theme.palette.red.main};
    text-align: center;
    font-size: ${theme.typography.default.fontSize};
    text-wrap: wrap;
    margin: -8px 0 8px 0;
  `}
`;
