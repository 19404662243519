import styled, { css } from 'styled-components';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';

export const Typography = styled(MuiTypography)<TypographyProps>`
  ${({ theme }) => css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.palette.common.black};
  `}
`;
