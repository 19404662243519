import styled, { css } from 'styled-components';
import { Typography } from '../typography';

export const Title = styled(Typography)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${theme.typography.base.fontSize};
    font-weight: 600;
  `}
`;
