import { IBackTestingPosition } from '@trader/types';
import { orderBy } from 'lodash';

type TGroupedProfitChartData = Record<
  string,
  { max: number; total: number; name: string }
>;
export const groupBiaxialChartDataByCloseTime = (
  positions: IBackTestingPosition[],
  initialData?: TGroupedProfitChartData
) => {
  const data = initialData || ({} as TGroupedProfitChartData);

  const sorted = orderBy(
    positions,
    [
      row => {
        const value = row.closeTime;
        return new Date(value).getTime();
      },
    ],
    'asc'
  );

  return sorted.reduce((acc, item) => {
    if (item.closeTime) {
      const date = item.closeTime.split('T')[0];

      if (!acc[date]) {
        acc[date] = { max: -Infinity, total: 0, name: date };
      }

      acc[date].total += item.profit;
      acc[date].max = Math.max(acc[date].max, item.profit);
    }

    return acc;
  }, data);
};
