import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize, getAmplitudeBasedOnTickSize } from '@trader/utils';
import { defaultPipSize } from '@trader/constants';
import { getOrderBasePrice } from './common';
import {
  getAmplitudeFromModel,
  getModelPips,
  getRealPipsFromModel,
} from './pips';

export const getBuyProfitRateWithAmplitude = (
  pips: number | undefined,
  tickSize: number,
  pipSize: number,
  bidPrice: number | string | undefined,
  formattingPipSize: number | undefined,
  category: string | undefined
) => {
  const amplitude = getAmplitudeFromModel(pips, pipSize, category);

  return formatByPipSize(
    Number(bidPrice ?? 0) + getAmplitudeBasedOnTickSize(amplitude, tickSize),
    formattingPipSize ?? defaultPipSize
  );
};

export const getBuyLossRateWithAmplitude = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  bidPrice: number | string | undefined,
  formattingPipSize: number | undefined,
  category: string | undefined
) => {
  const amplitude = getAmplitudeFromModel(stopsLevel, pipSize, category);

  return formatByPipSize(
    Number(bidPrice ?? 0) - getAmplitudeBasedOnTickSize(amplitude, tickSize),
    formattingPipSize ?? defaultPipSize
  );
};

export const getProfitPipsFromRate = (
  rate: string,
  tickSize: number,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  pipSize: number,
  category: string | undefined,
  price?: string
) => {
  const takeProfit =
    +rate - (price ? +price : getOrderBasePrice(side, bidPrice, askPrice));

  if (tickSize) {
    const pips = takeProfit / tickSize;

    return formatByPipSize(
      getModelPips(pips * (side === 'Buy' ? 1 : -1), category),
      0
    );
  }

  return formatByPipSize(
    getModelPips((takeProfit * (side === 'Buy' ? 1 : -1)) / +pipSize, category),
    0
  );
};

export const getProfitRateFromPips = (
  pips: string,
  tickSize: number,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  pipSize: number,
  category: string | undefined,
  formattingPipSize?: number,
  price?: string
) => {
  const basePrice = price
    ? +price
    : getOrderBasePrice(side, bidPrice, askPrice);

  if (tickSize) {
    const pipsWithTicks = +pips * tickSize;

    return formatByPipSize(
      basePrice +
        getRealPipsFromModel(pipsWithTicks, category) *
          (side === 'Buy' ? 1 : -1),
      formattingPipSize ?? pipSize
    );
  }
  return formatByPipSize(
    basePrice +
      +pipSize *
        getRealPipsFromModel(pips, category) *
        (side === 'Buy' ? 1 : -1),
    formattingPipSize ?? pipSize
  );
};
