import React from 'react';

import * as Styled from './styled';
import { Button } from '../button';

interface IErrorBoundaryPageProps {
  children: React.ReactNode;
  onHandleError?: () => void;
}

interface IErrorBoundaryPageState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryPageProps,
  IErrorBoundaryPageState
> {
  constructor(props: IErrorBoundaryPageProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(
    _error: unknown
  ): Partial<IErrorBoundaryPageState> {
    return { hasError: true };
  }

  onClickRetry = (): void => {
    this.setState({ hasError: false });
  };

  render(): React.ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Styled.Root>
        <Styled.Title variant='xl'>Whoops, something went wrong</Styled.Title>
        <Button
          size='large'
          variant='outlined'
          color='primary'
          onClick={this.props.onHandleError}
        >
          Refresh the app
        </Button>
      </Styled.Root>
    );
  }
}

export const WithErrorBoundary: React.FC<IErrorBoundaryPageProps> = ({
  children,
  ...props
}) => {
  return <ErrorBoundary {...props}>{children}</ErrorBoundary>;
};
