import styled, { css } from 'styled-components';

import { Button } from '@trader/components';

export const Root = styled('div')`
  ${() => css`
    width: 480px;
    padding: 12px 24px;
    text-align: center;
  `};
`;

export const Submit = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 5px;
    margin-top: 8px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.black};
    font-weight: ${theme.typography.fontWeightMedium};
  `};
`;

export const SignOut = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 5px;
    margin-top: 8px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};
    font-weight: ${theme.typography.fontWeightMedium};
    border: 1px solid ${theme.palette.common.black};

    &:hover {
      background-color: ${theme.palette.common.white};
    }
  `};
`;
