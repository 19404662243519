import { getIconBaseProps } from './utils';

export const switchAccount = {
  ...getIconBaseProps('switchAccount'),
  width: '8px',
  height: '8px',
  viewBox: '0 0 8 8',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M6.85522 2.65517H2.95522C1.79543 2.65517 0.855225 3.62779 0.855225 4.82759C0.855225 6.02738 1.79543 7 2.95522 7H5.45522M6.85522 2.65517L5.25522 1M6.85522 2.65517L5.25522 4.31034'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
