import { useEffect, useRef } from 'react';

import {
  calcMillisecondsUntilNextMinute,
  instrumentHelpers,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { IInstrumentBE } from '@trader/api';

const ONE_MINUTE_IN_MILLISECONDS = 60000;

/**
 * Custom hook that updates the trading status of instruments at regular intervals.
 * It checks if each instrument's trading is disabled based on holidays and sessions,
 * and updates the store accordingly.
 */
export const useInstrumentsTradingUpdater = () => {
  const intervalRef = useRef<undefined | number>(undefined);
  const timeoutRef = useRef<undefined | number>(undefined);

  const store = useMst();
  const instruments = store.entities.instruments.getAll<TInstrumentEntity>();
  const isFetchingInstruments =
    store.entities.instruments.getInstrumentsAsync.inProgress;
  const isAuth = store.auth.isAuth;

  const checkIsInstrumentTradingDisabled = () => {
    for (const instrument of instruments) {
      const tradingAvailability =
        instrumentHelpers.isInstrumentTradingUnavailable(
          instrument.holidays as unknown as IInstrumentBE['holidays'],
          instrument.sessions
        );
      store.entities.instruments.update(instrument.symbol, {
        tradingAvailability,
      });
    }
  };

  useEffect(() => {
    if (isAuth) {
      checkIsInstrumentTradingDisabled();

      const millisecondsUntilNextMinute = calcMillisecondsUntilNextMinute();
      /**
       * Sets a timeout to trigger the check at the start of the next minute.
       * The timeout's ID is stored in timeoutRef.current.
       */
      timeoutRef.current = window.setTimeout(() => {
        checkIsInstrumentTradingDisabled();
        /**
         * Sets an interval to repeatedly call the check every minute.
         * The interval's ID is stored in intervalRef.current.
         */
        intervalRef.current = window.setInterval(
          checkIsInstrumentTradingDisabled,
          ONE_MINUTE_IN_MILLISECONDS
        );
      }, millisecondsUntilNextMinute);

      return () => {
        clearTimeout(timeoutRef.current);
        clearInterval(intervalRef.current);
      };
    }
  }, [isAuth, isFetchingInstruments, instruments.length]);
};
