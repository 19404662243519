import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
  `}
`;
