import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';
import { Typography, Wrapper, Button } from '@trader/components';

export const Actions = styled(Wrapper)<IActionButton>`
  ${({ $layout, theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;

    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
      width: 100%;
      margin-top: 10px;
    }

    ${conditionApply(
      [$layout === 'threeRow'],
      css`
        flex-direction: column;
      `
    )};
  `};
`;

interface IActionButton {
  $isAsk?: boolean;
  $isInvestmentAccProduct?: boolean;
  $layout?: string;
}

export const ActionButton = styled(Button)<IActionButton>`
  ${({ theme, $isAsk, $isInvestmentAccProduct, $layout, disabled }) => css`
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    justify-content: ${$isAsk ? 'flex-start' : 'flex-end'};
    align-items: center;
    background: ${$isAsk
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};
    width: 160px;
    height: 47px;
    padding: 8px;
    border-radius: 5px;

    ${theme.breakpoints.down('xl')} {
      width: 130px;
      height: 52px;
    }

    ${theme.breakpoints.down('sm')} {
      width: 49%;
      height: 44px;
      margin-left: 0;
      padding: 10px;

      > span {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    &:hover {
      background: ${$isAsk
        ? theme.palette.purchaseButtons.red
        : theme.palette.purchaseButtons.green};
    }

    ${conditionApply(
      [disabled, $isAsk, $isInvestmentAccProduct],
      css`
        background: ${theme.palette.red.main} !important;
        cursor: not-allowed !important;
        pointer-events: initial !important;
      `
    )};

    ${conditionApply(
      [$layout !== 'single'],
      css`
        width: 100px;
        height: 42px;

        ${theme.breakpoints.down('xl')} {
          width: 90px;
          height: 30px;
        }
      `
    )};

    ${conditionApply(
      [$layout === 'threeRow'],
      css`
        width: 100px;
        height: 22px;

        ${theme.breakpoints.down('xl')} {
          width: 100px;
          height: 22px;
        }

      \`
      `
    )};
  `};
`;

export const ButtonTitle = styled(Typography)<IActionButton>`
  ${({ theme, $isAsk }) => css`
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.white.main};
    text-align: ${$isAsk ? 'left' : 'right'};

    ${theme.breakpoints.down('sm')} {
      font-weight: ${theme.typography.fontWeightBold};
      font-size: ${theme.typography.base.fontSize};
    }
  `};
`;

export const ButtonPrice = styled(Typography)<IActionButton>`
  ${({ theme, $isAsk, $layout }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.white.main};
    text-align: ${$isAsk ? 'left' : 'right'};
    ${conditionApply(
      [$layout !== 'single'],
      css`
        font-size: ${theme.typography.small.fontSize};
      `
    )};
    ${conditionApply(
      [$layout === 'threeRow'],
      css`
        font-size: ${theme.typography.large.fontSize};
      `
    )};

    ${theme.breakpoints.down('sm')} {
      font-weight: ${theme.typography.fontWeightBold};
      font-size: ${theme.typography.base.fontSize};
    }
  `};
`;

export const ButtonText = styled('span')`
  ${() => css`
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-transform: initial;
  `}
`;
