import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Content = styled(Wrapper)`
  ${() => css`
    align-items: center;

    .MuiAutocomplete-root {
      width: 100px;
    }
  `};
`;
