import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import { IExportCsv, useExportCsv } from '@trader/hooks';
import { fullyFinishedBackTest } from '@trader/constants';

import { MultiplierFilter } from './multiplierFilter';
import { BandFilter } from './bandFilter';

import * as Styled from './styled';

interface IBackTestHeader {
  onGetCsvData: () => IExportCsv;
}

export const BackTestHeader: React.FC<IBackTestHeader> = observer(
  ({ onGetCsvData }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { downloadCSV } = useExportCsv();

    const percentFinished =
      +store.pages.muliBands.backTesting.percentFinished.toFixed();

    return (
      <Styled.Root>
        <Wrapper>
          <MultiplierFilter />
          <BandFilter />
        </Wrapper>
        {percentFinished === fullyFinishedBackTest && (
          <Styled.Download
            size='small'
            onClick={() => downloadCSV(onGetCsvData())}
          >
            {translate('COMMON.LABELS.DOWNLOAD_CSV')}
          </Styled.Download>
        )}
      </Styled.Root>
    );
  }
);
