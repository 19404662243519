import { observer } from 'mobx-react-lite';

import { Instruments } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import {
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';

import * as Styled from './styled';

export const InstrumentsTable = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <Styled.Header>
        <Styled.Title>
          <Styled.Instrument variant='tiny'>
            {translate('COMMON.LABELS.INSTRUMENT')}
          </Styled.Instrument>
          {store.ui.sidebar.leftBar.includedColumns.sell && (
            <Styled.Sell variant='tiny'>
              {translate('COMMON.LABELS.SELL')}
            </Styled.Sell>
          )}
          {store.ui.sidebar.leftBar.includedColumns.buy && (
            <Styled.Sell variant='tiny'>
              {translate('COMMON.LABELS.BUY')}
            </Styled.Sell>
          )}
          {shouldDisplaySpreadColumnOnInstrumentList &&
            store.ui.sidebar.leftBar.includedColumns.spread && (
              <Styled.Spread variant='tiny'>
                {translate('COMMON.LABELS.SPREAD')}
              </Styled.Spread>
            )}
          {shouldDisplayChangeColumnOnInstrumentList &&
            store.ui.sidebar.leftBar.includedColumns.change && (
              <Styled.Spread variant='tiny'>
                {translate('COMMON.LABELS.CHANGE')}
              </Styled.Spread>
            )}
          {store.ui.sidebar.leftBar.includedColumns.dailyTrend && (
            <Styled.HTrend variant='tiny'>
              {translate('COMMON.LABELS.24HTREND')}
            </Styled.HTrend>
          )}
          {store.ui.sidebar.leftBar.includedColumns.favourite && (
            <Styled.Favourite />
          )}
        </Styled.Title>
      </Styled.Header>
      <Instruments />
    </Styled.Root>
  );
});
