import styled, { css } from 'styled-components';
import { Stack } from '@mui/material';

import { Typography, Wrapper } from '@trader/components';

export const Value = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;

export const Portfolio = styled('div')`
  ${() => css`
    width: 226px;
    border-radius: 5px;
  `};
`;

export const PortfolioHeader = styled('div')`
  ${({ theme }) => css`
    padding: 10px 14px;
    border-bottom: 1px solid ${theme.palette.gray.main};
  `};
`;

export const PortfolioTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;

export const PortfolioContent = styled(Stack)`
  ${() => css`
    padding: 12px 14px;
  `};
`;

export const PortfolioItem = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
  `};
`;
