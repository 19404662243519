import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const Content = styled(Typography)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    text-align: left;
    width: 100%;
  `}
`;

export const Input = styled('input')`
  ${() => css`
    width: 100%;
    padding: 5px 10px;
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
  `}
`;

export const Error = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin-top: 10px;
    color: ${theme.palette.error.main};
  `}
`;
export const ActionButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin-top: 20px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
  `}
`;
