import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Main = styled(Wrapper)`
  ${() => css`
    margin-top: 16px;
    justify-content: flex-start;
  `};
`;

export const ItemType = styled(Wrapper)`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
    margin-right: 15px;
    > svg {
      margin-right: 8px;
    }

    > svg:last-child {
      width: 17px;
      height: 17px;
    }
  `};
`;
