import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { ITab } from '@trader/components';

import { BetaDesign } from './betaDesign';
import { StableDesign } from './stableDesign';

export interface IAssetDetails {
  additionalTabs?: ITab[];
  excludedTabs?: string[];
  forceBeta?: boolean;
  selectedTab?: string;
  navigationKey?: string;
  minHeight?: number;
}

export const AssetDetails: React.FC<IAssetDetails> = observer(props => {
  const store = useMst();

  if (store.app.isBetaDesignEnabled() || props.forceBeta) {
    return <BetaDesign {...props} />;
  }

  return <StableDesign {...props} />;
});
