import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@trader/components';
import { useI18next } from '@trader/services';
import { NAVIGATE_TO } from '@trader/constants';

import * as Styled from './styled';

export const NotFound: React.FC = () => {
  const navigateTo = useNavigate();
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <Typography>{translate('COMMON.LABELS.PAGE_NOT_FOUND')}.</Typography>
      <Styled.SwitchAccountButton
        onClick={() => navigateTo(NAVIGATE_TO.main.dashboard)}
      >
        {translate('COMMON.LABELS.GO_TO_HOMEPAGE')}
      </Styled.SwitchAccountButton>
    </Styled.Root>
  );
};
