import React from 'react';

import { SelectFilter } from '@trader/components';
import { TPaymentStatus } from '@trader/api';
import { useI18next } from '@trader/services';

import * as Styled from './styled';
import { useFilterOptions } from '../../../hooks/useFilterOptions';

interface IFilters {
  onFiltersChange: (statuses: TPaymentStatus[]) => void;
}

export const PurchaseHistoryFilters: React.FC<IFilters> = ({
  onFiltersChange,
}) => {
  const { translate } = useI18next();
  const { selectStatusOptions } = useFilterOptions();

  return (
    <Styled.Root>
      <SelectFilter
        label={translate('COMMON.LABELS.STATUS')}
        options={selectStatusOptions}
        onChange={selectedStatuses =>
          onFiltersChange(selectedStatuses as TPaymentStatus[])
        }
      />
    </Styled.Root>
  );
};
