import styled, { css } from 'styled-components';

import { Wrapper, Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Content = styled(Wrapper)`
  ${() => css`
    width: 100%;
    flex-direction: column;
  `};
`;

export const SubTabs = styled(Wrapper)`
  ${({ theme }) => css`
    height: 48px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border-bottom: 1px solid ${theme.palette.background.default};
  `};
`;

export const SubTab = styled(Typography)<{ $selected?: boolean }>`
  ${({ theme, $selected }) => css`
    font-size: ${theme.typography.large.fontSize};
    cursor: pointer;
    user-select: none;

    ${conditionApply(
      [$selected],
      css`
        font-weight: ${theme.typography.fontWeightBold};
      `
    )}
  `};
`;

export const Divider = styled(Wrapper)`
  ${({ theme }) => css`
    height: 20px;
    width: 1px;
    margin: 0 16px;
    background-color: ${theme.palette.background.default};
  `};
`;
