import { devLoggerService } from '@trader/services';
import { getEndOfDayDate, returnTypedThis } from '@trader/utils';
import { api, IBackTestConfig } from '@trader/api';

import { createThunk } from '../../../utils/asyncModel';
import { TBackTestingStore } from './index';
import i18next from 'i18next';
import { getRootInstance } from '../../../configureStore/configureStore';

export const startBackTestingAsync = createThunk<IBackTestConfig, void>(
  body =>
    async function startBackTesting(this: unknown, options, _flow) {
      const that = returnTypedThis<TBackTestingStore>(this);

      const { symbol, ...rest } = body;
      try {
        const res = await api.BandStrategy.startBackTesting(
          {
            symbol,
            backTestConfig: {
              ...rest.backTestConfig,
              to: getEndOfDayDate(rest.backTestConfig.to),
            },
          },
          options
        );

        that.runInAction(() => {
          that.id = res.id;
          that.percentFinished = 0;
          that.quantity = body.backTestConfig.quantity;
          that.oneTradePerSession = body.backTestConfig.oneTradePerSession;
          that.allowAveraging = body.backTestConfig.allowAveraging;
          that.lowestMultiplier = body.backTestConfig.lowestMultiplier;
          that.highestMultiplier = body.backTestConfig.highestMultiplier;
          that.multiplierStep = body.backTestConfig.multiplierStep;
          that.from = body.backTestConfig.from;
          that.to = body.backTestConfig.to;
          that.isPending = true;
          that.multipliers = [];
          that.summary = null;
          that.selectedMultiplierOption = null;
          that.selectedBandOption = null;

          if (body.backTestConfig?.sl) {
            that.sl = body.backTestConfig?.sl;
          }

          if (body.backTestConfig.sessionRange?.from) {
            that.sessionFrom = body.backTestConfig.sessionRange
              ?.from as unknown as Date;
            that.sessionTo = body.backTestConfig.sessionRange
              ?.to as unknown as Date;
          }
        });
      } catch (e) {
        devLoggerService.error('startBackTestingAsync', e);
      }
    }
);

export const cancelBackTestRunningAsync = createThunk<void, void>(
  () =>
    async function cancelBackTestRunning(this: unknown, options, _flow) {
      const that = returnTypedThis<TBackTestingStore>(this);
      const root = getRootInstance();

      if (!that.id) {
        return;
      }

      try {
        await api.BandStrategy.cancelBackTestRunning(options);

        that.runInAction(() => {
          that.isPending = false;
          that.percentFinished = 100;
        });

        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.BACK_TEST_CANCELLED'),
          options: {
            variant: 'warning',
          },
        });
      } catch (e) {
        devLoggerService.error('cancelBackTestRunningAsync', e);
      }
    }
);

export const existsBackTestAsync = createThunk<void, void>(
  () =>
    async function existsBackTest(this: unknown, options, _flow) {
      const that = returnTypedThis<TBackTestingStore>(this);

      if (!that.id || !that.getSymbol()) {
        return;
      }

      try {
        const res = await api.BandStrategy.getBackTestExists(
          that.getSymbol(),
          options
        );
        if (res.exists) {
          that.runInAction(() => {
            that.isPending = true;
          });
        }
      } catch (e) {
        devLoggerService.error('cancelBackTestRunningAsync', e);
      }
    }
);
