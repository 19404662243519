import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 233px;
    height: 180px;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    padding: 19px 12px 10px 12px;
    border: 1px solid ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      width: 80%;
      height: auto;
    }
  `};
`;

export const Main = styled(Wrapper)`
  ${() => css`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    justify-content: space-around;
  `};
`;

export const Buttons = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div > button {
      background: ${theme.palette.gray[500]};

      > span {
        p {
          color: ${theme.palette.common.black};
        }
        p:first-child {
          font-size: ${theme.typography.tiny.fontSize};
          font-weight: ${theme.typography.fontWeightBold};
        }
        p:last-child {
          font-size: ${theme.typography.tiny.fontSize};
          font-weight: ${theme.typography.fontWeightMedium};
        }
      }
    }
  `};
`;

export const Symbol = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.base.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    margin: 5px 0 10px 0;
  `};
`;
export const CurrentPrice = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
  `};
`;

interface IDifference {
  $isAboveZero: boolean;
}

export const Difference = styled(Typography)<IDifference>`
  ${({ theme, $isAboveZero }) => css`
    font-size: ${theme.typography.xl.fontSize};
    color: ${$isAboveZero ? theme.palette.green.main : theme.palette.grey.main};
  `};
`;
