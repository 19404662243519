import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface ITabPanel {
  compareValue: string;
  value: string;
  children: React.ReactNode;
}

interface ITab {
  label?: string;
  value: string;
  children: React.ReactNode | string;
  icon?: React.ReactElement | string;
  disabled?: boolean;
  isDefault?: boolean;
}

interface ITabs {
  tabs: ITab[];
  marginBottom?: string;
  padding?: string;
  navigationKey?: string;
  onChange?: (key: string) => void;
  tabsPosition?: 'top' | 'bottom';
  hasNavigationBar?: boolean;
  hasBottomBorder?: boolean;
  withScrollableTabs?: boolean;
  preselectedTab?: string;
}

export const Tabs: React.FC<ITabs> = observer(
  ({
    tabs,
    onChange,
    hasNavigationBar = true,
    withScrollableTabs = false,
    preselectedTab,
  }) => {
    const [selectedTab, setSelectedTab] = React.useState<string>(
      preselectedTab || tabs[0]?.value
    );

    const handleChange = (
      _event: React.SyntheticEvent,
      newValue: string
    ): void => {
      setSelectedTab(newValue);
      onChange && onChange(newValue);
    };

    useEffect(() => {
      preselectedTab && setSelectedTab(preselectedTab);
    }, [preselectedTab]);

    return (
      <Styled.Container>
        {tabs.map(t => (
          <TabPanel key={t.value} value={selectedTab} compareValue={t.value}>
            {t.children}
          </TabPanel>
        ))}
        {hasNavigationBar && (
          <Styled.AppBar>
            <Styled.Tabs
              variant='scrollable'
              value={selectedTab}
              onChange={handleChange}
              scrollButtons={withScrollableTabs}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              {tabs.map(t => (
                <Styled.Tab
                  key={t.value}
                  label={t.label}
                  value={t.value}
                  disabled={t.disabled}
                />
              ))}
            </Styled.Tabs>
          </Styled.AppBar>
        )}
      </Styled.Container>
    );
  }
);

const TabPanel: React.FC<ITabPanel> = ({ value, compareValue, children }) => {
  return (
    <Box
      overflow='auto'
      width={'100%'}
      height={'100%'}
      hidden={value !== compareValue}
    >
      {value === compareValue && <Box height={'100%'}>{children}</Box>}
    </Box>
  );
};
