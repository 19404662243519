import { ThemeOptions } from '@mui/material';
import { SelectOverride, TooltipOverride } from '../overridedComponents';
import { breakpoints, darkPallet, typography } from '../constants';
import { customSpacing, IThemeOptions } from './index';

export const darkTheme = (options?: IThemeOptions): ThemeOptions => {
  return {
    components: {
      MuiTooltip: TooltipOverride,
      MuiSelect: SelectOverride(options),
    },
    typography,
    breakpoints,
    palette: options?.palette ? options.palette : darkPallet,
    spacing: customSpacing,
  };
};
