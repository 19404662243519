import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { useInstrument } from '@trader/hooks';

export const TradingInitializer = observer(
  ({ children }: { children: React.ReactNode }) => {
    const store = useMst();

    const trading = store.trading.getTrading('createOrder');

    const symbol = store.pages.trading.getInstrumentSymbolByLayout();
    const entity = store.entities.instruments.get<TInstrumentEntity>(symbol);

    const handleUpdate = (askValue: number, bidValue: number) => {
      const ask = +formatByPipSize(askValue, entity?.pipSize);
      const bid = +formatByPipSize(bidValue, entity?.pipSize);

      store.entities.instruments.update(symbol, { ask, bid });
    };

    useEffect(() => {
      if (symbol) {
        (async () => {
          await trading.getInstrumentReferenceAsync.run({
            symbol,
            side: null,
          });
        })();
      }
    }, [symbol]);

    useInstrument(
      symbol,
      entity?.spreadDiff,
      entity?.spreadDiffBalance,
      entity?.pipSize,
      handleUpdate
    );

    return children;
  }
);
