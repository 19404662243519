import { orderBy } from 'lodash';

import { TSortOrder, TSortType } from '../index';

interface ISortRowsParams<T> {
  sortByCellId?: string;
  rows: Array<T>;
  sortOrder?: TSortOrder;
  sortType?: TSortType;
}

export const sortRows = <T>({
  sortByCellId,
  rows,
  sortOrder = 'asc',
  sortType = 'number',
}: ISortRowsParams<T>): Array<T> => {
  if (sortByCellId) {
    return orderBy(
      rows,
      [
        row => {
          const value = row[sortByCellId];
          if (sortType === 'date') {
            return new Date(value).getTime();
          }
          return value;
        },
      ],
      [sortOrder]
    );
  }

  return rows;
};
