import * as yup from 'yup';

import {
  useAmountSchema,
  usePriceSchema,
  useProtectionSchema,
} from '@trader/hooks';
import { TCommonOrderType } from '@trader/types';

export const usePlaceOrderFormValidation = (type: TCommonOrderType) => {
  const { amountSchema } = useAmountSchema(type);
  const { priceSchema } = usePriceSchema(type);
  const { protectionSchema } = useProtectionSchema(type);

  const validationSchema = yup
    .object()
    .shape({
      ask: yup.number(),
      bid: yup.number(),
      side: yup.string().required(),
      orderType: yup.string(),
      amount: amountSchema,
      price: priceSchema,
    })
    .concat(protectionSchema);

  return { validationSchema };
};
