import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    flex-direction: column;

    > div {
      padding: 0 17px 0 17px;
      .MuiCheckbox-root {
        padding: 6px;
      }
      span {
        font-size: ${theme.typography.medium.fontSize};
      }
    }
  `};
`;
