import { useEffect, useState } from 'react';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

/**
 * Custom hook to monitor the internet connection status of the application.
 * Displays a warning notification when the application goes offline and automatically
 * reloads the page when the connection is restored.
 */
export const useInternetStatus = () => {
  const store = useMst();
  const { translate } = useI18next();
  const [isOnline, setIsOnline] = useState(true);

  const handleStatus = event => {
    setIsOnline(event.type === 'online');

    if (event.type !== 'online') {
      store.notifications.add({
        message: translate('NOTIFICATIONS.YOU_ARE_OFFLINE'),
        options: {
          variant: 'warning',
          autoHideDuration: 2000,
        },
      });
    } else {
      window.location.reload();
    }
  };
  useEffect(() => {
    window.addEventListener('offline', handleStatus);
    window.addEventListener('online', handleStatus);

    return () => {
      window.removeEventListener('offline', handleStatus);
      window.removeEventListener('online', handleStatus);
    };
  });

  return isOnline;
};
