import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';
import { searchInstrumentHeight } from '@trader/constants';

import { IconButton } from '../iconButton';
import { Wrapper } from '../wrapper';
import { Icon } from '../icon';

interface IRoot {
  $isStatic?: boolean;
}

export const Root = styled(Wrapper)<IRoot>`
  ${({ $isStatic, theme }) => css`
    width: 100%;
    height: ${searchInstrumentHeight}px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(128, 128, 128, 0.42);
    z-index: ${theme.zIndex.modal};
    ${conditionApply(
      [$isStatic],
      css`
        position: inherit;
        height: initial;
        background-color: transparent;
      `
    )}
  `}
`;

export const Close = styled(IconButton)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 1;
    width: 40px;
    height: 40px;
    background: ${theme.palette.common.white};
  `}
`;

export const Search = styled(Icon)`
  ${({ theme }) => css`
    width: 18px;
    height: 18px;
    margin-left: 6px;
    color: ${theme.palette.common.black};
  `}
`;

export const InputWrapper = styled(Wrapper)<IRoot>`
  ${({ theme, $isStatic }) => css`
    background: ${theme.palette.background.paper};
    width: 90%;
    position: relative;
    border-radius: 4px;

    .MuiInputBase-root {
      padding: 6px;
    }

    & input::placeholder {
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
      color: ${theme.palette.text.secondary};
    }
    ${conditionApply(
      [$isStatic],
      css`
        width: 100%;
        border: none;

        fieldset {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid ${theme.palette.background.default};
        }
      `
    )}
  `}
`;
