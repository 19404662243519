import React from 'react';
import { SliderOwnProps } from '@mui/material';

import * as Styled from './styled';
import { IChangeFieldEvent } from '@trader/types';
import { ControlInfo } from '../controlInfo';

export interface ISlider extends Omit<SliderOwnProps, 'value' | 'onChange'> {
  label?: string;
  isLabelValue?: boolean;
  value?: number;
  isError?: boolean;
  helperText?: string;
  name?: string;
  shouldHideControlInfo?: boolean;
  onChange?: (event: IChangeFieldEvent<number | number[]>) => void;
}

export const Slider: React.FC<ISlider> = React.forwardRef<
  HTMLInputElement,
  ISlider
>((props, ref) => {
  const handleChange = (event: Event, value: number | number[]) => {
    props.name &&
      props.onChange &&
      props.onChange({
        target: {
          name: props.name,
          value,
        },
      });
  };

  return (
    <Styled.Root>
      {props.label && (
        <Styled.Label>
          {props.label}{' '}
          <span>{props.isLabelValue ? props.value?.toFixed(2) : ''}</span>
        </Styled.Label>
      )}
      <Styled.FormControlLabel
        ref={ref}
        control={
          <Styled.Slider
            onChange={handleChange}
            value={props.value || 0}
            min={props.min}
            max={props.max}
            step={props.step}
            disabled={props.disabled}
          />
        }
        label={''}
      />
      <ControlInfo
        isError={props.isError}
        helperText={props.helperText}
        isDisabled={props.disabled}
        isHide={props.shouldHideControlInfo}
      />
    </Styled.Root>
  );
});
