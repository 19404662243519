import * as React from 'react';
import Moveable from 'react-moveable';

import { useMst } from '@trader/store';

import { Root } from './styled';

interface IMoveableWrapper {
  elementRefToMove: React.RefObject<HTMLDivElement | null>;
}

export const MoveableWrapper: React.FC<IMoveableWrapper> = ({
  elementRefToMove,
}) => {
  const store = useMst();
  return (
    <Root>
      <Moveable
        className={'root_moveable'}
        target={elementRefToMove}
        draggable
        snappable
        origin={true}
        throttleDrag={1}
        edgeDraggable={false}
        bounds={{
          left: 0,
          top: 0,
        }}
        onDrag={e => {
          e.target.style.transform = e.transform;
        }}
        onRender={e => {
          store.pages.muliBands.updateDataBoxPosition(
            e.moveable.getRect().top,
            e.moveable.getRect().left
          );
        }}
      />
    </Root>
  );
};
