import React from 'react';
import { BoxProps } from '@mui/material';

import { TIconsName } from '@trader/assets';

import * as Styled from './styled';

interface IInfoChip extends BoxProps {
  iconType: TIconsName;
  iconSize?: string;
  text: string | React.ReactNode;
  textColor: string;
  backgroundColor: string;
}

export const InfoChip: React.FC<IInfoChip> = ({
  iconType,
  iconSize,
  text,
  textColor,
  backgroundColor,
  ...boxProps
}) => {
  return (
    <Styled.Root
      $backgroundColor={backgroundColor}
      $iconColor={textColor}
      {...boxProps}
    >
      <Styled.Icon iconType={iconType} $width={iconSize} $height={iconSize} />
      <Styled.Text $color={textColor}>{text}</Styled.Text>
    </Styled.Root>
  );
};
