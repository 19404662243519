import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { EChallenge } from '@trader/types';
import { useMst } from '@trader/store';

import * as Styled from './styled';

export const BulkActions = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const masterChallenges = store.pages.copier.getChallengesByType(
    EChallenge.Master
  );

  return (
    <Styled.Action>
      <Styled.ActionButton
        $isTransparent
        disabled={
          !store.pages.copier.selectedChallenges.length ||
          !masterChallenges.length
        }
        size='large'
        onClick={() => store.pages.copier.assignSlaveAsync.run({})}
      >
        {translate('COPIER.BULK_CREATE_SLAVE_ACC')}
      </Styled.ActionButton>
      <Styled.ActionButton
        disabled={!store.pages.copier.selectedChallenges.length}
        size='large'
        onClick={() => store.pages.copier.assignMasterAsync.run()}
      >
        {translate('COPIER.BULK_CREATE_MASTER_ACC')}
      </Styled.ActionButton>
    </Styled.Action>
  );
});
