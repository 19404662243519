import { types, Instance } from 'mobx-state-tree';
import {
  loginAsync,
  ssoAsync,
  authRefreshTokenAsync,
  authGetTokenAsync,
  signUpAsync,
  changePasswordAsync,
} from './actions';
import { runInAction } from '@trader/utils';
import { IGetAuthTokenBE } from '@trader/api';
import { localStorageService, SmartLookService } from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { userActivity } from './userActivity';

const initialTokens = {
  accessToken: '',
  expiresIn: 0,
  idToken: '',
  refreshToken: '',
  tokenType: '',
  cognitoId: '',
  sessionId: '',
  authorizedSessionTimestamp: 0,
};

const tokens = types
  .model({
    accessToken: types.string,
    expiresIn: types.number,
    idToken: types.string,
    refreshToken: types.string,
    tokenType: types.string,
    cognitoId: types.string,
    sessionId: types.string,
    authorizedSessionTimestamp: types.optional(types.number, 0),
  })
  .actions(self => ({
    runInAction,
    setTokens(
      data: Partial<IGetAuthTokenBE & { authorizedSessionTimestamp: number }>
    ) {
      if (data) {
        Object.keys(data).forEach(key => {
          self[key] = data[key];
        });
      }
    },
    reset() {
      this.setTokens(initialTokens);
    },
  }));

const Auth = types
  .model('Auth', {
    isAuth: types.boolean,
    isAuthChecked: types.boolean,
    userActivity: userActivity,
    tokens: types.optional(tokens, initialTokens),
    tempData: types.optional(types.string, ''),
    loginAsync,
    ssoAsync,
    authGetTokenAsync,
    authRefreshTokenAsync,
    signUpAsync,
    changePasswordAsync,
  })
  .actions(_self => ({
    runInAction,
    logOut: () => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.access);
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.refresh);
      localStorageService.remove(
        LOCAL_STORAGE_KEYS.tokens.authorizedSessionTimestamp
      );
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.idToken);
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.expiresIn);
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.tokenType);
      localStorageService.remove(LOCAL_STORAGE_KEYS.tokens.sessionId);
      localStorageService.remove(LOCAL_STORAGE_KEYS.version);
      localStorageService.remove(LOCAL_STORAGE_KEYS.persist);
      _self.isAuth = false;
      _self.isAuthChecked = true;
      _self.tokens.reset();
      SmartLookService.track('LogOut');
      SmartLookService.ending();
    },
  }));

export const auth = types.optional(Auth, {
  isAuth: false,
  isAuthChecked: false,
});

export interface IAuthStore extends Instance<typeof Auth> {}
