import { ETradeMode, TPlaceOrderSide } from '@trader/types';

export type TAvailableOperations = {
  open: boolean;
  edit: boolean;
  close: boolean;
};

const allDisabled: TAvailableOperations = {
  open: false,
  edit: false,
  close: false,
};
const allEnabled: TAvailableOperations = {
  open: true,
  edit: true,
  close: true,
};

export const getAllowedOperations = (
  tradeMode: ETradeMode,
  side: TPlaceOrderSide
): TAvailableOperations => {
  switch (tradeMode) {
    case ETradeMode.Full:
      return allEnabled;

    case ETradeMode.BuyOnly:
      return {
        open: side === 'Buy',
        edit: side === 'Buy',
        close: side === 'Buy',
      };

    case ETradeMode.SellOnly:
      return {
        open: side === 'Sell',
        edit: side === 'Sell',
        close: side === 'Sell',
      };

    case ETradeMode.CloseOnly:
      return { ...allDisabled, close: true };

    case ETradeMode.Disabled:
    default:
      return allDisabled;
  }
};
