import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EStripName,
  IChangeFieldEvent,
  IOption,
  TOptions,
} from '@trader/types';
import { BasicAutocomplete } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import * as Styled from './styled';

const listOptions = [
  EStripName.Strip025Low,
  EStripName.Strip0HalfLow,
  EStripName.Strip075Low,
  EStripName.Strip1Low,
  EStripName.Strip025High,
  EStripName.Strip0HalfHigh,
  EStripName.Strip075High,
  EStripName.Strip1High,
];

export const BandFilter: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const options = listOptions.map(o => ({
    title: EStripName[o],
    value: o,
  })) as unknown as TOptions;

  const backTesting = store.pages.muliBands.backTesting;

  const onChange = (event: IChangeFieldEvent<IOption | null>) => {
    backTesting.runInAction(() => {
      backTesting.selectedBandOption = event.target.value;
    });
  };

  return (
    <Styled.Content>
      <BasicAutocomplete
        label={translate('MULI_BANDS.BAND')}
        value={backTesting.selectedBandOption || undefined}
        options={options}
        onChange={onChange}
      />
    </Styled.Content>
  );
});
