import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 314px;
    height: 167px;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid ${theme.palette.aqua.main};

    > img {
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Body = styled(Wrapper)`
  ${() => css`
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const Profit = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.black.main};
    display: block;
    font-size: 2.18rem;
    font-weight: ${theme.typography.fontWeightBold};
  `}
`;

export const Price = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: center;
    text-transform: uppercase;

    :nth-child(1) {
      color: ${theme.palette.black.main};
      font-size: ${theme.typography.large.fontSize};
    }

    :nth-child(2) {
      color: ${theme.palette.black.main};
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
      margin-left: 2px;
    }
  `}
`;

export const PurchasedButton = styled('button')`
  ${({ theme }) => css`
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    padding: 16px 28px;
    border: 1px solid ${theme.palette.aqua.main};
    border-radius: 5px;
    background-color: ${theme.palette.white.main};
    cursor: pointer;
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `}
`;

export const InstantChallengeIcon = styled('div')`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 3px;
    right: 8px;
    top: 8px;
    background-color: ${theme.palette.purple.light};
  `}
`;
