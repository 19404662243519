import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  AppBar as MuiAppBar,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';
import {
  modalHorizontalPadding,
  layoutChildrenRadius,
} from '@trader/constants';

import { Wrapper } from '../wrapper';

interface IPosition {
  $tabsPosition?: 'top' | 'bottom';
  $marginBottom?: string;
  $padding?: string;
  $hasBottomBorder?: boolean;
}

export const Tabs = styled(MuiTabs)<IPosition>`
  ${({
    theme,
    $tabsPosition,
    $hasBottomBorder,
    $marginBottom,
    $padding,
  }) => css`
    width: 100%;
    margin-top: 10px;
    align-items: center;
    padding: ${$padding !== undefined
      ? $padding
      : `0 ${modalHorizontalPadding}px`};
    border-top: 1px solid ${theme.palette.background.default};

    span {
      text-transform: capitalize;
    }

    > div {
      display: flex;
      justify-content: flex-end;

      ${theme.breakpoints.down('sm')} {
        justify-content: inherit;
      }

      ${conditionApply(
        [$tabsPosition === 'top'],
        css`
          justify-content: flex-start;
        `
      )};
    }

    ${conditionApply(
      [$tabsPosition === 'top'],
      css`
        margin-bottom: ${$marginBottom || '15px'};
        border-top: none;
        margin-top: 0;
        padding-bottom: 10px;
        border-bottom: ${$hasBottomBorder
          ? `1px solid ${theme.palette.background.default}`
          : 'none'};

        ${theme.breakpoints.down('sm')} {
          padding-top: 7px;
          padding-bottom: 7px !important;
          border-top: 1px solid ${theme.palette.background.default};
          border-bottom: 1px solid ${theme.palette.background.default} !important;
        }
      `
    )};
  `}
`;

export const Tab = styled(MuiTab)`
  ${({ theme }) => css`
    text-transform: capitalize;
    min-width: 50px;
    padding: 10px;
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    height: 30px;
    min-height: 30px;
    border-radius: 5px;
    margin-right: 5px;
    color: ${theme.palette.common.black};

    &.Mui-selected {
      background-color: ${theme.palette.tab.main};
      color: ${theme.palette.tab.light};
    }

    &.Mui-disabled {
      color: ${theme.palette.tab.disabled};
    }
  `};
`;

export const Container = styled(Wrapper)<IPosition>`
  ${({ theme, $tabsPosition }) => css`
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    height: 47px;
    background: ${theme.palette.background.paper};
    flex-direction: column;
    border-radius: ${layoutChildrenRadius}px;

    ${conditionApply(
      [$tabsPosition === 'top'],
      css`
        height: auto;
        flex-direction: column-reverse;
      `
    )};
  `};
`;

export const AppBar = styled(MuiAppBar)`
  position: static;
  width: auto;
  box-shadow: none;
  background: transparent;
`;
