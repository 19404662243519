import { types } from 'mobx-state-tree';

const settingsModel = types.model('settingsModel', {
  isTradingCentral: false,
  isCrmTradingCentral: false,
  isDisplayedByUser: false,
  isEdgeX: false,
  isCrmEdgeX: false,
  isEdgeXDisplayedByUser: false,
  isXStrategy: false,
  isCrmXStrategy: false,
  isXStrategyDisplayedByUser: false,
});

export const initialSettings = {
  tradingId: 0,
  isTradingCentral: false,
  isCrmTradingCentral: false,
  isDisplayedByUser: false,
  isEdgeX: false,
  isCrmEdgeX: false,
  isEdgeXDisplayedByUser: false,
  isXStrategy: false,
  isCrmXStrategy: false,
  isXStrategyDisplayedByUser: false,
};

export const settings = types.optional(settingsModel, initialSettings);
