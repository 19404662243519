import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Popover } from '@mui/material';

import { useI18next } from '@trader/services';
import { useMst, TFilterName, TDateFilters } from '@trader/store';
import { calculateDateInterval } from '@trader/utils';
import { EDateFilterTypes } from '@trader/types';

import { FilterTrigger } from './components/filterTrigger';
import { FilterList } from './components/filterList';
import { CustomDateSelection } from './components/customDateSelection';
import * as Styled from './styled';

interface IDateFilter {
  filterName: TFilterName;
  dateFilterNames: {
    from: TDateFilters;
    to: TDateFilters;
  };
  label: string;
}

export const TableColumnDateFilter: FC<IDateFilter> = observer(
  ({ filterName, dateFilterNames, label }) => {
    const store = useMst();
    const filter = store.filters[filterName];
    const initialValues = {
      from: filter.date[dateFilterNames.from],
      to: filter.date[dateFilterNames.to],
      filterType:
        !!filter.date[dateFilterNames.from] || !!filter.date[dateFilterNames.to]
          ? filter.date.filterType
          : null,
    };

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedFilterType, setSelectedFilterType] =
      useState<EDateFilterTypes | null>(initialValues.filterType);
    const { translate } = useI18next();

    const isOpen = Boolean(anchorEl);
    const isFilterApplied =
      !!filter.date[dateFilterNames.from] || !!filter.date[dateFilterNames.to];
    const popoverId = isOpen ? 'date-filter' : undefined;

    useEffect(() => {
      if (!isFilterApplied && !!selectedFilterType) {
        setSelectedFilterType(null);
      }
    }, [filter.date[dateFilterNames.from], filter.date[dateFilterNames.to]]);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleReset = useCallback(() => {
      store.filters.resetDateFilters(filterName);
      setSelectedFilterType(null);
      handleClose();
    }, []);

    const handleCustomFilterApply = useCallback(
      (from: Date | null, to: Date | null) => {
        store.filters.setDateFilters(
          filterName,
          {
            [dateFilterNames.from]: !from ? '' : from.toISOString(),
            [dateFilterNames.to]: !to ? '' : to.toISOString(),
          },
          EDateFilterTypes.CUSTOM
        );
        handleClose();
      },
      []
    );

    const handleChange = useCallback(
      (filterType: EDateFilterTypes) => {
        if (filterType === selectedFilterType) {
          handleReset();
          return;
        }

        if (filterType === 'custom') {
          setSelectedFilterType(filterType);
          store.filters.setDateFilters(
            filterName,
            {
              [dateFilterNames.from]: '',
              [dateFilterNames.to]: '',
            },
            EDateFilterTypes.CUSTOM
          );
          return;
        }

        const dateInterval = calculateDateInterval(filterType);
        store.filters.setDateFilters(
          filterName,
          {
            [dateFilterNames.from]: dateInterval.from.toISOString(),
            [dateFilterNames.to]: dateInterval.to.toISOString(),
          },
          filterType
        );
        setSelectedFilterType(filterType);
        handleClose();
      },
      [selectedFilterType]
    );

    return (
      <>
        <FilterTrigger
          label={label}
          popoverId={popoverId}
          isFilterApplied={isFilterApplied}
          onClick={event => setAnchorEl(event.currentTarget)}
        />
        <Popover
          id={popoverId}
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <Styled.FilterContent>
            <div>
              <FilterTrigger
                label={label}
                isFilterApplied={isFilterApplied}
                isStatic
              />
              <FilterList
                selectedFilterType={selectedFilterType}
                onFilterTypeClick={handleChange}
              />
              <Styled.ResetButton onClick={handleReset}>
                {translate('FILTERS.RESET')}
              </Styled.ResetButton>
            </div>
            {selectedFilterType === EDateFilterTypes.CUSTOM && (
              <CustomDateSelection
                initialValues={{
                  from: initialValues.from
                    ? new Date(initialValues.from)
                    : null,
                  to: initialValues.to ? new Date(initialValues.to) : null,
                }}
                onApply={handleCustomFilterApply}
              />
            )}
          </Styled.FilterContent>
        </Popover>
      </>
    );
  }
);
