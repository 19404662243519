import styled, { css } from 'styled-components';
import { Typography } from '@trader/components';

export const Text = styled(Typography)`
  ${() => css`
    white-space: normal;
  `}
`;

export const List = styled('ul')`
  ${({ theme }) => css`
    padding: 0 15px;
    margin: 6px 0;

    li {
      font-size: ${theme.typography.large.fontSize};
      text-align: justify;
    }

    li:first-child {
      margin-bottom: 6px;
    }
  `}
`;
