import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { defaultSubCategoryName } from '@trader/constants';
import { getAllowedOperations, runInAction } from '@trader/utils';
import { ETradeMode } from '@trader/types';

import { createCollectionModel } from '../../utils/collectionModel';
import {
  addInstrumentToFavouriteAsync,
  getExchangeRateInstrumentsAsync,
  getInstrumentBarsAsync,
  getInstrumentBarsIndicatorsAsync,
  getInstrumentCostAndChargesAsync,
  getInstrumentsAsync,
  getInstrumentSpecificationAsync,
  getInstrumentsSearchAsync,
  getInstrumentTradersTrendAsync,
  getRelatedInstrumentsAsync,
  removeInstrumentFromFavouriteAsync,
} from './actions';

const tradingHourModel = types.model('tradingHourModel', {
  from: types.string,
  to: types.string,
});

export const tradingSessionsModel = types.optional(
  types.array(
    types.model('sessionsModel', {
      tradingHours: types.array(tradingHourModel),
      quotesHours: types.array(tradingHourModel),
    })
  ),
  [
    {
      tradingHours: [{ from: '', to: '' }],
      quotesHours: [{ from: '', to: '' }],
    },
  ]
);
export const instrumentModel = types
  .model('instrumentModel', {
    symbol: types.identifier,
    close: types.number,
    currency: types.string,
    currencySymbol: types.string,
    description: types.string,
    displayName: types.string,
    high: types.number,
    iconUrl: types.maybeNull(types.string),
    isFavorite: types.boolean,
    low: types.number,
    open: types.number,
    contractSize: types.optional(types.number, 0),
    pipSize: types.number,
    tickSize: types.optional(types.number, 0),
    ask: types.number,
    bid: types.number,
    category: types.maybeNull(types.string),
    spreadDiff: types.optional(types.number, 0),
    spreadDiffBalance: types.optional(types.number, 0),
    stopsLevel: types.optional(types.number, 0),
    isOpenTrading: types.optional(types.boolean, false),
    disabledByMarketNews: types.optional(types.boolean, false),
    miniChartCoordinates: types.optional(types.array(types.number), [0]),
    swapRateLong: types.optional(types.number, 0),
    swapRateShort: types.optional(types.number, 0),
    swapType: types.optional(types.number, 0),
    swapRates: types.optional(types.array(types.number), []),
    minOrderSize: types.optional(types.number, 0),
    minOrderSizeIncrement: types.optional(types.number, 0),
    maxOrderSize: types.optional(types.number, 0),
    baseCurrency: types.optional(types.string, ''),
    tradingStatus: types.optional(types.maybeNull(types.string), ''),
    sessions: tradingSessionsModel,
    holidays: types.optional(types.map(types.array(tradingHourModel)), {}),
    rolloverDate: types.optional(types.maybeNull(types.string), ''),
    calcMode: types.optional(types.number, -1),
    tickValue: types.optional(types.number, 1),
    tradeMode: types.optional(
      types.union(
        types.literal(ETradeMode.Full),
        types.literal(ETradeMode.BuyOnly),
        types.literal(ETradeMode.SellOnly),
        types.literal(ETradeMode.CloseOnly),
        types.literal(ETradeMode.Disabled)
      ),
      ETradeMode.Full
    ),

    // only FE fields.
    isPopular: types.optional(types.boolean, false),
    subCategory: types.optional(types.string, defaultSubCategoryName),
    updateAskType: types.optional(types.string, 'none'),
    updateBidType: types.optional(types.string, 'none'),
    tradingAvailability: types.optional(
      types.model('tradingAvailabilityModel', {
        isUnavailable: types.boolean,
        isHoliday: types.boolean,
        openIn: types.string,
      }),
      { isUnavailable: false, isHoliday: false, openIn: '' }
    ),

    getInstrumentBarsAsync,
    getRelatedInstrumentsAsync,
    getInstrumentBarsIndicatorsAsync,
    addInstrumentToFavouriteAsync,
    removeInstrumentFromFavouriteAsync,
    getInstrumentCostAndChargesAsync,
    getInstrumentSpecificationAsync,
    getInstrumentTradersTrendAsync,
  })
  .views(store => ({
    allowedOperations: () => {
      return {
        buy: getAllowedOperations(store.tradeMode, 'Buy'),
        sell: getAllowedOperations(store.tradeMode, 'Sell'),
      };
    },
  }))
  .actions(() => ({
    runInAction,
  }));

interface IInstrumentsAsync {
  getInstrumentsAsync: typeof getInstrumentsAsync;
  getInstrumentsSearchAsync: typeof getInstrumentsSearchAsync;
  getInstrumentSpecificationAsync: typeof getInstrumentSpecificationAsync;
  getExchangeRateInstrumentsAsync: typeof getExchangeRateInstrumentsAsync;
}

export const instruments = createCollectionModel<
  typeof instrumentModel,
  IInstrumentsAsync
>(
  instrumentModel,
  {
    getInstrumentsAsync,
    getInstrumentsSearchAsync,
    getInstrumentSpecificationAsync,
    getExchangeRateInstrumentsAsync,
  },
  {}
);

export type TInstrumentEntity = Instance<typeof instrumentModel>;
export type TInstrumentEntityIn = SnapshotIn<typeof instrumentModel>;

export type TTradingHoursModel = SnapshotIn<typeof tradingHourModel>;
