import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import { Challenges } from '@trader/containers';
import { TChallengeEntity, useMst } from '@trader/store';
import { EChallenge, EChallengeStatus } from '@trader/types';
import { Button, InfoChip } from '@trader/components';
import { isChallengeAccountType, NAVIGATE_TO } from '@trader/constants';
import { IChallenge as IChallengeBE } from '@trader/api';
import { useI18next } from '@trader/services';
import { useNavigation } from '@trader/hooks';

import * as Styled from './styled';

export const ChallengesList = observer(() => {
  const { translate } = useI18next();
  const { navigateTo } = useNavigation();
  const theme = useTheme();

  const store = useMst();
  const activeChallenge = store.user.getActiveAccountChallenge();
  const isOneMasterAccount = store.entities.challenges
    .getAll<TChallengeEntity>()
    .some(ch => ch.hierarchyType === EChallenge.Master);

  const translationMap: Record<IChallengeBE['result'], string> = {
    Completed: translate('COMMON.LABELS.COMPLETED'),
    Failed: translate('COMMON.LABELS.FAILED'),
    Cancelled: translate('COMMON.LABELS.CANCELLED'),
    'In Progress': translate('COMMON.LABELS.IN_PROGRESS'),
  };

  return (
    <Styled.Root>
      {activeChallenge?.status === EChallengeStatus.Disable && (
        <InfoChip
          text={
            <>
              {translate('WARNINGS.CHALLENGE_STATUS', {
                status: translationMap[activeChallenge?.result]?.toLowerCase(),
              })}{' '}
              <Button
                onClick={() =>
                  isChallengeAccountType
                    ? navigateTo(NAVIGATE_TO.main.purchaseChallenges)
                    : window.open(import.meta.env.VITE_REGISTRATION_LINK)
                }
                size='small'
                variant='text'
              >
                <span style={{ fontWeight: 600 }}>
                  {translate('COMMON.LABELS.PURCHASE').toLowerCase()}
                </span>
              </Button>{' '}
              {translate('COMMON.LABELS.A_NEW_ONE')}.
            </>
          }
          textColor={theme.palette.red.main}
          backgroundColor={theme.palette.red['200']}
          iconType='challengeFailed'
          marginBottom='12px'
        />
      )}
      <Challenges
        shouldDisplayAccTypeIcon={isOneMasterAccount}
        shouldDisplayStatus
        shouldDisplayResult
        isFullHeight
      />
    </Styled.Root>
  );
});
