import { millisecondsInOneMinute, sixty, ten, thousand } from './constants';

export const calcMillisecondsUntilNextMinute = () => {
  const now = new Date();
  const currentMilliseconds = now.getMilliseconds();
  const currentSeconds = now.getSeconds();
  const millisecondsUntilNextMinute =
    (sixty - currentSeconds) * thousand - currentMilliseconds;

  return millisecondsUntilNextMinute;
};

export const getUTCDate = () => {
  const now = new Date();
  return new Date(
    now.getTime() + now.getTimezoneOffset() * millisecondsInOneMinute
  );
};

export const getTime = (date: Date): string => date.toLocaleTimeString('en-GB');

/**
 * Pads a date part (e.g., day or month) with a leading zero if it is less than 10.
 *
 * @param value - The date part (day or month) to be padded.
 * @returns A string representation of the date part, padded with a leading zero if necessary.
 *          If the value is 10 or greater, it is returned as is.
 *
 * Example:
 * padDatePart(5);  // Returns "05"
 * padDatePart(12); // Returns "12"
 */
export const padDatePart = (datePart: number) =>
  datePart < ten ? `0${datePart}` : datePart;

export const getTimeInSeconds = (time?: number) =>
  Math.floor((time || new Date().getTime()) / thousand);

/**
 * Builds a UTC date object based on a given time string (HH:mm format).
 *
 * @param time - A string representing the time in "HH:mm" format.
 * @returns A Date object set to the current UTC date with the specified time (hours and minutes).
 *
 * Example:
 * buildUtcDateBasedOnTime("14:30"); // Returns a Date object for today's UTC date at 14:30 (2:30 PM UTC).
 */
export const buildUtcDateBasedOnTime = (time: string) => {
  const date = getUTCDate();

  const hours = +time.split(':')[0];
  const minutes = +time.split(':')[1];
  date.setHours(hours, minutes);

  return date;
};

export const getEndOfDayDate = (date: Date) =>
  // eslint-disable-next-line no-magic-numbers
  new Date(date.setHours(23, 59, 59));
