import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';

/**
 * Custom hook to check if the user's password has expired.
 */
export const useExpiredPasswordChecker = () => {
  const store = useMst();

  useEffect(() => {
    if (store.user.profile.isPasswordExpired === true) {
      store.ui.modal.open(MODAL_TYPES.expiredPassword, {
        shouldHideCloseButton: true,
      });
    }
  }, [store.user.profile.isPasswordExpired]);
};
