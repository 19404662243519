import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  AppBar as MuiAppBar,
  Box as MuiBox,
  Skeleton as MuiSkeleton,
  TabsProps,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';
import { searchInstrumentHeight } from '@trader/constants';

import { Wrapper } from '../wrapper';

const minInstrumentsCategoryWidth = 75;

const countInstrumentCategoriesWidth = (longestCategory: string) => {
  const letterPerPixels = 5.5;
  const countedWidth = letterPerPixels * longestCategory.length;

  if (countedWidth < minInstrumentsCategoryWidth) {
    return minInstrumentsCategoryWidth;
  }

  return countedWidth;
};

export const Tabs = styled(MuiTabs)`
  span {
    text-transform: capitalize;
  }
`;
export const Label = styled(Wrapper)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 5px;
  }
`;

export const Tab = styled(MuiTab)`
  ${({ theme }) => css`
    text-transform: capitalize;
    height: 61px;
    min-width: ${minInstrumentsCategoryWidth}px;
    font-size: ${theme.typography.tiny.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    border-bottom: 1px solid ${theme.palette.background.default};

    &.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: white;
    }
  `};
`;

export const Skeleton = styled(MuiSkeleton)`
  ${() => css`
    text-transform: capitalize;
    height: 61px;
    width: ${minInstrumentsCategoryWidth}px;
    min-width: ${minInstrumentsCategoryWidth}px;
    margin: 1px;
  `};
`;

export const SkeletonContainer = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `};
`;

export const Container = styled(Wrapper)<{
  orientation?: TabsProps['orientation'];
}>`
  ${({ theme }) => css`
    flex-grow: 1;
    overflow: hidden;
    flex-direction: row;
    display: flex;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`;

export const Panel = styled(MuiBox)<{ $longestCategory: string }>`
  ${({ theme, $longestCategory }) => css`
    width: 100%;
    margin-left: ${countInstrumentCategoriesWidth($longestCategory)}px;

    ${theme.breakpoints.down('sm')} {
      height: 100%;
      margin-left: 0;
    }
  `}
`;

export const AppBar = styled(MuiAppBar)<{
  $longestCategory: string;
}>`
  ${({ theme, color, $longestCategory }) => css`
    position: absolute;
    left: 0;
    top: ${searchInstrumentHeight}px;
    width: ${countInstrumentCategoriesWidth($longestCategory)}px;
    height: calc(100% - ${searchInstrumentHeight}px);
    justify-content: space-between;
    box-shadow: none;
    border-right: 1px solid ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      display: none;
    }

    ${conditionApply(
      [color === 'default'],
      `
          background: transparent;
          `
    )}
  `}
`;
