import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
  `}
`;

export const ChallengeMessage = styled('span')`
  ${({ theme }) => css`
    margin-bottom: 4px;
    font-size: ${theme.typography.xl.fontSize};
    font-weight: 600;
    color: ${theme.palette.red.light};
    display: flex;
    align-items: center;

    > button {
      font-size: ${theme.typography.xl.fontSize};
      font-weight: 600;
      margin: 0 2px;
    }
  `}
`;
