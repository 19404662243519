import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${() => css`
    padding: 0 7px;
    width: 100%;
  `}
`;

export const PlaceOrder = styled(Button)<{ $isBuySide: boolean }>`
  ${({ theme, disabled, $isBuySide }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 15px;
    border-radius: 5px;
    padding: 14px 0;
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.purchaseButtons.red};

    &:hover {
      background-color: ${theme.palette.purchaseButtons.red};
    }

    ${conditionApply(
      [disabled],
      css`
        background-color: ${theme.palette.gray.main};

        &:hover {
          background-color: ${theme.palette.gray.main};
        }
      `
    )};

    ${conditionApply(
      [$isBuySide],
      css`
        background-color: ${theme.palette.purchaseButtons.green};

        &:hover {
          background-color: ${theme.palette.purchaseButtons.green};
        }
      `
    )};
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
  `}
`;
