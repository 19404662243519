import React from 'react';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import AssistantIcon from '@mui/icons-material/Assistant';

import { ModalNotification } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { sideBarWidth } from '@trader/constants';

import * as Styled from './styled';

const SUPPORT_EMAIL = import.meta.env.VITE_SUPPORT_EMAIL;
const LANDING_PAGE_LINK = import.meta.env.VITE_LANDING_PAGE_LINK;

export const SwitchingToBetaDesign: React.FC = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const shouldHideSwitchToOldButton = store.ui.modal.options.get(
    'shouldHideSwitchToOldButton'
  );

  const handleSwitchToOldDesignButtonClick = () => {
    store.ui.sidebar.updateLeftBarWidth(sideBarWidth);
    store.app.toggleDesignType();
    store.ui.modal.close();
  };

  return (
    <ModalNotification
      width='570px'
      icon={<AssistantIcon fontSize='large' />}
      title={translate('NOTIFICATIONS.NEW_DESIGN.TITLE')}
      content={
        <div>
          <Styled.Text variant='large' textAlign='justify'>
            <Trans i18nKey='NOTIFICATIONS.NEW_DESIGN.SUBTITLE' />
          </Styled.Text>
          <Styled.Text variant='xl' marginTop='12px'>
            {translate('NOTIFICATIONS.NEW_DESIGN.KEY_INFORMATION.TITLE')}:
          </Styled.Text>
          <Styled.List>
            <li>
              <Trans i18nKey='NOTIFICATIONS.NEW_DESIGN.KEY_INFORMATION.SWITCH_BACK_POINT' />
            </li>
            <li>
              {translate(
                'NOTIFICATIONS.NEW_DESIGN.KEY_INFORMATION.FEEDBACK_POINT'
              )}
            </li>
          </Styled.List>
          <Styled.Text variant='large' marginTop='12px'>
            <Trans
              i18nKey='NOTIFICATIONS.NEW_DESIGN.SUPPORT'
              values={{ email: SUPPORT_EMAIL }}
              components={{ a: <a /> }}
            />
          </Styled.Text>
          <Styled.Text variant='large' marginTop='6px'>
            <Trans
              i18nKey='NOTIFICATIONS.NEW_DESIGN.THANKS'
              values={{ link: LANDING_PAGE_LINK }}
              components={{
                a: <a target='_blank' rel='noopener noreferrer' />,
              }}
            />
          </Styled.Text>
        </div>
      }
      confirmButton={{
        text: translate('COMMON.LABELS.PROCEED'),
        onClick: store.ui.modal.close,
      }}
      refuseButton={
        shouldHideSwitchToOldButton
          ? undefined
          : {
              text: translate('NOTIFICATIONS.NEW_DESIGN.SWITCH_TO_OLD'),
              onClick: handleSwitchToOldDesignButtonClick,
            }
      }
    />
  );
});
