import { requestService } from '@trader/services';
import { notificationRoutes } from './routes';

export class Notification {
  routes = notificationRoutes;

  async initializeDevice(deviceToken: string): Promise<void> {
    const resp = await requestService.post<void>(this.routes.initializeDevice, {
      deviceToken,
    });
    return resp?.data;
  }
}
