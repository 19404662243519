import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Controller } from '@trader/components';
import { useI18next } from '@trader/services';

interface IPositionsCloseTime {
  isCloseTime: boolean;
  closeTime: Date | null;
}

export const PositionsCloseTime: React.FC = () => {
  const { translate } = useI18next();

  const { control, getValues } = useFormContext<IPositionsCloseTime>();

  return (
    <>
      <Controller
        type='switcherField'
        label={translate('MULI_BANDS.CLOSE_TIME')}
        name='isCloseTime'
        control={control}
        shouldHideControlInfo
      />
      {getValues().isCloseTime && (
        <Controller
          type='timePickerField'
          label={translate('MULI_BANDS.CLOSE_TIME')}
          name='closeTime'
          control={control}
        />
      )}
    </>
  );
};
