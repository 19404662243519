import { requestService, TAsyncActionOptions } from '@trader/services';
import { userRoutes } from './routes';
import {
  IGetTradingAccountsParams,
  IUserProfile,
  IUserSettings,
  IUserTradingAccount,
} from './models';

export class User {
  routes = userRoutes;

  async getUserProfile(options: TAsyncActionOptions): Promise<IUserProfile> {
    const resp = await requestService.get<IUserProfile>(
      this.routes.getProfile,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getUserSettings(options: TAsyncActionOptions): Promise<IUserSettings> {
    const resp = await requestService.get<IUserSettings>(
      this.routes.getSettings,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async updateUserSettings(
    isTradingCentral: boolean,
    isEdgeX: boolean,
    isXStrategy: boolean,
    options: TAsyncActionOptions
  ): Promise<IUserSettings> {
    const resp = await requestService.put<IUserSettings>(
      this.routes.getSettings,
      {
        isTradingCentral,
        isEdgeX,
        isXStrategy,
      },
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getTradingAccounts(
    options: TAsyncActionOptions,
    params?: IGetTradingAccountsParams
  ): Promise<Array<IUserTradingAccount>> {
    const resp = await requestService.get<Array<IUserTradingAccount>>(
      this.routes.getTradingAccounts,
      {
        ...options.cancelToken,
        params: {
          includePortfolio:
            params?.includePortfolio !== undefined
              ? params.includePortfolio
              : true,
          includePositions:
            params?.includePositions !== undefined
              ? params.includePositions
              : true,
        },
      }
    );

    return resp?.data;
  }
}
