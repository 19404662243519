/* eslint-disable no-magic-numbers */

import { TFormatterType, TSupportedLocales } from './types';
import { localeKey } from './constants';
import { getTime } from './dateHelpers';
import { convertToFullDate } from './dateConverters';

const formatDateToEN = (date: Date): string => date.toLocaleDateString('en-EN');
const formatDateToBY = (date: Date): string => date.toLocaleDateString('be-BY');
const formatTimeWithoutSeconds = (date: Date): string =>
  date.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });
const formatDateWithLongMonth = (
  date: Date,
  locale: TSupportedLocales = 'en'
): string => {
  const monthAndDay = date.toLocaleDateString(localeKey[locale], {
    month: 'long',
    day: '2-digit',
  });
  return `${monthAndDay}, ${date.getFullYear()}`;
};

export const formatDate = (
  date: number | Date,
  formatterType: TFormatterType,
  locale?: TSupportedLocales
) => {
  switch (formatterType) {
    case 'dd/mm/yyyy hh:mm':
      return `${formatDateToEN(
        convertToFullDate(date)
      )} ${formatTimeWithoutSeconds(convertToFullDate(date))}`;
    case 'dd/mm/yyyy; hh:mm:ss':
      return `${formatDateToEN(convertToFullDate(date))}; ${getTime(
        convertToFullDate(date)
      )}`;
    case 'dd.mm.yyyy':
      return formatDateToBY(convertToFullDate(date));
    case 'dd.mm.yyyy hh:mm:ss':
      return `${formatDateToBY(convertToFullDate(date))} ${getTime(
        convertToFullDate(date)
      )}`;
    case 'dd.mm.yyyy hh:mm':
      return `${formatDateToBY(convertToFullDate(date))} ${getTime(
        convertToFullDate(date)
      )
        .split(':')
        .slice(0, 2)
        .join(':')}`;
    case 'Mm dd, yyyy':
      return formatDateWithLongMonth(date as Date, locale);
    case 'Mm dd, yyyy hh:mm:ss':
      return `${formatDateWithLongMonth(date as Date, locale)} ${getTime(
        convertToFullDate(date)
      )}`;
    case 'hh:mm:ss':
      return `${getTime(convertToFullDate(date))}`;
    case 'YYYY-MM-DD': {
      const year = new Date(date).getUTCFullYear();
      const month = String(new Date(date).getUTCMonth() + 1).padStart(2, '0');
      const day = String(new Date(date).getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    case 'dd/mm/yyyy hh:mm:ss':
    default:
      return `${formatDateToEN(convertToFullDate(date))} ${getTime(
        convertToFullDate(date)
      )}`;
  }
};
