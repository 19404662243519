import { types } from 'mobx-state-tree';

import { bottomBarHeight, sideBarWidth } from '@trader/constants';
import { getRootInstance } from '../../configureStore/configureStore';

export enum ELeftBarColumnNames {
  Buy = 'buy',
  Sell = 'sell',
  Spread = 'spread',
  Change = 'change',
  DailyTrend = 'dailyTrend',
  Favourite = 'favourite',
}

const sidebarModel = types
  .model('sidebarModel', {
    leftBar: types.model('leftBarModel', {
      width: types.number,
      isShrinked: types.boolean,
      includedColumns: types.model('leftBarColumnsModel', {
        [ELeftBarColumnNames.Buy]: types.boolean,
        [ELeftBarColumnNames.Sell]: types.boolean,
        [ELeftBarColumnNames.Spread]: types.boolean,
        [ELeftBarColumnNames.Change]: types.boolean,
        [ELeftBarColumnNames.DailyTrend]: types.boolean,
        [ELeftBarColumnNames.Favourite]: types.boolean,
      }),
    }),
    rightBar: types.model('rightBarModel', {
      orderSection: types.model('orderSectionModel', {
        isOpen: types.boolean,
      }),
      infoSection: types.model('orderSectionModel', {
        isOpen: types.boolean,
        contentType: types.union(
          types.literal('keyStatistics'),
          types.literal('tradingCentral')
        ),
      }),
    }),
    bottomBar: types.model('bottomBarModel', {
      height: types.number,
      isShrinked: types.boolean,
    }),
  })
  .views(store => ({
    isRightBarHidden() {
      return (
        !store.rightBar.orderSection.isOpen &&
        !store.rightBar.infoSection.isOpen
      );
    },
    isRightBarKeyStatisticsOpened() {
      return (
        store.rightBar.infoSection.isOpen &&
        store.rightBar.infoSection.contentType === 'keyStatistics'
      );
    },
    isRightBarTradingCentralOpened() {
      return (
        store.rightBar.infoSection.isOpen &&
        store.rightBar.infoSection.contentType === 'tradingCentral'
      );
    },
    rightBarOpenedSectionsCount() {
      let count = 0;
      if (store.rightBar.orderSection.isOpen) count++;
      if (store.rightBar.infoSection.isOpen) count++;
      return count;
    },
  }))
  .actions(store => ({
    updateLeftBarWidth(value: number) {
      store.leftBar.width = value;
    },
    expandLeftBar() {
      store.leftBar.isShrinked = false;
    },
    shrinkLeftBar() {
      store.leftBar.isShrinked = true;
    },
    toggleLeftBarColumn(column: ELeftBarColumnNames) {
      store.leftBar.includedColumns[column] =
        !store.leftBar.includedColumns[column];
    },
    openRightBarOrderSection() {
      store.rightBar.orderSection.isOpen = true;
    },
    closeRightBarOrderSection() {
      store.rightBar.orderSection.isOpen = false;

      const root = getRootInstance();
      root.trading.getTrading('createOrder').clear();
    },
    openRightBarInfoSection(contentType: 'keyStatistics' | 'tradingCentral') {
      store.rightBar.infoSection = {
        isOpen: true,
        contentType: contentType,
      };
    },
    closeRightBarInfoSection() {
      store.rightBar.infoSection.isOpen = false;
    },
    updateBottomBarHeight(value: number) {
      store.bottomBar.height = value;
    },
    expandBottomBar() {
      store.bottomBar.isShrinked = false;
    },
    shrinkBottomBar() {
      store.bottomBar.isShrinked = true;
    },
  }));

export const sidebar = types.optional(sidebarModel, {
  leftBar: {
    width: sideBarWidth,
    isShrinked: false,
    includedColumns: {
      [ELeftBarColumnNames.Buy]: true,
      [ELeftBarColumnNames.Sell]: true,
      [ELeftBarColumnNames.Spread]: true,
      [ELeftBarColumnNames.Change]: true,
      [ELeftBarColumnNames.DailyTrend]: true,
      [ELeftBarColumnNames.Favourite]: true,
    },
  },
  rightBar: {
    orderSection: {
      isOpen: false,
    },
    infoSection: {
      isOpen: false,
      contentType: 'keyStatistics',
    },
  },
  bottomBar: {
    height: bottomBarHeight,
    isShrinked: false,
  },
});
