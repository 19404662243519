import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Checkbox } from '@trader/components';

import * as Styled from './styled';

export const General: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const indicators = store.pages.muliBands.indicators;

  return (
    <Styled.Root>
      {indicators.map(indicator => (
        <Checkbox
          label={indicator.title}
          checked={indicator.isActive}
          key={indicator.name}
          onChange={indicator.toggleActive}
          size='small'
        />
      ))}
      <Checkbox
        label={translate('MULI_BANDS.DATA_BOX')}
        checked={store.pages.muliBands.dataBox.isOpen}
        onChange={store.pages.muliBands.toggleDataBox}
        size='small'
      />
    </Styled.Root>
  );
});
