import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const UncheckedCheckboxIcon = styled('div')<{ $checkboxSize: string }>`
  ${({ theme, $checkboxSize }) => css`
    width: ${$checkboxSize};
    height: ${$checkboxSize};
    border: 1px solid ${theme.palette.gray.dark};
    border-radius: 2px;

    svg {
      min-width: 7px;
      width: ${`calc(${$checkboxSize} / 1.75)`};
      min-height: 7px;
      height: ${`calc(${$checkboxSize} / 1.75)`};
    }
  `};
`;

export const CheckedCheckboxIcon = styled(Wrapper)<{ $checkboxSize: string }>`
  ${({ theme, $checkboxSize }) => css`
    width: ${$checkboxSize};
    height: ${$checkboxSize};
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: ${theme.palette.primary.main};

    svg {
      min-width: 7px;
      width: ${`calc(${$checkboxSize} / 1.75)`};
      min-height: 7px;
      height: ${`calc(${$checkboxSize} / 1.75)`};
    }
  `};
`;
