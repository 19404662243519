import { requestService, TAsyncActionOptions } from '@trader/services';
import { bandStrategy } from './routes';
import {
  IBackTestConfig,
  IInitMuliBandsBody,
  IIsStrategyExist,
  IIsMuliBandsStrategy,
  IMuliBandsEditOrderParams,
  IMuliBandsPlaceOrderBody,
  IPingStrategyParams,
  IStrategyId,
} from './models';

export class BandStrategy {
  routes = bandStrategy;

  async initMuliBands(
    body: IInitMuliBandsBody,
    options: TAsyncActionOptions
  ): Promise<IStrategyId> {
    const resp = await requestService.post<IStrategyId>(
      this.routes.muliBands,
      body,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async deleteMuliBand(
    id: number,
    options: TAsyncActionOptions
  ): Promise<IStrategyId> {
    const resp = await requestService.delete<IStrategyId>(
      this.routes.muliBands,
      {},
      {
        params: { id },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async deleteMuliBandOrders(
    strategyId: number,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.deleteOrders,
      {},
      {
        params: { id: strategyId },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async deleteMuliBandSingleOrder(
    bandId: string,
    id: number,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.cancelMuliBandsOrder,
      {},
      {
        params: {
          bandId,
          id,
        },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async pingMuliBand(
    params: IPingStrategyParams,
    options: TAsyncActionOptions
  ): Promise<IStrategyId> {
    const resp = await requestService.post<IStrategyId>(
      this.routes.muliBandsPing,
      {},
      {
        params,
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async cancelBackTestRunning(options: TAsyncActionOptions): Promise<void> {
    const resp = await requestService.post<void>(
      this.routes.backTestCancel,
      {},
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getBackTestExists(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IIsStrategyExist> {
    const resp = await requestService.get<IIsStrategyExist>(
      this.routes.backTestExists,
      {
        params: { symbol },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getMuliBands(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IIsMuliBandsStrategy> {
    const resp = await requestService.get<IIsMuliBandsStrategy>(
      this.routes.muliBands,
      {
        params: { symbol },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getMuliBandsExisting(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IIsStrategyExist> {
    const resp = await requestService.get<IIsStrategyExist>(
      this.routes.muliBandsExisting,
      {
        params: { symbol },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async placeMuliBandsOrder(
    body: IMuliBandsPlaceOrderBody,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.post<void>(
      this.routes.placeMuliBandsOrder,
      body,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async editMuliBandsOrder(
    params: IMuliBandsEditOrderParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.put<void>(
      this.routes.editMuliBandsOrder,
      {},
      {
        ...options.cancelToken,
        params,
      }
    );
    return resp?.data;
  }

  async startBackTesting(
    body: IBackTestConfig,
    options: TAsyncActionOptions
  ): Promise<IStrategyId> {
    const resp = await requestService.post<IStrategyId>(
      this.routes.muliBands,
      body,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }
}
