import { isNumber } from 'lodash';

import { getPipSizeNumber } from '../trading';

export const isGrowTransfer = (transferType: string) =>
  transferType === 'Adjustment' || transferType === 'Deposit';

export const getSign = (
  amount: number,
  withSign?: boolean,
  transferType?: string
) => {
  if (withSign) {
    if (transferType) {
      if (isGrowTransfer(transferType)) {
        return '+';
      }
      return '-';
    }
    if (amount === 0) {
      return '';
    }
    if (amount > 0) {
      return '+';
    }
    return '-';
  }
  return '';
};

export const formatNumber = (
  value: number | string,
  options: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {}
) => {
  const number = Number(value) || 0;
  const { minimumFractionDigits = 0, maximumFractionDigits } = options;

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
};

export const formatPercents = (
  amount: number | undefined,
  options?: {
    hasSign?: boolean;
    pipSize?: number | string;
    fallBack?: string;
  }
) => {
  const { hasSign, pipSize = 2, fallBack = '--' } = options ?? {};

  if (!isNumber(amount)) {
    return `${fallBack}%`;
  }

  return `${getSign(amount!, hasSign)}${formatNumber(Math.abs(amount!), {
    maximumFractionDigits: getPipSizeNumber(pipSize),
    minimumFractionDigits: getPipSizeNumber(pipSize),
  })}%`;
};

export const formatMoney = (
  amount: number | undefined,
  options: {
    currencySymbol: string;
    hasSign?: boolean;
    pipSize?: number | string;
    hasDecimals?: boolean;
    fallBack?: string;
    transferType?: string;
  }
) => {
  const {
    currencySymbol,
    hasSign,
    pipSize = 2,
    fallBack = '--',
    hasDecimals = true,
    transferType,
  } = options;

  if (!isNumber(amount)) {
    return `${currencySymbol}${fallBack}`;
  }

  return `${getSign(
    amount!,
    hasSign,
    transferType
  )}${currencySymbol}${formatNumber(Math.abs(amount!), {
    maximumFractionDigits: getPipSizeNumber(pipSize),
    minimumFractionDigits: hasDecimals ? getPipSizeNumber(pipSize) : undefined,
  })}`;
};
