import { FC } from 'react';
import { useTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';

import { EChallenge } from '@trader/types';
import { TChallengeEntity, TTradingAccountEntity, useMst } from '@trader/store';
import { calcEquity, getGoalReached, getOverAllPl } from '@trader/utils';
import { useTradingAccountPositions } from '@trader/hooks';
import {
  CircularProgressBar,
  GoalReached,
  IHeadCell,
  RemainingTime,
} from '@trader/components';

import { useRenderCellActions } from '../../hooks';
import * as Styled from '../../styled';

interface IChallenge {
  challenge: TChallengeEntity;
  cells: Array<IHeadCell>;
  leftAction?: (challengeId: number) => JSX.Element;
  rightAction?: (challengeId: number) => JSX.Element;
  shouldDisplayAccTypeIcon?: boolean;
}

export const Challenge: FC<IChallenge> = observer(
  ({ challenge, cells, leftAction, rightAction, shouldDisplayAccTypeIcon }) => {
    const theme = useTheme();

    const store = useMst();
    const account = store.entities.tradingAccounts.get<TTradingAccountEntity>(
      challenge.accountId
    );
    const portfolio = account.portfolio;

    const { positionsPl } = useTradingAccountPositions(challenge.accountId);
    const {
      renderAccountIcon,
      renderProfitLoss,
      renderStatus,
      renderChangeAcc,
      renderResult,
    } = useRenderCellActions(challenge, account, shouldDisplayAccTypeIcon);

    const overallPl = getOverAllPl(
      portfolio.balance,
      challenge.challengeAmount,
      positionsPl
    );
    const equity = calcEquity(overallPl, portfolio.balance, 0);

    const renderRow = {
      trade: challenge.hierarchyType !== EChallenge.Slave && renderChangeAcc(),
      leftAction: leftAction && leftAction(challenge.id),
      accountIcon: renderAccountIcon(),
      id: challenge.hierarchyType !== EChallenge.Slave && (
        <Styled.Value>{challenge.id}</Styled.Value>
      ),
      status: renderStatus(),
      dailyTargetProfit: (
        <Styled.DailyTargetProfitIcon
          iconType='checkmark'
          $achieved={challenge.blockedDueToDailyTarget}
        />
      ),
      accountId: (
        <Styled.Value>
          {account.isInUse && <Styled.ActiveAccIcon iconType='activeAcc' />}
          {challenge.accountId}
        </Styled.Value>
      ),
      challengeAmount: (
        <Styled.Value>
          {account.currencySymbol}
          {challenge.challengeAmount}
        </Styled.Value>
      ),
      payout: <Styled.Value>{challenge.payout}%</Styled.Value>,
      balance: (
        <Styled.Value>
          {account.currencySymbol}
          {equity % 2 !== 0 ? equity.toFixed(2) : equity}
        </Styled.Value>
      ),
      profitLoss24: renderProfitLoss(positionsPl),
      profitLossOverall: renderProfitLoss(overallPl),
      tradingDays: (
        <Styled.Value>
          <CircularProgressBar
            value={challenge.tradingDays}
            maxValue={7}
            text={`${challenge.tradingDays}/${challenge.minTradingDays}`}
            pathColor={theme.palette.purchaseButtons.green}
          />
        </Styled.Value>
      ),
      remainingTime: <RemainingTime value={challenge.remainingTime} />,
      goalReached: (
        <GoalReached
          value={getGoalReached(
            challenge.profitTarget,
            overallPl,
            challenge.challengeAmount
          )}
          hasProgressBar
        />
      ),
      result: renderResult(),
      rightAction: rightAction && rightAction(challenge.id),
    };

    return (
      <Styled.Row className='challenge'>
        {cells.map(cell => (
          <span
            style={{ justifyContent: cell.align, minWidth: cell.minWidth }}
            key={`${cell.label}-${cell.id}`}
          >
            {renderRow[cell.id]}
          </span>
        ))}
      </Styled.Row>
    );
  }
);
