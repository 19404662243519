import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    margin-top: 10px;
    flex-direction: column;
    padding: 0 17px 0 17px;

    .tradingInput-customLabel {
      max-width: 115px;
    }
  `};
`;

export const Sl = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `};
`;

export const CloseTime = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    margin: 5px 0 10px 0;

    > div:nth-child(2) {
      margin-top: 15px;
    }
  `};
`;
