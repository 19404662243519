import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Action = styled(Wrapper)`
  ${() => css`
    margin: 16px 0 32px 0;
    justify-content: flex-start;

    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

interface IButton {
  $isTransparent?: boolean;
}

export const ActionButton = styled(Button)<IButton>`
  ${({ theme, $isTransparent, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 165px;
    height: 50px;
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.common.white};
    background: ${theme.palette.primary.dark};

    ${conditionApply(
      [$isTransparent],
      css`
        background: transparent;
        color: ${theme.palette.text.secondary};
        border: 1px solid ${theme.palette.primary.dark};
      `
    )}
    ${conditionApply(
      [disabled],
      css`
        background: ${!$isTransparent
          ? theme.palette.gray.light
          : 'transparent'} !important;
        border: ${!$isTransparent
          ? 'none'
          : `1px solid ${theme.palette.gray.light}`};
      `
    )}
    &:hover {
      background: ${$isTransparent
        ? 'transparent'
        : theme.palette.primary.dark};
    }
  `};
`;
