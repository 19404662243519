import { useState, Fragment } from 'react';

import { createDynamicComponent } from '@trader/utils';
import { useI18next } from '@trader/services';
import { Wrapper } from '@trader/components';

import { BackTestConfig } from './components/backTestConfig';
import { ListOfMultipliers } from './components/listOfMultipliers';
import { ListOfTrades } from './components/listOfTrades';
import { PerformanceSummary } from './components/performanceSummary';
import { LoadingProgressBar } from './components/loadingProgressBar';
import * as Styled from './styled';

type TSubTabs =
  | 'backTestConfig'
  | 'listOfMultiplies'
  | 'listOfTrades'
  | 'performanceSummary';

export const BackTestStrategyTab = () => {
  const { translate } = useI18next();
  const [selectedSubTab, setSelectedSubTab] =
    useState<TSubTabs>('backTestConfig');

  const subTabsItems = [
    { name: translate('MULI_BANDS.BACK_TEST_CONFIG'), value: 'backTestConfig' },
    {
      name: translate('MULI_BANDS.PERFORMANCE_SUMMARY'),
      value: 'performanceSummary',
    },
    {
      name: translate('MULI_BANDS.LIST_OF_MULTIPLIERS'),
      value: 'listOfMultiplies',
    },
    { name: translate('MULI_BANDS.LIST_OF_TRADES'), value: 'listOfTrades' },
  ];

  const subTabsContent = {
    backTestConfig: BackTestConfig,
    listOfMultiplies: ListOfMultipliers,
    listOfTrades: ListOfTrades,
    performanceSummary: PerformanceSummary,
  };

  return (
    <div>
      <Styled.SubTabs>
        <Wrapper>
          {subTabsItems.map((subTab, index) => (
            <Fragment key={subTab.name}>
              <Styled.SubTab
                onClick={() => setSelectedSubTab(subTab.value as TSubTabs)}
                $selected={selectedSubTab === subTab.value}
              >
                {subTab.name}
              </Styled.SubTab>
              {subTabsItems.length !== index + 1 && <Styled.Divider />}
            </Fragment>
          ))}
        </Wrapper>
        <LoadingProgressBar />
      </Styled.SubTabs>
      <Styled.Content>
        {createDynamicComponent(subTabsContent[selectedSubTab], {})}
      </Styled.Content>
    </div>
  );
};
