import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { adapterLineStyle, emptyFn } from '@trader/constants';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';

const slLineLength = 5;
let slLineAdapter: IOrderLineAdapter | undefined = undefined;

export const useDisplayStopLoss = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken;

  const trading = store.trading.getTrading('createOrder');

  const symbol = trading.instrument?.symbol as string;

  const shouldDisplayStopLoss =
    !!trading.side && trading.isStopLoss && store.app.isBetaDesignEnabled();

  const createOrder = (price: string) => {
    slLineAdapter = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setText(`${translate('COMMON.LABELS.STOP_LOSS')} ${trading.loss}`)
      .setBodyTextColor(theme.palette.white.main)
      .setBodyBorderColor(theme.palette.black.light)
      .setBodyBackgroundColor(theme.palette.black.light)
      .setLineColor(theme.palette.black.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .setCancelButtonBorderColor(theme.palette.black.light)
      .setCancelButtonBackgroundColor(theme.palette.black.light)
      .setLineLength(slLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity('')
      .onMoving(function () {
        this.setPrice(this.getPrice());
        trading.updatePriceFromChart(
          this.getPrice().toString(),
          'chartStopLoss'
        );
      })
      .onMove(function () {
        this.setPrice(this.getPrice());
        trading.updatePriceFromChart(
          this.getPrice().toString(),
          'chartStopLoss'
        );
      })
      .onCancel(function () {
        trading.runInAction(() => {
          trading.isStopLoss = false;
        });
      })
      .setPrice(Number(price));
  };

  const clearTP = useCallback(() => {
    slLineAdapter?.remove();
    slLineAdapter = undefined;
  }, []);

  // initialize SL order
  useEffect(() => {
    if (shouldDisplayStopLoss) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          createOrder(trading.price);
        }
      });
    }
    return () => clearTP();
  }, [symbol, shouldDisplayStopLoss, idToken]);

  // update stop loss
  useEffect(() => {
    if (shouldDisplayStopLoss) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady && slLineAdapter) {
          slLineAdapter.setPrice(
            trading.SLEditMode === 'Chart'
              ? Number(trading.chartStopLoss)
              : Number(trading.stopLoss)
          );
          slLineAdapter.setText(
            `${translate('COMMON.LABELS.STOP_LOSS')} ${trading.loss}`
          );
        }
      });
    }
  }, [
    shouldDisplayStopLoss,
    trading.stopLoss,
    trading.chartStopLoss,
    trading.loss,
    trading.SLEditMode,
  ]);

  useEffect(() => {
    if (!shouldDisplayStopLoss) {
      clearTP();
    }
  }, [shouldDisplayStopLoss]);
};
