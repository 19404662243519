import React from 'react';
import { useI18next } from '@trader/services';
import * as Styled from './styled';

interface IPurchaseType {
  value: string;
  variant?: 'shrink' | 'fit-content';
}

export const PurchaseType: React.FC<IPurchaseType> = ({
  value,
  variant = 'shrink',
}) => {
  const { translate } = useI18next();
  return (
    <Styled.Type $isBuy={value === 'Buy'} $variant={variant}>
      {translate(`COMMON.LABELS.${(value as string).toUpperCase()}`)}
    </Styled.Type>
  );
};
