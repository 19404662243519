import React from 'react';
import { observer } from 'mobx-react-lite';

import { defaultIcon } from '@trader/constants';
import { TAlertEntity, TModalStore, useMst } from '@trader/store';
import { ModalTitle, Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import { formatByPipSize } from '@trader/utils';

import * as Styled from '../styled';

export const DeletePriceAlert: React.FC = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const modal: TModalStore = store.ui.modal;
  const alertId = modal.options.get('alertId');
  const alert = store.entities.alerts.get<TAlertEntity>(alertId);
  const instrument = alert?.conditions[0].instrument;

  const handleCancelOrder = async () => {
    await store.entities.alerts.deleteAlertAsync.run(alertId);
  };

  if (!instrument) {
    return null;
  }

  return (
    <Styled.Root>
      <ModalTitle
        bottomMargin='0'
        title={translate('COMMON.LABELS.DELETE_ALERT')}
      />
      <Styled.InstrumentInfo>
        <Wrapper alignItems='center'>
          <img
            src={instrument.iconUrl || defaultIcon}
            alt='instrument icon'
            width={30}
            height={30}
          />
          <Wrapper flexDirection='column' marginLeft='8px'>
            <Styled.Symbol>{instrument.symbol}</Styled.Symbol>
            <Typography>{instrument.description}</Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper alignItems='flex-end' flexDirection='column'>
          <Styled.Label>
            {translate('COMMON.LABELS.CURRENT_PRICE')}
          </Styled.Label>
          <Typography>
            {instrument.currencySymbol}
            {instrument
              ? formatByPipSize(instrument.currentPrice, instrument.pipSize)
              : '--'}
          </Typography>
        </Wrapper>
      </Styled.InstrumentInfo>
      <Styled.ConfirmButton
        disabled={store.entities.alerts.deleteAlertAsync.inProgress}
        loading={store.entities.alerts.deleteAlertAsync.inProgress}
        onClick={handleCancelOrder}
      >
        {translate('COMMON.LABELS.DELETE_ALERT')}
      </Styled.ConfirmButton>
    </Styled.Root>
  );
});
