import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Container = styled(Wrapper)<{
  $top: number | null;
  $left: number | null;
}>`
  ${({ theme, $top, $left }) => css`
    width: 261px;
    height: 645px;
    background: rgba(46, 48, 63, 0.7);
    position: absolute;
    right: 215px;
    bottom: 67px;
    z-index: ${theme.zIndex.modal};
    cursor: move;
    border: 1px solid ${theme.palette.black.main};
    border-radius: 5px;
    flex-direction: column;

    ${conditionApply(
      [$top && $left],
      css`
        left: ${$left}px;
        top: ${$top}px;
      `
    )}
  `};
`;

export const Content = styled(Wrapper)`
  ${() => css`
    overflow: auto;
    flex-direction: column;
  `};
`;

export const Header = styled(Wrapper)`
  ${() => css`
    padding: 7px 13px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `};
`;

export const Close = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    width: 25px;
    height: 25px;

    svg {
      path {
        stroke-width: 1.5px;
      }
    }
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.white.main};
  `};
`;

export const Value = styled(Wrapper)<{ $background?: string }>`
  ${({ theme, $background }) => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 2px;

    > span {
      font-size: ${theme.typography.tiny.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      width: 50%;
      margin: 1px;
      text-transform: capitalize;
      padding: 5px 13px;
      color: white;
    }

    > span:first-child {
      background: ${$background || 'rgba(67, 70, 89, 1)'};
    }

    > span:last-child {
      background: rgba(56, 59, 75, 1);
    }
  `};
`;
