import styled, { css } from 'styled-components';

import { Wrapper, Button } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  `}
`;

export const SwitchAccountButton = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    border-radius: 5px;
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    padding: 8px 12px;
    margin-top: 12px;
  `}
`;
