import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;

export const Footer = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    padding: 0 20px;

    > p {
      border-left: 1px solid ${theme.palette.background.paper};
      font-size: ${theme.typography.medium.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      margin: 0;
      display: flex;
      align-items: center;
      padding-left: 10px;

      > span {
        font-weight: ${theme.typography.fontWeightBold};
        margin-left: 8px;
      }
    }

    ${theme.breakpoints.down('sm')} {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 13px 10px;

      > p {
        width: 100%;
        padding-left: 0;
        justify-content: space-between;
        display: flex;
        border-left: none;
      }
    }
  `}
`;

export const Item = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    overflow: auto;
  `}
`;
