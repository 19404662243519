export enum EIndicators {
  MACD = 'MACD',
  VWAP_DIFF = 'VWAP_DIFF',
  VWAP_PRESSER = 'VWAP_PRESSER',
}

export enum EBandType {
  lower,
  upper,
}

export enum EMultiplier {
  base,
  us,
}

export enum EOrderSide {
  Buy,
  Sell,
}

export enum EStripName {
  Strip1Low,
  Strip2Low,
  Strip3Low,
  Strip4Low,
  Strip5Low,
  Strip0HalfLow,
  Strip025Low,
  Strip075Low,
  Strip1HalfLow,
  Strip2HalfLow,
  Strip3HalfLow,
  Strip4HalfLow,
  Strip1High,
  Strip2High,
  Strip3High,
  Strip4High,
  Strip5High,
  Strip0HalfHigh,
  Strip025High,
  Strip075High,
  Strip1HalfHigh,
  Strip2HalfHigh,
  Strip3HalfHigh,
  Strip4HalfHigh,
  RoundedVwap,
}

export interface IBackTestingSummary {
  avgProfit: number;
  avgTradeTime: string;
  cognitoId: string;
  maxProfit: number;
  isPartially?: boolean;
  name: 'StrategyFinished';
  numberOfTrades: number;
  strategyId: number;
  totalProfit: number;
}

export enum EPositionClosedBy {
  TakeProfit,
  StopLoss,
  '-',
}

export enum EOrderCreatedBy {
  Average,
  '-',
}

export interface IBackTestingPosition {
  bandId: number;
  closePrice: number;
  name?: 'PositionDeleted';
  openPrice: number;
  openTime: string;
  closeTime: string;
  profit: number;
  side: EOrderSide;
  id: number;
  closedBy: EPositionClosedBy;
  orderCreatedBy: EOrderCreatedBy;
}

export interface IMultiplierData {
  totalProfit: number;
  multiplier: number;
  bandId: EStripName;
  profitDays: number;
  lossDays: number;
  daysWoHit: number;
  percentFinished: number;
  returnedToVwapDays: number;
  notReturnedToVwapDays: number;
  returnedToVwapWithLoss: number;
  returnedToLowerBandWithLoss: number;
  maxConsecutiveWinDays: number;
  maxConsecutiveLossDays: number;
  maxConsecutiveSlDays: number;
  maxProfit: number;
  positions: Array<IBackTestingPosition>;
}

export interface IBackTestingMultiplierFinished {
  bandMultipliersData: Array<IMultiplierData>;
  cognitoId: string;
  strategyId: string;
  name: 'StrategyMultiplierFinished';
}
