import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Typography } from '@trader/components';

import * as Styled from './styled';

interface IHidingInfo {
  title: string;
  children: React.ReactNode;
  defaultState?: boolean;
}

export const HidingInfo: React.FC<IHidingInfo> = observer(
  ({ title, children, defaultState = true }) => {
    const [isVisible, setIsVisible] = useState(defaultState);

    useEffect(() => {
      return () => {
        setIsVisible(true);
      };
    }, []);

    const toggleVisibility = useCallback(() => {
      setIsVisible(prev => !prev);
    }, []);

    return (
      <Styled.Root>
        <Styled.Header>
          <Typography
            variant='small'
            fontWeight='bold'
            textTransform='uppercase'
          >
            {title}
          </Typography>
          <Styled.Toggle
            size='small'
            isSingleIcon
            variant='text'
            onClick={toggleVisibility}
            iconType={isVisible ? 'arrowLeft' : 'arrowRight'}
          />
        </Styled.Header>
        {isVisible && children}
      </Styled.Root>
    );
  }
);
