import { TSupportedLocales } from './types';

export const thousand = 1000;
export const ten = 10;
export const sixty = 60;
export const millisecondsInOneMinute = 60000;
export const localeKey: Record<TSupportedLocales, string> = {
  en: 'en-EN',
  it: 'it-IT',
  es: 'es-ES',
  de: 'de-DE',
  pl: 'pl-PL',
  nl: 'nl-NL',
  sk: 'sk-SK',
};
