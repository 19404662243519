import React, { useState } from 'react';

import { IInstrumentBE } from '@trader/api';
import { useInstrument } from '@trader/hooks';
import { formatByPipSize, getInstrumentDifference } from '@trader/utils';
import { defaultIcon } from '@trader/constants';
import { ActionButtons } from '@trader/components';

import * as Styled from './styled';
import { observer } from 'mobx-react-lite';

interface IState {
  ask: number;
  bid: number;
  currentPrice: number;
}

interface IInstrument {
  instrument: IInstrumentBE;
}

export const Instrument: React.FC<IInstrument> = observer(({ instrument }) => {
  const [realTimePrice, setRealTimePrice] = useState<IState>({
    ask: instrument.ask,
    bid: instrument.bid,
    currentPrice: instrument.ask,
  });

  const { isGrow, openCloseDifferenceInPercent } = getInstrumentDifference(
    realTimePrice.ask || instrument.ask,
    instrument.close,
    instrument.pipSize
  );

  const updateRealTimePrice = (askValue: number, bidValue: number) => {
    const ask = formatByPipSize(askValue, instrument.pipSize);
    const bid = formatByPipSize(bidValue, instrument.pipSize);

    setRealTimePrice({
      ask: Number(ask),
      bid: Number(bid),
      currentPrice: Number(ask),
    });
  };

  useInstrument(
    instrument.symbol,
    instrument.spreadDiff,
    instrument.spreadDiffBalance,
    instrument.pipSize,
    updateRealTimePrice
  );

  return (
    <Styled.Root>
      <img
        src={instrument?.iconUrl || defaultIcon}
        alt='instrument icon'
        width={36}
        height={36}
      />
      <Styled.Symbol>{instrument.displayName}</Styled.Symbol>
      <Styled.Main>
        <Styled.CurrentPrice>{realTimePrice.currentPrice}</Styled.CurrentPrice>
        <Styled.Difference $isAboveZero={isGrow}>
          {openCloseDifferenceInPercent}%
        </Styled.Difference>
      </Styled.Main>
      <Styled.Buttons>
        <ActionButtons
          layout={'single'}
          buttonVariant='text'
          symbol={instrument.symbol}
          ask={realTimePrice.ask}
          bid={realTimePrice.bid}
          pipSize={instrument.pipSize}
          actionsStyles={{
            marginLeft: 0,
            width: '100%',
          }}
          actionButtonStyles={{
            marginLeft: 0,
            width: '100px',
            height: '45px',
          }}
        />
      </Styled.Buttons>
    </Styled.Root>
  );
});
