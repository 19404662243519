import React from 'react';
import styled, { css } from 'styled-components';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

interface ICustomTooltipProps extends TooltipProps {
  $maxWidth?: number;
}
const defaultMaxWidth = 300;
export const Tooltip = styled<React.FC<ICustomTooltipProps>>(
  ({ children, title, className, ...props }) => (
    <MuiTooltip
      title={title}
      classes={{ popper: className, arrow: `${className}-arrow` }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
)`
  ${({ theme, $maxWidth }) => css`
    & .${tooltipClasses.tooltip} {
      font-size: ${theme.typography.tiny.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.common.black};
      max-width: ${$maxWidth ? `${$maxWidth}px` : `${defaultMaxWidth}px`};
    }

    & .${tooltipClasses.arrow}::before {
      background-color: white;
    }

    height: max-content;
  `}
`;
