import { useEffect } from 'react';

import { TPositionMetricEntity, useMst } from '@trader/store';
import { calcPositionPl, instrumentHelpers } from '@trader/utils';
import { IInstrumentBE } from '@trader/api';

export const useFilteredPositions = () => {
  const store = useMst();
  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const tradeablePositions: TPositionMetricEntity[] = positions.filter(
    p =>
      !instrumentHelpers.isInstrumentTradingUnavailable(
        p.holidays as unknown as IInstrumentBE['holidays'],
        p.sessions
      ).isUnavailable
  );

  /**
   * Load positions trading sessions to exclude
   * the not tradeable positions from close all list.
   */
  useEffect(() => {
    store.entities.positionsMetrics.getPositionTradingSessionsAsync.run(
      positions.filter(p => p.sessions.length === 1)
    );
  }, []);

  const getFilteredPositions = () => {
    const profitable: TPositionMetricEntity[] = [];
    const losing: TPositionMetricEntity[] = [];
    const buy: TPositionMetricEntity[] = [];
    const sell: TPositionMetricEntity[] = [];

    for (const position of tradeablePositions) {
      const pl = calcPositionPl(position);

      if (pl > 0) {
        profitable.push(position);
      }

      if (pl < 0) {
        losing.push(position);
      }

      if (position.side === 'Buy') {
        buy.push(position);
      }

      if (position.side === 'Sell') {
        sell.push(position);
      }
    }

    return {
      allPositions: tradeablePositions,
      profitablePositions: profitable,
      losingPositions: losing,
      buyPositions: buy,
      sellPositions: sell,
    };
  };

  return getFilteredPositions();
};
