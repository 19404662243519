/* eslint-disable no-magic-numbers */
import React, { useEffect } from 'react';
import { resetChartData } from '@trader/containers';
import { invertedResolutionObject } from '../../datafeed';
import {
  IChartingLibraryWidget,
  ResolutionString,
} from '../../charting_library';
import { emptyFn, strategyName } from '@trader/constants';
import { useMst } from '@trader/store';

const intervalResetTime = 60000 * 30; // 30 min

// need to reset historical data each 30 min to do not have missing vwap
export const useResetHistoricalBarsInterval = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();

  const symbol = store.pages.muliBands.symbol;
  const recalculateIndicatorTrigger =
    store.pages.muliBands.recalculateIndicatorTrigger;

  useEffect(() => {
    const interval = setInterval(() => {
      if (widget?.current && isMuliBands) {
        widget?.current?.onChartReady(() => {
          widget?.current?.setSymbol(
            resetChartData,
            invertedResolutionObject.Minute2 as ResolutionString,
            emptyFn
          );
          widget?.current?.setSymbol(
            symbol,
            invertedResolutionObject.Minute2 as ResolutionString,
            emptyFn
          );
        });
      }
    }, intervalResetTime);

    return () => {
      store.pages.muliBands.runInAction(() => {
        store.pages.muliBands.recalculateIndicatorTrigger = 0;
      });
      clearInterval(interval);
    };
  }, [widget?.current, symbol, store.auth.tokens.idToken]);

  useEffect(() => {
    // trick for synchronization data with BE
    if (recalculateIndicatorTrigger) {
      widget?.current?.onChartReady(() => {
        widget.current
          ?.activeChart()
          .getAllStudies()
          .forEach(study => {
            if (study.name === strategyName) {
              widget.current?.activeChart().setPriceToBarRatioLocked(true);
              widget.current
                ?.activeChart()
                .getStudyById(study.id)
                .setVisible(false);
              widget.current
                ?.activeChart()
                .getStudyById(study.id)
                .setVisible(true);
              widget.current?.activeChart().setPriceToBarRatioLocked(false);
            }
          });
      });
    }
  }, [recalculateIndicatorTrigger]);
};
