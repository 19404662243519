import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

import { Typography, Icon as CompIcon } from '@trader/components';

export const Root = styled(Box)<{
  $backgroundColor: string;
  $iconColor: string;
}>`
  ${({ $backgroundColor, $iconColor }) => css`
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: ${$backgroundColor};

    svg {
      path {
        stroke: ${$iconColor};
      }
    }
  `};
`;

export const Text = styled(Typography)<{ $color: string }>`
  ${({ $color }) => css`
    margin-left: 4px;
    font-weight: 600;
    text-wrap: wrap;
    color: ${$color};
  `};
`;

export const Icon = styled(CompIcon)<{ $width?: string; $height?: string }>`
  ${({ $width, $height }) => css`
    width: ${$width};
    height: ${$height};
  `};
`;
