import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Instruments, Subcategories } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import {
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';

import * as Styled from './styled';

export const InstrumentsTable = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const category = store.pages.trading.selectedCategory;
  const subCategory = store.pages.trading.selectedSubCategory;

  const instruments = store.pages.trading.getInstrumentsByCategory(
    category?.name || '',
    subCategory
  );

  const subCategoryName = useMemo(
    () =>
      category?.subCategories.find(s => s.id.toString() === subCategory)?.name,
    [subCategory, category]
  );

  return (
    <Styled.Root>
      <Styled.Header>
        <Styled.TotalCount>
          <Styled.Name>{category?.name} -</Styled.Name>
          <Styled.Count>
            {subCategoryName?.toUpperCase()}{' '}
            {store.pages.trading.instrumentsAmount}&nbsp;
            {instruments.length === 1
              ? translate('COMMON.LABELS.RESULT')
              : translate('COMMON.LABELS.RESULTS')}
          </Styled.Count>
        </Styled.TotalCount>
        <Subcategories />
        <Styled.Title>
          <Styled.Instrument variant='tiny'>
            {translate('COMMON.LABELS.INSTRUMENT')}
          </Styled.Instrument>
          <Styled.Sell variant='tiny'>
            {translate('COMMON.LABELS.SELL')}
          </Styled.Sell>
          <Styled.Buy variant='tiny'>
            {translate('COMMON.LABELS.BUY')}
          </Styled.Buy>
          {shouldDisplaySpreadColumnOnInstrumentList && (
            <Styled.Spread variant='tiny'>
              {translate('COMMON.LABELS.SPREAD')}
            </Styled.Spread>
          )}
          {shouldDisplayChangeColumnOnInstrumentList && (
            <Styled.Spread variant='tiny'>
              {translate('COMMON.LABELS.CHANGE')}
            </Styled.Spread>
          )}
          <Styled.HTrend variant='tiny'>
            {translate('COMMON.LABELS.24HTREND')}
          </Styled.HTrend>
          <Styled.Action variant='tiny' />
        </Styled.Title>
      </Styled.Header>
      <Instruments />
    </Styled.Root>
  );
});
