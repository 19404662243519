const diffDivider = 2;

export const getSpreadDifference = (
  spreadDiff: number,
  askValue: number,
  bidValue: number,
  spreadDiffBalance: number,
  pipSize: number
) => {
  const bid =
    bidValue + pipSize * (spreadDiff / diffDivider + spreadDiffBalance);

  const ask =
    askValue -
    pipSize *
      ((spreadDiff + (spreadDiff % diffDivider === 0 ? 0 : 1)) / diffDivider -
        spreadDiffBalance);

  return {
    ask,
    bid,
  };
};
