import { useEffect } from 'react';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useHub } from './core/useHub';
import { useMst } from '@trader/store';

enum ESignalType {
  EdgeX,
  EdgeZone,
}

interface IMessage {
  signalType: ESignalType;
  isEnabled: boolean;
}

export const useSignalPurchase = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken;

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/inboxes',
    hub: EConnectionHub.Inboxes,
    subscriber: EConnectionHubSubscriber.SignalPurchase,
    onMessage: message => {
      if (message.signalType === ESignalType.EdgeX) {
        store.user.runInAction(() => {
          store.user.settings.isCrmEdgeX = message.isEnabled;
          store.user.settings.isEdgeXDisplayedByUser = message.isEnabled;
          store.user.settings.isEdgeX = message.isEnabled;
        });
      }
      if (message.signalType === ESignalType.EdgeZone) {
        store.user.runInAction(() => {
          store.user.settings.isCrmXStrategy = message.isEnabled;
          store.user.settings.isXStrategyDisplayedByUser = message.isEnabled;
          store.user.settings.isXStrategy = message.isEnabled;
        });
      }
    },
    invokedName: 'OnSignalPurchase',
  });

  useEffect(() => {
    if (store.auth.isAuth) {
      subscribe();
    }
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [store.auth.isAuth, idToken]);
};
