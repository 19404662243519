// Demo only to test demo mt5;
export enum EBrand {
  Markets77 = 'Markets77',
  FrontBroker = 'FrontBroker',
  Demo = 'Demo',
  ChallengesTrades = 'ChallengesTrades',
  TradeGlobal = 'TradeGlobal',
  Finexo = 'Finexo',
  HeroMarkets = 'HeroMarkets',
  PipsTrades = 'PipsTrades',
  TrdFx = 'TrdFx',
  TradeItaly = 'TradeItaly',
}
