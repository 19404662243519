import { ThemeOptions } from '@mui/material';
import { SelectOverride, TooltipOverride } from '../overridedComponents';
import { breakpoints, lightPallet, typography } from '../constants';
import { customSpacing, IThemeOptions } from './index';

export const lightTheme = (options?: IThemeOptions): ThemeOptions => {
  return {
    components: {
      MuiTooltip: TooltipOverride,
      MuiSelect: SelectOverride(options),
    },
    typography,
    breakpoints,
    palette: options?.palette ? options.palette : lightPallet,
    spacing: customSpacing,
  };
};
