import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Controller, Form, Icon, Typography } from '@trader/components';
import { useChangePasswordSchema } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import * as Styled from './styled';

type TInitialState = {
  password: string;
  proposedPassword: string;
  matchProposedPassword: string;
};

export const ExpiredPassword: React.FC = observer(() => {
  const { translate } = useI18next();
  const { expiredPasswordSchema, validationSchema } = useChangePasswordSchema();

  const store = useMst();
  const tempData = store.auth.tempData;

  const { current: currentPassword } = useRef(tempData);

  useEffect(() => {
    store.auth.runInAction(() => {
      store.auth.tempData = '';
    });
  }, []);

  const handleSubmitButtonClick = async (data: TInitialState) => {
    const body = {
      previousPassword: currentPassword || data.password,
      proposedPassword: data.proposedPassword,
    };
    await store.auth.changePasswordAsync.run(body);

    store.user.runInAction(() => {
      store.user.profile.isPasswordExpired = false;
    });
    store.ui.modal.close();
  };

  return (
    <Styled.Root>
      <Icon iconType='warning' />
      <Typography variant='heading' fontWeight='bold'>
        {translate('COMMON.LABELS.PASSWORD_EXPIRED_TITLE')}
      </Typography>
      <Typography variant='medium' marginBottom='24px'>
        {translate('COMMON.LABELS.PASSWORD_EXPIRED_DESCRIPTION')}
      </Typography>
      <Form<TInitialState>
        onSubmit={(reset, data) => handleSubmitButtonClick(data)}
        defaultValues={{
          password: '',
          proposedPassword: '',
          matchProposedPassword: '',
        }}
        validationSchema={
          !currentPassword ? expiredPasswordSchema : validationSchema
        }
        mode='all'
      >
        {({ formState }) => (
          <>
            {!currentPassword && (
              <Controller
                name='password'
                type='passwordField'
                keyboardType='password'
                fullWidth
                label={translate('COMMON.LABELS.CURRENT_PASSWORD')}
              />
            )}
            <Controller
              name='proposedPassword'
              type='passwordField'
              keyboardType='password'
              fullWidth
              label={translate('COMMON.LABELS.NEW_PASSWORD')}
            />
            <Controller
              name='matchProposedPassword'
              type='passwordField'
              keyboardType='password'
              fullWidth
              label={translate('COMMON.LABELS.RETYPE_PASSWORD')}
            />
            <Styled.Submit
              loading={store.auth.changePasswordAsync.inProgress}
              disabled={
                !formState.isDirty ||
                store.auth.changePasswordAsync.inProgress ||
                !formState.isValid
              }
              fullWidth
              variant='contained'
              type='submit'
            >
              {translate('COMMON.LABELS.PROCEED')}
            </Styled.Submit>
            {!currentPassword && (
              <Styled.SignOut
                fullWidth
                variant='contained'
                onClick={() => {
                  store.auth.logOut();
                }}
              >
                {translate('COMMON.LABELS.SIGN_OUT')}
              </Styled.SignOut>
            )}
          </>
        )}
      </Form>
    </Styled.Root>
  );
});
