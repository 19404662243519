import { useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { usePurchase, useMenuListItems } from '@trader/hooks';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Icon, SearchInstrument } from '@trader/components';

import { TradingAccounts } from '../../../../components/tradingAccounts';

import * as Styled from './styled';

interface IMobileMenu {
  isOpen: boolean;
  onToggle: () => void;
  side?: 'right' | 'left';
}

export const MobileMenu: FC<IMobileMenu> = observer(
  ({ isOpen, onToggle, side = 'right' }) => {
    const store = useMst();
    const { translate } = useI18next();
    const purchase = usePurchase();

    const isDemoAccount = store.user.isDemoAccInUse();

    return (
      <Styled.Drawer anchor={side} open={isOpen} onClick={onToggle}>
        <Styled.Container role='presentation'>
          {side === 'right' ? (
            <ListRightMenu onToggle={onToggle} />
          ) : (
            <ListLeftMenu onToggle={onToggle} />
          )}
          {side === 'right' && !isDemoAccount && (
            <Styled.AddFunds
              disabled={purchase.isDisabled}
              onClick={purchase.action}
              variant='text'
            >
              {translate(purchase.title)}
            </Styled.AddFunds>
          )}
        </Styled.Container>
      </Styled.Drawer>
    );
  }
);

interface IListMenu {
  onToggle: () => void;
}

const ListRightMenu: FC<IListMenu> = ({ onToggle }) => {
  const { getMenu } = useMenuListItems();

  return (
    <Styled.RootList>
      <TradingAccounts onClose={onToggle} />
      <Styled.List>
        {getMenu().map(item => (
          <Styled.Item key={item.id}>
            <Styled.ItemButton
              disabled={item.disabled}
              onClick={_e => item?.action()}
            >
              {item.icon && <Icon iconType={item.icon} />}
              <Styled.Children>{item.children}</Styled.Children>
            </Styled.ItemButton>
          </Styled.Item>
        ))}
      </Styled.List>
    </Styled.RootList>
  );
};

const ListLeftMenu: FC<IListMenu> = observer(({ onToggle }) => {
  const store = useMst();
  const { getLeftMenu } = useMenuListItems();

  useEffect(() => {
    store.entities.categories.getCategoriesAsync.run();
  }, []);

  return (
    <Styled.RootList className='list-left-menu'>
      <SearchInstrument onClose={onToggle} />
      <Styled.List className='categories'>
        {getLeftMenu().map(item => (
          <Styled.Item key={item.id}>
            <Styled.ItemButton
              disabled={item.disabled}
              onClick={_e => item.action(item.value)}
            >
              {item.icon && <Styled.CategoryIcon iconType={item.icon} />}
              <Styled.Children>{item.children}</Styled.Children>
              <Styled.IconRight iconType='arrowRight' />
            </Styled.ItemButton>
          </Styled.Item>
        ))}
      </Styled.List>
    </Styled.RootList>
  );
});
