/* eslint-disable no-magic-numbers */

import { buildUtcDateBasedOnTime, getUTCDate } from './dateHelpers';
import { TIntervalType } from './types';

// Function to calculate the start and end of a quarter.
const calculateQuarterInterval = (quarterOffset = 0) => {
  const numberOfMonthInQuarter = 3;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  let quarterStartMonth: number | null = null;
  let quarterEndMonth: number | null = null;

  const targetYear =
    currentMonth - quarterOffset < 0 ? currentYear - 1 : currentYear;
  const quarterIndex = Math.floor(
    (currentMonth - quarterOffset) / numberOfMonthInQuarter
  );

  switch (quarterIndex) {
    case 0:
      quarterStartMonth = 0;
      quarterEndMonth = 2;
      break;
    case 1:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 3;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 5;
      break;
    case 2:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 6;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 8;
      break;
    // eslint-disable-next-line no-magic-numbers
    case 3:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 9;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 11;
      break;
    default:
      break;
  }

  const from = new Date(targetYear, quarterStartMonth as number, 1);
  const to = new Date(
    targetYear,
    (quarterEndMonth as number) + 1,
    0,
    lastHour,
    lastMinute,
    lastSecond
  );

  return { from, to };
};

// Function to check if a date is within a specific time range.
const isDateInTimeInterval = (date: Date, from: string, to: string) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const fromHours = +from.split(':')[0];
  const fromMinutes = +from.split(':')[1];
  const fromTime = new Date(year, month, day, fromHours, fromMinutes);

  const toHours = +to.split(':')[0];
  const toMinutes = +to.split(':')[1];
  const toTime = new Date(year, month, day, toHours, toMinutes);

  return date >= fromTime && date <= toTime;
};

// Function to calculate date intervals based on a given type.
export const calculateDateInterval = (intervalType: TIntervalType) => {
  const numberOfMonthInQuarter = 3;
  const daysInLastMonth = 31;
  const lastMonthIndex = 11;
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  const currentDate = new Date();

  let from: null | Date = null;
  let to: null | Date = null;

  switch (intervalType) {
    case 'prevDay': {
      const date = new Date().getUTCDate();

      const prevDay = new Date().setUTCDate(date - 1);

      from = new Date(new Date(prevDay).setUTCHours(0, 0, 0, 0));
      to = new Date(new Date().setUTCHours(0, 0, 0, 0));
      break;
    }
    case 'thisMonth':
      from = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      to = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'pastMonth':
      from = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      to = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'thisQuarter':
      from = calculateQuarterInterval().from;
      to = calculateQuarterInterval().to;
      break;
    case 'pastQuarter':
      from = calculateQuarterInterval(numberOfMonthInQuarter).from;
      to = calculateQuarterInterval(numberOfMonthInQuarter).to;
      break;
    case 'thisYear':
      from = new Date(currentDate.getFullYear(), 0, 1);
      to = new Date(
        currentDate.getFullYear(),
        lastMonthIndex,
        daysInLastMonth,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'pastYear':
      from = new Date(currentDate.getFullYear() - 1, 0, 1);
      to = new Date(
        currentDate.getFullYear() - 1,
        lastMonthIndex,
        daysInLastMonth,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    default:
      throw new Error('Unsupported interval');
  }

  return { from, to };
};

// Function to calculate the number of days in an interval.
export const calculateDaysInInterval = (interval: { from: Date; to: Date }) => {
  const { from, to } = interval;

  // Validate that `from` is before or equal to `to`
  if (from > to) {
    throw new Error('`from` date must be earlier than or equal to `to` date.');
  }

  // Calculate the difference in milliseconds
  const diffInMs = to.getTime() - from.getTime();

  // Convert milliseconds to days
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
};

// Function to analyze a range of hours for overlap with a date.
export const analyzeHourRanges = (
  hoursRange: { from: string; to: string }[],
  date?: Date
) => {
  if (!hoursRange.length) {
    return {
      hasMatchedWithCurrentDate: true,
      matchedRange: null,
      nextCurrentDateMatch: null,
    };
  }

  const currentDate = date || getUTCDate();

  let hasMatchedWithCurrentDate = false;
  let nextCurrentDateMatch: Date | null = null;
  let matchedRange: { from: string; to: string } | null = null;

  for (const hours of hoursRange) {
    if (isDateInTimeInterval(currentDate, hours.from, hours.to)) {
      hasMatchedWithCurrentDate = true;
      matchedRange = { from: hours.from, to: hours.to };
      break;
    } else {
      const fromDate = buildUtcDateBasedOnTime(hours.from);

      if (fromDate > currentDate) {
        if (nextCurrentDateMatch === null) {
          nextCurrentDateMatch = fromDate;
        } else {
          const isFromDateEarlier = fromDate < nextCurrentDateMatch;
          if (isFromDateEarlier) {
            nextCurrentDateMatch = fromDate;
          }
        }
      }
    }
  }

  return {
    hasMatchedWithCurrentDate,
    matchedRange,
    nextCurrentDateMatch,
  };
};

// Function to calculate the time difference between two times.
export const calculateTimeDifference = (fromTime: Date, toTime: Date) => {
  const diffInMilliseconds = toTime.getTime() - fromTime.getTime();
  // eslint-disable-next-line no-magic-numbers
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  // eslint-disable-next-line no-magic-numbers
  const hours = Math.floor(diffInMinutes / 60);
  // eslint-disable-next-line no-magic-numbers
  const minutes = diffInMinutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}h:${formattedMinutes}m`;
};
