import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 408px;
    height: 350px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
      height: 320px;
      justify-content: flex-start;
    }
  `}
`;

export const Position = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  `}
`;

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${modalHorizontalPadding}px;
    justify-content: space-around;
    margin: 15px 0 25px 0;
  `}
`;

export const Main = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin: 10px 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    min-width: max-content;
    color: ${theme.palette.gray.dark};
  `}
`;
