import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BottomBar, Modals, TopBar } from '@trader/containers';
import { Typography, Icon } from '@trader/components';
import { useI18next } from '@trader/services';
import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';

import { UserDetailsLayoutLeftBar } from './leftBar';
import * as Styled from './styled';

export const UserDetailsLayout: React.FC = () => {
  const location = useLocation();
  const { navigateTo } = useNavigation();
  const { translate } = useI18next();

  const title = {
    [NAVIGATE_TO.main.myAccount]: translate('COMMON.LABELS.MY_ACCOUNT'),
    [NAVIGATE_TO.main.myHistory]: translate('COMMON.LABELS.MY_HISTORY'),
    [NAVIGATE_TO.main.settings]: translate('COMMON.LABELS.SETTINGS'),
    [NAVIGATE_TO.main.copier]: translate('COPIER.TITLE'),
    [NAVIGATE_TO.main.purchaseChallenges]: translate(
      'PURCHASE_CHALLENGES.TITLE'
    ),
  };

  return (
    <>
      <Styled.Root>
        <TopBar />
        <Styled.Main>
          <UserDetailsLayoutLeftBar />
          <Styled.Content>
            <Styled.Title>
              <Styled.GoHomeButton
                onClick={() => navigateTo(NAVIGATE_TO.main.dashboard)}
              >
                <Icon iconType='arrowLeft' />
              </Styled.GoHomeButton>
              <Typography variant='heading'>
                {title[location.pathname]}
              </Typography>
            </Styled.Title>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <Outlet />
            </div>
          </Styled.Content>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </>
  );
};
