import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';

import { IBackTestingInitialValues } from './index';

export const SessionRange = observer(() => {
  const { translate } = useI18next();

  const { control, getValues } = useFormContext<IBackTestingInitialValues>();

  const [isSessionRange, setIsSessionRange] = useState(
    getValues().isSessionRange
  );

  return (
    <>
      <Controller
        type='switcherField'
        label={translate('MULI_BANDS.SESSION_RANGE')}
        name='isSessionRange'
        control={control}
        onCustomChange={event => setIsSessionRange(event.target.checked)}
      />
      {isSessionRange && (
        <Stack direction='row' spacing='10px' padding='4px 0'>
          <Controller
            type='timePickerField'
            label={translate('MULI_BANDS.SESSION_FROM')}
            name='sessionFrom'
            control={control}
            shouldHideControlInfo
          />
          <Controller
            type='timePickerField'
            label={translate('MULI_BANDS.SESSION_TO')}
            name='sessionTo'
            control={control}
            shouldHideControlInfo
          />
        </Stack>
      )}
    </>
  );
});
