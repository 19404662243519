export const LOCAL_STORAGE_KEYS = {
  tokens: {
    access: 'access_token',
    refresh: 'refresh_token',
    idToken: 'id_token',
    expiresIn: 'expires_in_token',
    tokenType: 'token_type_token',
    cognitoId: 'cognito_id',
    sessionId: 'session_id',
    authorizedSessionTimestamp: 'authorized_session_timestamp',
    firebaseMessagingToken: 'firebase_messaging_token',
  },
  credentials: 'credentials',
  brand: 'brand',
  apiType: 'api_type',
  persist: 'persist_store',
  leftPageTimestamp: 'left_page_timestamp',
  language: 'language',
  theme: 'theme',
  errorBoundary: 'error-boundary',
  version: 'version',
  chartAdvanced: 'chart-advanced',
  tradingAccounts: 'trading-accounts',
  tradingViewSettings: 'tradingview.chartproperties.mainSeriesProperties',
  tradingViewTheme: 'tradingview.current_theme.name',
  // Temporary solution only for Indices category.
  category: 'category',
  isBetaDesignInitialized: 'isBetaDesignInitialized',
} as const;
