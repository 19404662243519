import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { BANDS, IIBandItem } from '@trader/constants';
import { EBandType } from '@trader/types';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    min-height: 355px;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.background.default};
  `};
`;

export const Box = styled(Wrapper)`
  ${() => css`
    flex-direction: column-reverse;
    align-items: center;
    overflow: scroll;
    position: relative;
    justify-content: center;
    flex-flow: wrap;
    width: 100%;
  `};
`;

export const Blur = styled(Wrapper)`
  ${() => css`
    background: rgba(88, 79, 79, 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  `};
`;

export const AbsoluteBandWrapper = styled(Wrapper)<{ $order: number }>`
  ${({ theme, $order }) => css`
    flex-direction: column;
    position: absolute;
    z-index: 2;
    align-items: center;
    justify-content: center;
    width: 80px;
    border-radius: 5px;
    background: ${theme.palette.white.main};

    > button:nth-child(3) {
      margin-top: 3px;
    }

    > button:nth-child(2) {
      margin-bottom: 0;
    }

    ${conditionApply(
      [$order > Object.keys(BANDS).length / 2 - 2],
      css`
        top: -63px;
        flex-direction: column-reverse;
      `
    )};
  `};
`;

export const BandWrapper = styled(Wrapper)<{ $order: number }>`
  ${({ $order }) => css`
    position: relative;
    order: ${$order};

    ${conditionApply(
      [$order === BANDS.RoundedVwap.order],
      css`
        width: 100%;
        padding-left: 16px;
      `
    )};
  `};
`;

export const Band = styled(Button)<{
  $type: IIBandItem['type'];
  $isBlur?: boolean;
  $isVwap?: boolean;
}>`
  ${({ $type, theme, $isBlur, $isVwap }) => css`
    width: 74px;
    height: 28px;
    margin: 3px;
    border-radius: 5px;
    justify-content: flex-start;
    padding: 10px 0 10px 10px;
    background: ${theme.palette.purchaseButtons.red};
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
    color: ${theme.palette.text.main};

    &:hover {
      background: ${theme.palette.purchaseButtons.red};
    }

    ${conditionApply(
      [$type === EBandType.lower],
      css`
        background: ${theme.palette.purchaseButtons.green};

        &:hover {
          background: ${theme.palette.purchaseButtons.green};
        }
      `
    )};

    ${conditionApply(
      [$isVwap],
      css`
        background: ${theme.palette.yellow[100]};
        color: ${theme.palette.black.main};

        &:hover {
          background: ${theme.palette.yellow[100]};
        }
      `
    )};

    ${conditionApply(
      [$isBlur],
      css`
        filter: blur(1px);
      `
    )};
  `};
`;

export const Purchase = styled(Button)`
  ${({ theme, disabled }) => css`
    width: 74px;
    height: 28px;
    margin-bottom: 3px;
    border-radius: 5px;
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    background: ${theme.palette.black[100]};
    color: ${theme.palette.text.main};

    > span {
      text-transform: uppercase;
    }

    &:hover {
      background: ${theme.palette.black[100]};
    }

    ${conditionApply(
      [disabled],
      css`
        background: ${theme.palette.black[100]} !important;
        cursor: not-allowed;

        &:hover {
          background: ${theme.palette.black[100]} !important;
        }
      `
    )};
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    width: 100%;
    padding: 11px 17px 8px 17px;
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-transform: uppercase;
  `};
`;
