import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 220px;
    padding-bottom: 12px;
  `}
`;
