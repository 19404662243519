import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, IOption } from '@trader/types';
import { Autocomplete, useTheme } from '@mui/material';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IBasicAutocomplete {
  onChange?: (event: IChangeFieldEvent<IOption | null>) => void;
  value?: IOption;
  isError?: boolean;
  fullWidth?: boolean;
  options?: IOption[];
  helperText?: string;
  shouldHideControlInfo?: boolean;
  name?: string;
  label?: string;
  disabled?: boolean;
}

export const BasicAutocomplete: React.FC<IBasicAutocomplete> = observer(
  ({
    value,
    label,
    onChange,
    options = [],
    name = 'nativeAutocomplete',
    disabled,
  }) => {
    const { translate } = useI18next();
    const theme = useTheme();
    const autocompleteRef = useRef<HTMLDivElement | null>(null);

    const handleChange = (
      _event: React.SyntheticEvent,
      option: IOption | null
    ) => {
      onChange &&
        onChange({
          target: {
            name,
            value: option,
          },
        });
    };

    return (
      <Styled.Root>
        <label>
          {label}
          <Styled.Icon $isDisabled={disabled} />
          <Autocomplete
            ref={autocompleteRef}
            value={value}
            onChange={handleChange}
            options={options}
            disabled={disabled}
            openOnFocus={true}
            noOptionsText={translate('COMMON.LABELS.NO_OPTIONS')}
            getOptionLabel={(option: IOption) => option.title}
            isOptionEqualToValue={(option, v) => option.value === v.value}
            renderInput={params => (
              <div ref={params.InputProps.ref}>
                <input
                  placeholder={translate('COMMON.LABELS.SELECT')}
                  type='text'
                  {...params.inputProps}
                />
              </div>
            )}
            slotProps={{
              popper: {
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: theme.typography.default.fontSize,
                    paddingLeft: 3,
                  },
                  '& .MuiAutocomplete-noOptions': {
                    fontSize: theme.typography.default.fontSize,
                  },
                },
              },
            }}
          />
        </label>
      </Styled.Root>
    );
  }
);
