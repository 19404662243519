import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Component = styled(Wrapper)`
  ${() => css`
    padding: 0 ${modalHorizontalPadding}px 10px ${modalHorizontalPadding}px;
    width: 100%;
  `}
`;

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.background.default};
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    > img {
      ${theme.breakpoints.down('sm')} {
        width: 20px;
        height: 20px;
      }
    }
  `}
`;

export const Container = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    margin-right: 5px;
    color: #999595;
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const NetPlValue = styled(Typography)<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: ${theme.typography.medium.fontSize};
    color: ${color};
  `};
`;
