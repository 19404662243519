import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    margin-bottom: 23px;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1.5rem;
    font-weight: ${theme.typography.fontWeightBold};
    text-wrap: wrap;
  `}
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    text-wrap: wrap;
  `}
`;

export const Label = styled(Wrapper)<{
  $color: string;
  $backgroundColor: string;
}>`
  ${({ theme, $color, $backgroundColor }) => css`
    background-color: ${$backgroundColor};
    padding: 3px 10px;
    margin-left: 10px;
    margin-top: 3px;
    border-radius: 5px;

    > p {
      color: ${$color};
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }
  `}
`;

export const Link = styled('a')`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
    color: ${theme.palette.blue['300']};
  `}
`;
