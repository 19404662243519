import styled, { css } from 'styled-components';

import { Stack } from '@mui/material';

export const Root = styled(Stack)`
  ${() => css`
    width: 540px;
    padding-bottom: 12px;
  `}
`;
