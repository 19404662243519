import styled, { css } from 'styled-components';
import { MenuItem as MuiMenuItem } from '@mui/material';

import { Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { userDetailsLayoutLeftBarWidth } from '@trader/constants';

export const LeftBar = styled(Wrapper)`
  ${({ theme }) => css`
    width: ${userDetailsLayoutLeftBarWidth}px;
    height: 100%;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${theme.palette.background.paper};
    margin-right: 12px;
  `};
`;

export const LeftBarItem = styled(MuiMenuItem)`
  ${({ theme, selected }) => css`
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 12px;
      margin-bottom: 3px;
    }

    p {
      font-size: ${theme.typography.xl.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }

    ${conditionApply(
      [selected],
      css`
        background-color: ${theme.palette.gray.light}!important;
      `
    )};
  `};
`;
