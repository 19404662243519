import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  EChartLayoutTypes,
  IChangeFieldEvent,
  IOption,
  TOptions,
} from '@trader/types';
import { defaultSubCategoryName } from '@trader/constants';
import { useMst } from '@trader/store';
import { autoScrollTo } from '@trader/utils';

import { Select } from '../select';

import * as Styled from './styled';

interface ISubcategories {
  withTopDivider?: boolean;
}

export const Subcategories: React.FC<ISubcategories> = observer(
  ({ withTopDivider = true }) => {
    const store = useMst();

    const subTab = store.pages.trading.selectedSubCategory;
    const category = store.pages.trading.selectedCategory;
    const isSingleLayout =
      store.pages.trading.layout.layoutType === EChartLayoutTypes.Single;

    const subCategories = category
      ? category.subCategories.length > 0
        ? [
            { id: defaultSubCategoryName, name: defaultSubCategoryName },
            ...category.subCategories,
          ]
        : []
      : [];

    useEffect(() => {
      autoScrollTo(`subcategory-${subTab}`);
    }, []);

    if (!subCategories.length) {
      return null;
    }

    const subCategoriesOptions: Array<IOption> = subCategories.map(s => ({
      title: s.name,
      value: s.id,
    }));
    const selectedSubCategory: Array<IOption> = [
      {
        title:
          subCategoriesOptions.find(s => s.value === subTab)?.title ||
          defaultSubCategoryName,
        value: subTab,
      },
    ];

    const handleChange = async (event: IChangeFieldEvent<TOptions>) => {
      const [option] = event.target.value;

      store.pages.trading.runInAction(() => {
        store.trading.getTrading('createOrder').resetOrderType();
        store.pages.trading.selectedSubCategory = option.value;
      });

      const resp = await store.entities.instruments.getInstrumentsAsync.run({
        shouldClearBeforeMerge: true,
      });

      isSingleLayout &&
        store.pages.trading.layout.selectInstrument(
          resp?.instruments?.length ? resp?.instruments[0]?.symbol : ''
        );
    };

    return (
      <Styled.Root
        $shouldHide={!subCategories.length}
        $withTopDivider={withTopDivider}
      >
        <Styled.Container className='select-subcategories'>
          <Select
            name='subcategory'
            fullWidth
            value={selectedSubCategory}
            options={subCategoriesOptions}
            onChange={handleChange}
          />
        </Styled.Container>
        <Styled.Container className='click-subcategories'>
          <SubcategoriesMobile />
        </Styled.Container>
      </Styled.Root>
    );
  }
);

export const SubcategoriesMobile: React.FC = observer(() => {
  const store = useMst();

  const subTab = store.pages.trading.selectedSubCategory;
  const category = store.pages.trading.selectedCategory;
  const isSingleLayout =
    store.pages.trading.layout.layoutType === EChartLayoutTypes.Single;

  const subCategories = category
    ? category.subCategories.length > 0
      ? [
          { id: defaultSubCategoryName, name: defaultSubCategoryName },
          ...category.subCategories,
        ]
      : []
    : [];

  const onChange = item => async () => {
    autoScrollTo(`subcategory-${item.id}`);

    store.pages.trading.runInAction(() => {
      store.pages.trading.selectedSubCategory = item.id.toString();
    });

    const resp = await store.entities.instruments.getInstrumentsAsync.run({
      shouldClearBeforeMerge: true,
    });

    isSingleLayout &&
      store.pages.trading.layout.selectInstrument(
        resp?.instruments?.length ? resp?.instruments[0]?.symbol : ''
      );
  };

  useEffect(() => {
    autoScrollTo(`subcategory-${subTab}`);
  }, []);

  return subCategories.map(sub => (
    <Styled.Item
      $isSelected={sub.id === subTab}
      id={`subcategory-${sub.id}`}
      onClick={onChange(sub)}
      key={sub.id}
    >
      {sub.name}
    </Styled.Item>
  ));
});
