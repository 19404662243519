import { getIconBaseProps } from './utils';

export const challengeFailed = {
  ...getIconBaseProps('challengeFailed'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        stroke='#F73F4A'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 11.2C9 11.675 8.575 12.1 8.1 12.1C7.625 12.1 7.2 11.675 7.2 11.2C7.2 10.725 7.625 10.3 8.1 10.3C8.575 10.3 9 10.725 9 11.2Z'
        strokeWidth='0.5'
        fill='#F73F4A'
      />
      <path
        d='M8 8L8 5.3'
        stroke='#F73F4A'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
