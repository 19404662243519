import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { maxSl, minQuantity } from '@trader/constants';
import { Controller } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';

import { IBackTestingInitialValues } from './index';
import { getPipSizeNumber } from '@trader/utils';

export const Quantity = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const symbol = backTesting.getSymbol();

  const { setValue, control } = useFormContext<IBackTestingInitialValues>();

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  useEffect(() => {
    if (!symbol) {
      return setValue('quantity', 0);
    }

    if (instrument) {
      setValue('quantity', minQuantity);
    }
  }, [symbol, instrument?.minOrderSize]);

  return (
    <Controller
      type='tradingInput'
      shouldCheckActivityOfButtons
      minValue={minQuantity}
      maxValue={maxSl}
      step={minQuantity}
      fixDigitAfterDot={getPipSizeNumber(minQuantity)}
      control={control}
      name='quantity'
      disabled={!symbol}
      customLabel={translate('COMMON.LABELS.QUANTITY')}
    />
  );
});
