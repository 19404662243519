import React from 'react';
import { observer } from 'mobx-react-lite';

import { calculateSpread } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType } from '@trader/types';

import { BuyButton } from '../buyButton';
import * as Styled from './styled';

export const SideButtons: React.FC<ICommonOrderType> = observer(({ type }) => {
  const store = useMst();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const spread = calculateSpread({
    ask: Number(instrument.ask),
    bid: Number(instrument.bid),
    pipSize: instrument.pipSize,
  });

  return (
    <Styled.ActionButtons>
      <BuyButton type={type} isAsk side='Sell' />
      <Styled.Spread>{spread}</Styled.Spread>
      <BuyButton type={type} side='Buy' />
    </Styled.ActionButtons>
  );
});
