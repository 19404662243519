import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  EChallenge,
  EChallengeMode,
  EChallengeResult,
  EChallengeStatus,
} from '@trader/types';

import { createCollectionModel } from '../../utils/collectionModel';

import { getChallengesAsync } from './actions';

export const challengeModel = types.model('challengeModel', {
  id: types.identifierNumber,
  status: types.union(
    types.literal(EChallengeStatus.Active),
    types.literal(EChallengeStatus.Disable)
  ),
  accountId: types.number,
  hierarchyType: types.union(
    types.literal(EChallenge.Slave),
    types.literal(EChallenge.Master),
    types.literal(EChallenge.NonAssigned)
  ),
  masterId: types.maybeNull(types.number),
  purchaseAmount: types.number,
  challengeAmount: types.number,
  name: types.maybeNull(types.string),
  payout: types.number,
  platformLogin: types.string,
  profitTarget: types.number,
  tradingDays: types.number,
  minTradingDays: types.number,
  remainingTime: types.number,
  result: types.union(
    types.literal(EChallengeResult.Completed),
    types.literal(EChallengeResult.InProgress),
    types.literal(EChallengeResult.Cancelled),
    types.literal(EChallengeResult.Failed)
  ),
  blockedDueToDailyTarget: types.boolean,
  mode: types.maybeNull(
    types.union(
      types.literal(EChallengeMode.Real),
      types.literal(EChallengeMode.Practice)
    )
  ),
});

interface IChallengesAsync {
  getChallengesAsync: typeof getChallengesAsync;
}

export const challenges = createCollectionModel<
  typeof challengeModel,
  IChallengesAsync
>(challengeModel, {
  getChallengesAsync,
});

export type TChallengeEntity = Instance<typeof challengeModel>;

export interface IChallengeEntitySnapshotOut
  extends SnapshotOut<typeof challengeModel> {}
