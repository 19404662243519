import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Progress, Tabs, Tooltip, Wrapper } from '@trader/components';
import { inAppMessagingService, useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import { IAssetDetails } from '../index';
import * as Styled from './styled';

const AlertsListLazy = React.lazy(() =>
  import('@trader/components').then(({ AlertsList }) => ({
    default: AlertsList,
  }))
);
const PositionsListLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsList }) => ({
    default: PositionsList,
  }))
);
const PendingOrdersListLazy = React.lazy(() =>
  import('@trader/components').then(({ PendingOrdersList }) => ({
    default: PendingOrdersList,
  }))
);
const PositionsHistoryLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsHistory }) => ({
    default: PositionsHistory,
  }))
);

const initialMaxHeight = 400;
const initialMinHeight = 45;

export const StableDesign: React.FC<IAssetDetails> = observer(
  ({ selectedTab, navigationKey = 'assetsView' }) => {
    const store = useMst();
    const { translate } = useI18next();

    const getInitialHeight = useMemo(
      () =>
        store.pages.trading.layout.layoutType === 'single'
          ? initialMaxHeight
          : initialMinHeight,
      [store.pages.trading.layout.layoutType]
    );

    const [height, setHeight] = useState(getInitialHeight);

    const isMinHeight = useMemo(() => height === initialMinHeight, [height]);
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const alerts = store.entities.alerts.getAll();

    const getTabs = () => {
      const items = [
        {
          label: translate('COMMON.LABELS.POSITIONS'),
          value: 'positions',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsListLazy />
            </React.Suspense>
          ),
        },
        {
          label: translate('COMMON.LABELS.HISTORY'),
          value: 'history',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsHistoryLazy />
            </React.Suspense>
          ),
        },
        {
          label: (
            <Wrapper alignItems='center'>
              <span>{translate('COMMON.LABELS.PRICE_ALERTS')}</span>
              {!inAppMessagingService.isPermissionGranted() &&
                !!alerts.length && (
                  <Tooltip title={translate('WARNINGS.NOTIFICATION_DISABLED')}>
                    <Styled.WarningIcon iconType='warning' />
                  </Tooltip>
                )}
            </Wrapper>
          ),
          value: 'price-alerts',
          children: (
            <React.Suspense fallback={<Progress />}>
              <AlertsListLazy />
            </React.Suspense>
          ),
        },
      ];

      if (!isInvestmentAccProduct) {
        items.splice(1, 0, {
          label: translate('COMMON.LABELS.PENDING_ORDERS'),
          value: 'pending-orders',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PendingOrdersListLazy />
            </React.Suspense>
          ),
        });
      }

      return items;
    };

    const toggleHeight = useCallback(() => {
      setHeight(prev =>
        prev === initialMaxHeight ? initialMinHeight : initialMaxHeight
      );
    }, []);

    return (
      <Styled.Root className='assets' $isHide={isMinHeight}>
        <Styled.Toggle
          $isHide={isMinHeight}
          iconType={isMinHeight ? 'minimize' : 'maximize'}
          onClick={toggleHeight}
        />
        <Styled.Title>
          {translate('COMMON.LABELS.ORDERS_AND_ALERTS')}
        </Styled.Title>
        <Tabs
          tabsPosition='top'
          marginBottom='0px'
          navigationKey={navigationKey}
          onChange={() => setHeight(initialMaxHeight)}
          tabs={getTabs()}
          preselectedTab={selectedTab}
        />
      </Styled.Root>
    );
  }
);
