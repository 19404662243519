import styled, { css } from 'styled-components';

import { IconButton, Typography, Wrapper, Button } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';

interface IRoot {
  $alignment?: string;
  $layout?: EChartLayoutTypes;
  $selectedLayout?: EChartLayouts;
  $instrumentLayout?: EChartLayouts;
}

export const Header = styled(Wrapper)<IRoot>`
  ${({ theme, $layout, $selectedLayout, $instrumentLayout }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 68px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid ${theme.palette.background.default};

    ${conditionApply(
      [$layout !== EChartLayoutTypes.Single],
      css`
        justify-content: flex-start;
      `
    )}
    ${conditionApply(
      [
        $layout !== EChartLayoutTypes.Single &&
          $selectedLayout === $instrumentLayout,
      ],
      css`
        background-color: ${theme.palette.grey.main};
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      `
    )}
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      height: auto;
      padding: 8px;
      margin-bottom: ${layoutMargin}px;
      background: ${theme.palette.background.paper};
      border-radius: ${layoutChildrenRadius}px;
    }
  `};
`;

export const Info = styled(Wrapper)`
  ${() => css`
    display: flex;
    width: 100%;
    flex-direction: row;
  `};
`;
export const MobileContainer = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: flex-end;

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `};
`;

export const DesktopContainer = styled(Wrapper)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const Search = styled(IconButton)`
  ${() => css`
    z-index: 4;
  `};
`;

export const BackTestToggle = styled(Button)`
  ${({ theme }) => css`
    width: 129px;
    margin-right: 12px;
    background: ${theme.palette.primary.dark};
    color: ${theme.palette.common.white};
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.typography.large.fontSize};
    padding: 0 10px;

    :hover {
      background: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
    }
  `};
`;

export const DropDownSearch = styled(Wrapper)`
  ${({ theme }) => css`
    width: 300px;

    ${theme.breakpoints.down('md')} {
      width: 170px;
    }

    & .MuiOutlinedInput-root {
      padding: 0;
    }

    & .Mui-Autocomplete-search-field {
      background: ${theme.palette.black[100]};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  `};
`;

export const InstrumentInfo = styled(Wrapper)<IRoot>`
  ${({ $layout, theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    width: 130px;
    justify-content: center;
    ${conditionApply(
      [$layout !== EChartLayoutTypes.Single],
      css`
        width: 100px;
      `
    )};

    ${theme.breakpoints.down('sm')} {
      width: 50%;
    }
  `};
`;

export const ExtraInfo = styled(Wrapper)`
  ${() => css`
    width: 100%;
  `};
`;

export const Symbol = styled(Typography)<IRoot>`
  ${({ theme, $layout }) => css`
    font-size: 1.25rem;
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.primary.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${conditionApply(
      [$layout !== EChartLayoutTypes.Single],
      css`
        font-size: ${theme.typography.xl.fontSize};
      `
    )};
  `};
`;

export const Description = styled(Typography)<IRoot>`
  ${({ theme, $layout }) => css`
    font-size: ${theme.typography.xl.fontSize};
    color: ${theme.palette.grey.main};

    ${conditionApply(
      [$layout !== EChartLayoutTypes.Single],
      css`
        font-size: ${theme.typography.small.fontSize};
      `
    )};
  `};
`;

interface IDifference {
  $isAboveZero: boolean;
  $layout?: string;
}

export const Difference = styled(Typography)<IDifference>`
  ${({ theme, $isAboveZero, $layout }) => css`
    font-size: ${theme.typography.xl.fontSize};
    min-width: min-content;
    color: ${$isAboveZero
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};

    ${conditionApply(
      [$layout !== EChartLayoutTypes.Single],
      css`
        font-size: ${theme.typography.small.fontSize};
      `
    )};
  `};
`;
