import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
    justify-content: space-between;

    form {
      height: 100%;
    }
  `}
`;

export const Field = styled(Wrapper)`
  ${() => css`
    align-items: baseline;
    margin-bottom: 10px;

    > div > div:nth-child(2) {
      width: 320px;
      height: 55px;
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    width: 220px;
    font-size: ${theme.typography.large.fontSize};

    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `}
`;
