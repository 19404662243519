import styled, { css } from 'styled-components';
import { MenuItem as MuiMenuItem } from '@mui/material';

import { Wrapper, Icon } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    padding-left: 15px;
    justify-content: center;
    align-items: center;

    & > button {
      width: 35px;
      height: 35px;
      border-radius: 3px;

      .MuiTouchRipple-child {
        border-radius: inherit;
      }
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
      visibility: hidden;
    }
  `};
`;

export const Children = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: center;
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin-left: 8px;
    width: 100%;
  `};
`;

export const MenuItem = styled(MuiMenuItem)`
  ${({ theme }) => css`
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${theme.palette.background.default};
  `};
`;

export const PaperArrow = styled(Icon)`
  ${({ theme }) => css`
    position: absolute;
    top: -10px;
    right: 20px;
    color: ${theme.palette.menu.main};
  `};
`;
