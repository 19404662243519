import { useEffect } from 'react';

import { getSpreadDifference } from '@trader/utils';
import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import { getAccountTypeForConnection, productId } from '@trader/constants';
import { IMessage } from '@trader/types';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

type TUpdatedPositions = {
  positions: { positionId: string; price: number }[];
  platformLogin: string;
};

interface IParams {
  hub?: EConnectionHub;
  subscriber?: EConnectionHubSubscriber;
  account?: TTradingAccountEntity;
  onUpdate?: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsCurrentPrice = (params?: IParams) => {
  const store = useMst();

  const { subscribe, unsubscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: params?.hub || EConnectionHub.Positions,
    subscriber:
      params?.subscriber || EConnectionHubSubscriber.PositionsCurrentPrice,
    onMessage: handlePositionCurrentPriceUpdated,
    invokedName: 'OnQuote',
  });

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = params?.account || store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const positions =
    params?.account?.positions ||
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  function handlePositionCurrentPriceUpdated(message: IMessage) {
    const symbolPositions = positions.filter(p => p.symbol === message.s);
    if (symbolPositions.length > 0) {
      const updatedPositions: TUpdatedPositions['positions'] = [];

      for (const position of symbolPositions) {
        const { ask, bid } = getSpreadDifference(
          position.spreadDiff,
          message.a,
          message.b,
          position.spreadDiffBalance,
          position.pipSize
        );
        const price = position.side === 'Buy' ? ask : bid;

        if (params?.onUpdate) {
          updatedPositions.push({
            positionId: position.positionId,
            price,
          });
        } else {
          store.entities.positionsMetrics.update(position.positionId, {
            price,
          });
        }
      }

      params?.onUpdate &&
        params?.onUpdate({
          positions: updatedPositions,
          platformLogin: activeTradingAccount?.platformLogin || '',
        });
    }
  }

  /**
   * Handle subscribe on positions symbols.
   */
  useEffect(() => {
    if (positions.length > 0 && isAuth && platformLogin && accountType) {
      const positionSymbols = positions.map(p => p.symbol);
      const uniqueSymbols = [...new Set(positionSymbols)];

      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnQuotes',
          uniqueSymbols,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });

      return () => {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromQuotes',
            uniqueSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      };
    }
  }, [positions.length, platformLogin, idToken]);
};
