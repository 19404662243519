import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: [
    'DM Sans',
    'Poppins',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),

  // FontSize
  tiny: { fontSize: '0.625rem' }, // 10px
  small: { fontSize: '0.6875rem' }, // 11px
  default: { fontSize: '0.75rem' }, // 12px
  medium: { fontSize: '0.8125rem' }, // 13px
  large: { fontSize: '0.875rem' }, // 14px
  xl: { fontSize: '0.937rem' }, // 15px
  heading: { fontSize: '1.125rem' }, // 18px
  base: { fontSize: '1rem' }, // 16px

  // FontWeight
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
} as const;
