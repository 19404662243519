import { isEmpty } from 'lodash';

import { IInstrumentBE } from '@trader/api';
import {
  analyzeHourRanges,
  buildUtcDateBasedOnTime,
  formatDate,
  calculateTimeDifference,
  getUTCDate,
} from '@trader/utils';

class InstrumentHelpers {
  /**
   * Checks if trading for an instrument is currently unavailable due to holidays or session hours.
   */
  isInstrumentTradingUnavailable(
    holidays: IInstrumentBE['holidays'] | undefined,
    sessions: IInstrumentBE['sessions'] | undefined
  ) {
    if (!holidays || !sessions) {
      return {
        isUnavailable: false,
        isHoliday: false,
        openIn: '',
      };
    }

    const currentDate = getUTCDate();

    if (!isEmpty(holidays)) {
      const formattedDate = formatDate(currentDate, 'YYYY-MM-DD');
      const holidayHours = holidays[formattedDate];

      if (holidayHours) {
        const analyzedHolidayHours = analyzeHourRanges(holidayHours);
        const isHoliday = analyzedHolidayHours.hasMatchedWithCurrentDate;

        if (isHoliday) {
          const toDate = analyzedHolidayHours.matchedRange
            ? buildUtcDateBasedOnTime(analyzedHolidayHours.matchedRange.to)
            : null;

          return {
            isUnavailable: true,
            isHoliday: true,
            openIn: toDate ? calculateTimeDifference(currentDate, toDate) : '',
          };
        }
      }
    }

    if (!isEmpty(sessions)) {
      const currentDayIndex = currentDate.getDay();
      const tradingHours = sessions[currentDayIndex]?.tradingHours || [];

      if (isEmpty(tradingHours)) {
        return {
          isUnavailable: true,
          isHoliday: true,
          openIn: '',
        };
      }

      const analyzedTradingHours = analyzeHourRanges(tradingHours);
      const isTradingUnavailable =
        !analyzedTradingHours.hasMatchedWithCurrentDate;
      let nextAvailableDate = analyzedTradingHours.nextCurrentDateMatch;

      /* Handle the case when next available trading date will be in next day. */
      if (
        isTradingUnavailable &&
        (!nextAvailableDate || currentDate > nextAvailableDate)
      ) {
        const lastDayIndex = 6;
        const nextDayIndex =
          currentDayIndex === lastDayIndex ? 0 : currentDayIndex + 1;
        const hours = sessions[nextDayIndex]?.tradingHours;

        if (hours && hours.length > 0) {
          const fromDate = buildUtcDateBasedOnTime(hours[0].from);
          fromDate.setDate(currentDate.getDate() + 1);
          nextAvailableDate = fromDate;
        }
      }

      return {
        isUnavailable: isTradingUnavailable,
        isHoliday: false,
        openIn: nextAvailableDate
          ? calculateTimeDifference(currentDate, nextAvailableDate)
          : '',
      };
    }

    return {
      isUnavailable: false,
      isHoliday: false,
      openIn: '',
    };
  }
}

export const instrumentHelpers = new InstrumentHelpers();
