import { useEffect } from 'react';

import { getAccountTypeForConnection, productId } from '@trader/constants';
import { useMst } from '@trader/store';
import { getSpreadDifference } from '@trader/utils';
import { IMessage } from '@trader/types';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useGetPageVisibility } from '../useGetPageVisibility';
import { useHub } from './core/useHub';

export const useInstrument = (
  symbol: string,
  spreadDiff: number,
  spreadDiffBalance: number,
  pipSize: number,
  onMessage: (ask: number, bid: number) => void
): void => {
  const store = useMst();
  const isVisible = useGetPageVisibility();

  const idToken = store.auth.tokens.idToken;
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const handleUpdate = (message: IMessage) => {
    if (message.s === symbol) {
      const { ask, bid } = getSpreadDifference(
        spreadDiff,
        message.a,
        message.b,
        spreadDiffBalance,
        pipSize
      );
      spreadDiff ? onMessage(ask, bid) : onMessage(message.a, message.b);
    }
  };

  const { subscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: EConnectionHub.Quotes,
    subscriber: EConnectionHubSubscriber.Quotes,
    onMessage: handleUpdate,
    invokedName: 'OnQuote',
  });

  useEffect(() => {
    if (symbol && platformLogin && accountType) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnQuote',
          symbol,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });
    }
  }, [symbol, platformLogin, idToken]);

  useEffect(() => {
    if (isVisible && accountType) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnQuote',
          symbol,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });
    }
  }, [isVisible]);
};
