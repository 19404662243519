import { getIconBaseProps } from './utils';

export const account = {
  ...getIconBaseProps('account'),
  width: '12px',
  height: '14px',
  viewBox: '0 0 12 14',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5.92762 8.21638C3.79208 8.21638 1.9551 9.17301 1.14009 10.7442C0.644394 11.6998 1.54342 12.6305 2.59512 12.6305H9.26011C10.3118 12.6305 11.2108 11.6998 10.7151 10.7442C9.90013 9.17301 8.06316 8.21638 5.92762 8.21638Z'
        fill='currentColor'
      />
      <path
        d='M8.30797 3.8214C8.30797 5.17575 7.24225 6.27367 5.92762 6.27367C4.61299 6.27367 3.54726 5.17575 3.54726 3.8214C3.54726 2.46706 4.61299 1.36914 5.92762 1.36914C7.24225 1.36914 8.30797 2.46706 8.30797 3.8214Z'
        fill='currentColor'
      />
      <path
        d='M5.92762 8.21638C3.79208 8.21638 1.9551 9.17301 1.14009 10.7442C0.644394 11.6998 1.54342 12.6305 2.59512 12.6305H9.26011C10.3118 12.6305 11.2108 11.6998 10.7151 10.7442C9.90013 9.17301 8.06316 8.21638 5.92762 8.21638Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.30797 3.8214C8.30797 5.17575 7.24225 6.27367 5.92762 6.27367C4.61299 6.27367 3.54726 5.17575 3.54726 3.8214C3.54726 2.46706 4.61299 1.36914 5.92762 1.36914C7.24225 1.36914 8.30797 2.46706 8.30797 3.8214Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
