import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { ICommonOrderType, TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IBuyButton extends ICommonOrderType {
  side: TPlaceOrderSide;
  isAsk?: boolean;
  isDisabled?: boolean;
}

export const BuyButton: React.FC<IBuyButton> = ({
  side,
  isDisabled,
  isAsk,
  type,
}) => {
  const store = useMst();
  const { translate } = useI18next();
  const { reset } = useFormContext();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;
  const isActive = trading.side === side;

  const changeButtonType = () => {
    if (!isDisabled) {
      reset(
        {
          side: side,
          orderType: trading.orderType,
        },
        { keepValues: true }
      );
      trading.updateSide(side);
    }
  };

  return (
    <Styled.ActionButton
      isAsk={isAsk}
      isActive={isActive}
      $isDisabled={isDisabled}
      onClick={changeButtonType}
    >
      <Styled.ButtonTitle isActive={isActive} isAsk={isAsk}>
        {translate(`COMMON.LABELS.${side?.toUpperCase()}`)}
      </Styled.ButtonTitle>
      <Styled.ButtonPrice isActive={isActive} isAsk={isAsk}>
        {side !== 'Sell'
          ? formatByPipSize(Number(instrument.bid), instrument.pipSize)
          : formatByPipSize(Number(instrument.ask), instrument.pipSize)}
      </Styled.ButtonPrice>
    </Styled.ActionButton>
  );
};
