import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { checkOnTickSize, formatByPipSize } from '@trader/utils';
import { adapterLineStyle, emptyFn } from '@trader/constants';
import { devLoggerService, useI18next } from '@trader/services';
import { TInstrumentEntity, TOrderMetricEntity, useMst } from '@trader/store';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';

interface IOrder {
  key: string;
  ref: IOrderLineAdapter | undefined;
}

const orderLineLength = 25;
const orders = new Map<string, IOrder>();

export const useDisplayAllOrders = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const { sideColor, typeColor } = useGetAdapterDiff('Order');

  const [activeChartSymbol, setActiveChartSymbol] = React.useState<
    string | undefined
  >(undefined);

  const idToken = store.auth.tokens.idToken;
  const trading = store.trading.getTrading('createOrder');

  const ordersMetrics =
    store.entities.ordersMetrics.getAll<TOrderMetricEntity>();

  const symbol =
    (trading.instrument?.symbol as string) ||
    store.pages.trading.getInstrumentSymbolByLayout();
  const shouldDisplayAllOrdersOnChart =
    !trading.side && store.app.isBetaDesignEnabled();

  const symbolOrdersMetrics = ordersMetrics.filter(
    metric => metric.symbol === symbol
  );

  const createOrder = (id: string) => {
    const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);
    const price = (metric.limitPrice || metric.stopPrice) as number;

    const tp = metric.takeProfit
      ? `TP: ${formatByPipSize(metric.takeProfit, metric.pipSize)}`
      : '';
    const sl = metric.stopLoss
      ? `SL: ${formatByPipSize(metric.stopLoss, metric.pipSize)}`
      : '';

    const ref = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setCancelTooltip(translate('COMMON.LABELS.CANCEL_ORDER'))
      .setText(
        `${translate(
          `COMMON.LABELS.${metric.side?.toUpperCase()}`
        )} ${translate('TRADING.LIMIT')}`
      )
      .setBodyTextColor(sideColor(metric.side))
      .setBodyBorderColor(typeColor(id, metric.side))
      .setBodyBackgroundColor(theme.palette.white.main)
      .setQuantity(`${metric.quantity}; ${tp} ${sl}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setLineColor(typeColor(id, metric.side))
      .setLineLength(orderLineLength)
      .setLineStyle(adapterLineStyle)
      .setCancelButtonBorderColor(theme.palette.tab.light)
      .setCancelButtonBackgroundColor(theme.palette.tab.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .onCancel(async function () {
        try {
          const order =
            store.entities.ordersMetrics.get<TOrderMetricEntity>(id);

          if (order) {
            await order.cancelOrderAsync.run(id);
          }
        } catch (e) {
          devLoggerService.error('onCancel called', e);
        }
      })
      .onMove(async function () {
        const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);
        const instrument = store.entities.instruments.get<TInstrumentEntity>(
          order.symbol
        );

        if (checkOnTickSize(this.getPrice(), instrument.tickSize)) {
          this.setPrice(order.limitPrice || order.stopPrice);
          return store.notifications.add({
            message: translate('COMMON.ERRORS.TICK_SIZE', {
              tickSize: instrument.tickSize,
            }),
            options: {
              variant: 'warning',
            },
          });
        }

        try {
          await order.editOrderAsync.run({
            orderId: id,
            body: {
              symbol: order.symbol,
              type: order.type,
              side: order.side,
              quantity: order.quantity,
              price: Number(this.getPrice()),
              takeProfit: order.takeProfit || undefined,
              stopLoss: order.stopLoss || undefined,
            },
          });

          this.setLineColor(theme.palette.tab.light);
        } catch (error) {
          this.setPrice(order.limitPrice || order.stopPrice);
        }
      })
      .setPrice(price);

    orders.set(id, {
      ...orders.get(id),
      key: id,
      ref,
    });
  };

  const clearOrders = useCallback(() => {
    Array.from(orders.values()).forEach(order => {
      order?.ref?.remove();
    });
    orders.clear();
  }, []);

  // wait till chart will be fully ready to drawing adapters
  useEffect(() => {
    widget.current?.onChartReady(() => {
      widget.current
        ?.activeChart()
        .onDataLoaded()
        .subscribe(null, () => {
          setActiveChartSymbol(widget.current?.activeChart().symbol());
        });
    });
  }, []);

  // initialize orders
  useEffect(() => {
    if (symbol === activeChartSymbol && shouldDisplayAllOrdersOnChart) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolOrdersMetrics.forEach(metric => {
            if (!metric) {
              return;
            }
            if (!orders.get(metric.orderId)) {
              createOrder(metric.orderId);
            }
          });
        }
      });
    }
    return () => clearOrders();
  }, [activeChartSymbol, symbol, idToken]);

  // create & update orders
  useEffect(() => {
    if (shouldDisplayAllOrdersOnChart && activeChartSymbol === symbol) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolOrdersMetrics.forEach(metric => {
            const price = (metric.limitPrice || metric.stopPrice) as number;
            const orderRef = orders.get(metric.orderId)?.ref;

            if (orderRef) {
              const tp = metric.takeProfit
                ? `TP: ${formatByPipSize(metric.takeProfit, metric.pipSize)}`
                : '';
              const sl = metric.stopLoss
                ? `SL: ${formatByPipSize(metric.stopLoss, metric.pipSize)}`
                : '';

              orderRef?.setPrice(price);
              orderRef?.setQuantity(`${metric.quantity}; ${tp} ${sl}`);
            } else {
              createOrder(metric.orderId);
            }
          });
        }
      });
    }
  }, [
    JSON.stringify(symbolOrdersMetrics),
    shouldDisplayAllOrdersOnChart,
    activeChartSymbol,
    symbol,
  ]);

  // delete order
  useEffect(() => {
    Array.from(orders.values()).forEach((order: IOrder) => {
      const matchedOrder = store.entities.ordersMetrics.get<TOrderMetricEntity>(
        order.key
      );
      if (!matchedOrder) {
        orders.get(order.key)?.ref?.remove();
        orders.delete(order.key);
      }
    });
  }, [symbolOrdersMetrics.length]);

  useEffect(() => {
    if (!shouldDisplayAllOrdersOnChart) {
      clearOrders();
    }
  }, [shouldDisplayAllOrdersOnChart]);
};
