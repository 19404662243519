import React from 'react';

import { Wrapper, Typography } from '@trader/components';
import { defaultIcon } from '@trader/constants';

interface ISymbolWithIcon {
  iconUrl?: string | null;
  symbol: string;
}

export const SymbolWithIcon: React.FC<ISymbolWithIcon> = ({
  iconUrl,
  symbol,
}) => {
  return (
    <Wrapper alignItems='center'>
      <img
        src={iconUrl || defaultIcon}
        alt='instrument icon'
        width={20}
        height={20}
      />
      <Typography variant='tiny' fontWeight='medium' marginLeft='5px'>
        {symbol}
      </Typography>
    </Wrapper>
  );
};
