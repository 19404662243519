import React from 'react';
import { TypographyProps, useTheme } from '@mui/material';

import * as Styled from './styled';

export const Typography: React.FC<TypographyProps> = ({
  children,
  fontWeight,
  ...props
}) => {
  const theme = useTheme();

  const fontWeightMapping: Record<string, number> = {
    regular: theme.typography.fontWeightRegular as number,
    medium: theme.typography.fontWeightMedium as number,
    bold: theme.typography.fontWeightBold as number,
  };
  const resolvedFontWeight =
    typeof fontWeight === 'string' && fontWeightMapping[fontWeight]
      ? fontWeightMapping[fontWeight]
      : fontWeight;

  return (
    <Styled.Typography
      {...props}
      fontWeight={resolvedFontWeight || fontWeightMapping.regular}
      variant={props.variant || 'default'}
      component={props.component || 'p'}
    >
      {children}
    </Styled.Typography>
  );
};
