import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';

export const Paragraph = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.tiny.fontSize};
    white-space: pre-wrap;
    text-overflow: clip;
    margin-top: 6px;

    > span {
      font-weight: 600;
    }

    .resistance {
      color: rgb(49, 146, 26);
    }

    .support {
      color: rgb(219, 46, 39);
    }

    .pivot {
      color: rgb(101, 67, 186);
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-weight: 600;
    margin-top: 4px;
    white-space: pre-wrap;
    text-overflow: clip;
  `};
`;
