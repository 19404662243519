import styled, { css } from 'styled-components';
import { Button, Icon, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { layoutChildrenRadius } from '@trader/constants';

interface IFullHeight {
  $isFullHeight?: boolean;
}

export const Root = styled(Wrapper)<IFullHeight>`
  ${({ $isFullHeight }) => css`
    flex-direction: column;
    overflow-y: auto;
    ${conditionApply(
      [$isFullHeight],
      css`
        margin-bottom: 30px;
      `
    )}
  `}
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    width: fit-content;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid ${theme.palette.background.default};
    border-radius: ${layoutChildrenRadius}px;

    ${theme.breakpoints.down('sm')} {
      height: 350px;
      overflow-x: scroll;
    }

    .challenge:last-child {
      border-bottom: none;
    }
  `}
`;

export const Header = styled(Wrapper)`
  ${({ theme }) => css`
    height: 43px;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.gray[300]};
    background: ${theme.palette.gray[600]};

    > span {
      font-size: ${theme.typography.default.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
      text-transform: capitalize;
      color: ${theme.palette.gray[700]};
    }
  `}
`;

export const Body = styled(Wrapper)<IFullHeight>`
  ${({ $isFullHeight }) => css`
    overflow: auto;
    max-height: 220px;
    flex-direction: column;

    ${conditionApply(
      [$isFullHeight],
      css`
        max-height: 100%;
      `
    )}
  `}
`;
export const Row = styled(Wrapper)`
  ${({ theme }) => css`
    min-height: 55px;
    border-bottom: 1px solid ${theme.palette.gray[300]};

    > span {
      font-size: ${theme.typography.small.fontSize};
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  `}
`;

export const ChangeAccount = styled(Button)`
  ${({ theme, disabled }) => css`
    width: 100%;
    height: 100%;
    border-radius: 0;
    background: transparent;

    ${conditionApply(
      [disabled],
      css`
        background: transparent !important;
      `
    )}
    &:hover {
      background: ${theme.palette.green[800]};
      box-shadow: none;
    }

    > span {
      font-size: ${theme.typography.default.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }
  `}
`;

interface IValue {
  $isPositive?: boolean;
  $isNegative?: boolean;
  $isProgress?: boolean;
  $isFullBgItem?: boolean;
  $hasBg?: boolean;
}

export const Value = styled(Typography)<IValue>`
  ${({
    $isPositive,
    $isNegative,
    $isProgress,
    $isFullBgItem,
    $hasBg,
    theme,
  }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    display: flex;
    align-items: center;
    flex-direction: row;
    text-transform: capitalize;
    color: ${theme.palette.text.primary};

    &:has(span) {
      background: red;
    }

    ${conditionApply(
      [$isPositive],
      css`
        color: ${theme.palette.green.dark};
        ${conditionApply(
          [$isFullBgItem],
          css`
            background: ${theme.palette.green[700]};
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
          `
        )}

        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.green[200]};
            padding: 1px 3px;
            border-radius: 3px;
          `
        )}
      `
    )}

    ${conditionApply(
      [$isNegative],
      css`
        color: ${theme.palette.red.main};
        ${conditionApply(
          [$isFullBgItem],
          css`
            background: ${theme.palette.red[300]};
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
          `
        )}

        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.red.main};
            padding: 1px 3px;
            border-radius: 3px;
          `
        )}
      `
    )}

    ${conditionApply(
      [$isProgress],
      css`
        color: ${theme.palette.yellow.main};
        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.yellow.light};
            padding: 1px 3px;
            border-radius: 3px;
          `
        )}
      `
    )}
  `}
`;

export const AccountType = styled(Icon)`
  ${() => css``}
`;

export const ActiveAccIcon = styled(Icon)`
  ${() => css`
    margin-right: 4px;
  `}
`;

export const DailyTargetProfitIcon = styled(Icon)<{ $achieved: boolean }>`
  ${({ theme, $achieved }) => css`
    width: 14px;
    height: 14px;

    path {
      stroke: ${$achieved ? theme.palette.green[800] : theme.palette.gray[200]};
    }
  `}
`;
