import React, { ReactNode } from 'react';

import { TIconsName } from '@trader/assets';
import { Icon, Wrapper } from '@trader/components';

import * as Styled from './styled';

interface IModalNotification {
  icon?: ReactNode;
  iconTypeName?: TIconsName;
  title: string;
  content: string | ReactNode;
  width?: string;
  confirmButton: {
    text: string;
    onClick: () => void;
  };
  refuseButton?: {
    text: string;
    onClick: () => void;
  };
}

export const ModalNotification: React.FC<IModalNotification> = ({
  icon,
  iconTypeName,
  title,
  content,
  width = '450px',
  confirmButton,
  refuseButton,
}) => {
  return (
    <Styled.Root $width={width}>
      {icon && icon}
      {iconTypeName && <Icon iconType={iconTypeName} />}
      <Styled.Title>{title}</Styled.Title>
      {typeof content === 'string' ? (
        <Styled.Content>{content}</Styled.Content>
      ) : (
        content
      )}
      <Wrapper marginTop='30px' width='100%'>
        {refuseButton && (
          <Styled.RefuseButton onClick={refuseButton.onClick}>
            {refuseButton.text}
          </Styled.RefuseButton>
        )}
        <Styled.ConfirmButton onClick={confirmButton.onClick}>
          {confirmButton.text}
        </Styled.ConfirmButton>
      </Wrapper>
    </Styled.Root>
  );
};
