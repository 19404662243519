import React from 'react';
import { useTheme } from 'styled-components';

import { Typography } from '@trader/components';

interface ITextFieldCharacterCounterProps {
  currentCount: number;
  maxCount: number;
}

export const TextFieldCharacterCounter: React.FC<
  ITextFieldCharacterCounterProps
> = ({ currentCount, maxCount }) => {
  const theme = useTheme();
  const isError = currentCount > maxCount;
  return (
    <Typography
      color={isError ? theme.palette.error.main : theme.palette.primary.dark}
    >
      {currentCount}/{maxCount}
    </Typography>
  );
};
