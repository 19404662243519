import { types, Instance, getRoot, IAnyStateTreeNode } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';
import { EAccountTypeNames, productId, productName } from '@trader/constants';

import { TChallengeEntity } from '../entities/challenges';
import { initialProfile, profile } from './profile';
import { initialSettings, settings } from './settings';
import {
  getProfileDemoAsync,
  makeDepositAsync,
  getSettingsAsync,
  getPspsAsync,
  getTransactionsAsync,
  getTransfersHistoryAsync,
  updateSettingsAsync,
  getPurchaseHistoryAsync,
  getAvailablePaymentMethodsAsync,
  getDepositLinkAsync,
} from './actions';
import {
  initialTradingAccount,
  tradingAccountModel,
  TTradingAccountEntity,
} from '../entities/tradingAccounts';
import {
  initialPortfolio,
  TTradingAccountPortfolio,
} from '../entities/tradingAccounts/portfolio';

export const userModel = types
  .model('userModel', {
    profile: profile,
    settings: settings,
    tradingAccount: types.safeReference(tradingAccountModel, {
      get(identifier, parent: IAnyStateTreeNode) {
        const root = getRoot(parent) as IAnyStateTreeNode;
        const entity = root.entities.tradingAccounts.get(identifier);
        return entity || initialTradingAccount;
      },
      set(value) {
        return value.tradingId;
      },
    }),
    getProfileDemoAsync,
    makeDepositAsync,
    getPspsAsync,
    getTransactionsAsync,
    getSettingsAsync,
    updateSettingsAsync,
    getTransfersHistoryAsync,
    getPurchaseHistoryAsync,
    getAvailablePaymentMethodsAsync,
    getDepositLinkAsync,
  })
  .views(state => ({
    getActiveAccountChallenge: (): TChallengeEntity | null =>
      state.tradingAccount?.challenge || null,
    getAccountChallenge: (accountId: number): TChallengeEntity | null => {
      const root: IAnyStateTreeNode = getRoot(state);
      return root.entities.tradingAccounts.get(accountId)?.challenge || null;
    },
    isDemoAccInUse: () =>
      state.tradingAccount?.accountType === EAccountTypeNames.Demo,
    activeTradingAcc: (): TTradingAccountEntity =>
      state.tradingAccount || initialTradingAccount,
    portfolio: (): TTradingAccountPortfolio =>
      state.tradingAccount?.portfolio || initialPortfolio,
    getAccountProduct: () => {
      return state.tradingAccount?.product || import.meta.env.VITE_PRODUCT;
    },
    isInvestmentAccProduct: () => {
      const product =
        state.tradingAccount?.product || import.meta.env.VITE_PRODUCT;
      const name = productName[productId[product]];

      return name === productName[productId.Investment];
    },
  }))
  .actions(state => ({
    runInAction,
    reset: () => {
      state.profile = initialProfile;
      state.settings = initialSettings;
    },
  }));

export const user = types.optional(userModel, {});

export type TUserStore = Instance<typeof userModel>;
