import { getIconBaseProps } from './utils';

export const edit = {
  ...getIconBaseProps('edit'),
  width: '10px',
  height: '12px',
  viewBox: '0 0 10 12',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M7.1664 1.35749C7.39529 1.12859 7.70574 1 8.02945 1C8.35316 1 8.66361 1.12859 8.89251 1.35749C9.12141 1.58639 9.25 1.89684 9.25 2.22055C9.25 2.54426 9.12141 2.85471 8.89251 3.0836L3.42648 8.54963L1.125 9.125L1.70037 6.82352L7.1664 1.35749Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
