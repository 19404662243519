import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

import { Typography } from '@trader/components';

export const ValueLabel = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
    flex-direction: column;
    text-transform: capitalize;
    background: ${theme.palette.aqua[100]};
    padding: 1px 6px;
    border-radius: 2px;
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Root = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 4px 10px;
    border-radius: 5px;
    background-color: ${theme.palette.aqua[100]};
  `}
`;
