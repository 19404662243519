import styled, { css } from 'styled-components';

import { Button, Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)<{ $width: string }>`
  ${({ $width }) => css`
    width: ${$width};
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 25px 0 8px 0;
  `};
`;

export const Content = styled(Typography)`
  ${({ theme }) => css`
    width: 95%;
    font-size: ${theme.typography.large.fontSize};
    white-space: normal;
    text-align: center;
  `};
`;

export const ConfirmButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.black};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;

export const RefuseButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.common.black};
    margin-right: 8px;

    &:hover {
      background-color: ${theme.palette.common.white};
    }
  `}
`;
