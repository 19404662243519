import { BANDS, maxMultiplier, minMultiplier } from '@trader/constants';
import { StudyInputType } from '../charting_library';

interface IStrip {
  key: string;
  color: string;
  value: number;
  cross: number;
  change: string;
  bestStripValue: string;
}

export const resetChartData = 'reset' as const; // need to reset historical data each 30 min to do not have missing vwap value

export const muliBandsInitial: { [key: string]: IStrip } = {
  [BANDS.RoundedVwap.id]: {
    key: BANDS.RoundedVwap.id,
    color: 'rgb(68,224,16)',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0',
  },
  [BANDS.Strip025High.id]: {
    key: BANDS.Strip025High.id,
    color: '#FE9CA2',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.25',
  },
  [BANDS.Strip0HalfHigh.id]: {
    key: BANDS.Strip0HalfHigh.id,
    color: '#FF8E94',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.5',
  },
  [BANDS.Strip075High.id]: {
    key: BANDS.Strip075High.id,
    color: '#F97F86',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.75',
  },
  [BANDS.Strip1High.id]: {
    key: BANDS.Strip1High.id,
    color: '#F46A71',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '1',
  },
  [BANDS.Strip1HalfHigh.id]: {
    key: BANDS.Strip1HalfHigh.id,
    color: '#ED575F',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '1.5',
  },
  [BANDS.Strip2High.id]: {
    key: BANDS.Strip2High.id,
    color: '#E5434C',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '2',
  },
  [BANDS.Strip2HalfHigh.id]: {
    key: BANDS.Strip2HalfHigh.id,
    color: '#D92F39',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '2.5',
  },
  [BANDS.Strip3High.id]: {
    key: BANDS.Strip3High.id,
    color: '#B2161F',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '3',
  },
  [BANDS.Strip025Low.id]: {
    key: BANDS.Strip025Low.id,
    color: '#ACDDD2',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.25',
  },
  [BANDS.Strip0HalfLow.id]: {
    key: BANDS.Strip0HalfLow.id,
    color: '#97D4C6',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.5',
  },
  [BANDS.Strip075Low.id]: {
    key: BANDS.Strip075Low.id,
    color: '#80CDBC',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '0.75',
  },
  [BANDS.Strip1Low.id]: {
    key: BANDS.Strip1Low.id,
    color: '#71BDAC',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '1',
  },
  [BANDS.Strip1HalfLow.id]: {
    key: BANDS.Strip1HalfLow.id,
    color: '#5FA999',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '1.5',
  },
  [BANDS.Strip2Low.id]: {
    key: BANDS.Strip2Low.id,
    color: '#4F9384',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '2',
  },
  [BANDS.Strip2HalfLow.id]: {
    key: BANDS.Strip2HalfLow.id,
    color: '#3F766A',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '2.5',
  },
  [BANDS.Strip3Low.id]: {
    key: BANDS.Strip3Low.id,
    color: '#214F45',
    value: NaN,
    cross: 0,
    change: '',
    bestStripValue: '3',
  },
};

export const muliBandsInputs = [
  {
    id: 'topMultiplier',
    name: 'Top Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: minMultiplier,
    min: minMultiplier,
    max: maxMultiplier,
    step: minMultiplier,
  },
  {
    id: 'bottomMultiplier',
    name: 'Bottom Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: minMultiplier,
    min: minMultiplier,
    max: maxMultiplier,
    step: minMultiplier,
  },
  {
    id: 'staticTopMultiplier',
    name: 'Static Top Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: 0.001,
    min: 0,
    max: 100,
    step: 0.001,
  },
  {
    id: 'staticBottomMultiplier',
    name: 'Static Bottom Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: 0.001,
    min: 0,
    max: 100,
    step: 0.001,
  },
];
