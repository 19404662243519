import styled, { css } from 'styled-components';

import {
  footerHeight,
  headerHeight,
  layoutMargin,
  userDetailsLayoutLeftBarWidth,
} from '@trader/constants';
import { Wrapper, Button } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
  `};
`;

export const Main = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: calc(
      100% - ${headerHeight}px - ${footerHeight}px - ${layoutMargin * 2}px
    );
  `};
`;

export const Content = styled(Wrapper)`
  ${({ theme }) => css`
    width: calc(100% - ${userDetailsLayoutLeftBarWidth}px - ${layoutMargin}px);
    height: 100%;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${theme.palette.background.paper};
  `};
`;

export const Title = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    padding: 24px;
    border-bottom: ${`1px solid ${theme.palette.gray.light}`};
    margin-bottom: 10px;

    p {
      font-weight: 600;
    }
  `};
`;

export const GoHomeButton = styled(Button)`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 5px;
    min-width: unset;
    background: ${theme.palette.common.black};
    align-items: center;
    margin-right: 8px;

    svg {
      margin: 0 !important;
      width: 10px;
      height: 10px;

      path {
        color: ${theme.palette.common.white};
      }
    }
  `}
`;
