import { TSupportedLocales } from '@trader/utils';
import { localeKey, thousand } from './constants';

export const convertToFullDate = (date: number | Date): Date =>
  new Date(typeof date === 'number' ? date * thousand : date);

export const convertDateToUTC = (
  date: number | Date,
  includeGMTLabel?: boolean,
  locale?: TSupportedLocales
): string => {
  const formatter = new Intl.DateTimeFormat(locale || localeKey['en'], {
    timeZone: 'UTC',
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hour12: false,
  });
  const utcDate = formatter.format(convertToFullDate(date));
  const utcDateWithGMT = utcDate
    .replace('UTC', 'GMT')
    // Replace all commas instead of the first one.
    .replace(/,/g, '')
    .replace(' ', ', ');

  return includeGMTLabel ? utcDateWithGMT : utcDateWithGMT.replace('GMT', '');
};

export const convertSecondsToMs = (seconds: number) => seconds * thousand;

export const convertDateToEndOfDay = (date: Date) => {
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  const converted = new Date(date);
  converted.setHours(lastHour);
  converted.setMinutes(lastMinute);
  converted.setSeconds(lastSecond);

  return converted;
};
