import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;

    ${theme.breakpoints.down('xl')} {
      display: none;
    }
  `};
`;

interface IDropdown {
  $isSingle: boolean;
}

export const Dropdown = styled(Wrapper)<IDropdown>`
  ${({ theme, $isSingle }) => css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: ${$isSingle ? 'none' : 'flex'};

    ${theme.breakpoints.down('xl')} {
      display: flex;
    }
  `};
`;

interface IItem {
  $isSelected: boolean;
}

export const Item = styled(Wrapper)<IItem>`
  ${({ $isSelected, theme }) => css`
    padding: 4px 8px;
    cursor: pointer;
    margin: 0 4px;
    border-radius: 5px;
    font-size: ${theme.typography.xl.fontSize};

    ${conditionApply(
      [$isSelected],
      css`
        background-color: ${theme.palette.tab.main};
        color: ${theme.palette.tab.light};
      `
    )}

    ${theme.breakpoints.down('sm')} {
      padding: 4px;
      margin: 0 2px;
      font-size: ${theme.typography.medium.fontSize};
    }
  `};
`;
