import styled, { css } from 'styled-components';
import { Icon, Typography, Wrapper } from '@trader/components';

import { layoutChildrenRadius } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: ${layoutChildrenRadius}px;

    > img {
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  `};
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    width: 430px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px 0 #0000000d;
    background-color: ${theme.palette.background.paper};
    border-radius: 8px;
    padding: 50px 30px 44px 30px;
    z-index: 1;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 100%;
    }

    input {
      font-family: Poppins, serif;
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 45px;
    margin-bottom: 40px;
    font-family: Poppins, serif;
  `};
`;

export const Logo = styled(Icon)`
  ${() => css``};
`;
