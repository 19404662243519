import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const FilterContent = styled(Wrapper)`
  ${({ theme }) => css`
    padding: 8px 10px;
    font-size: ${theme.typography.small.fontSize};
    border-radius: 8px;
  `};
`;

export const ResetButton = styled(Wrapper)`
  ${({ theme }) => css`
    margin-top: 6px;
    font-size: ${theme.typography.small.fontSize};
    color: ${theme.palette.primary.main};
    cursor: pointer;
  `};
`;
