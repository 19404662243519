import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { getMaxDigitWithDot, getStepBasedOnTickSize } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Controller } from '@trader/components';
import { useI18next } from '@trader/services';

import { Protection } from '../Protection';
import { PurchaseDetails } from '../PurchaseDetails';
import { PlaceOrderButton } from '../PlaceOrderButton';

import * as Styled from './styled';
import { SideButtons } from '../sideButtons';
import { ICommonOrderType } from '@trader/types';

interface ILimitOrder extends ICommonOrderType {
  buttonTitle?: string;
  isEditingOrder?: boolean;
  onClick?: () => void;
}

export const LimitOrder: React.FC<ILimitOrder> = observer(
  ({ type, buttonTitle, isEditingOrder = false }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { control, register, formState } = useFormContext();

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;

    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

    const step = getStepBasedOnTickSize(
      instrument.pipSize,
      instrument.tickSize
    );

    return (
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && <SideButtons type={type} />}
          <Styled.Main>
            <Controller
              type='tradingInput'
              isFocus
              customLabel={translate(
                `COMMON.LABELS.${trading.side?.toUpperCase()}_WHEN`
              )}
              disabled={false}
              minValue={0}
              maxValue={0}
              fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
              step={step}
              onCustomChange={() =>
                trading.updateEditMode('priceEditMode', 'Manual')
              }
              control={control}
              {...register('price')}
            />
          </Styled.Main>
          <div style={{ marginBottom: '-5px' }}>
            <Controller
              type='tradingInput'
              shouldCheckActivityOfButtons
              customLabel={translate('COMMON.LABELS.AMOUNT')}
              disabled={isEditingOrder}
              minValue={instrument.minOrderSize}
              maxValue={instrument.maxOrderSize}
              fixDigitAfterDot={trading.amountDigitsAfterDot()}
              step={instrument.minOrderSizeIncrement}
              control={control}
              {...register('amount')}
            />
          </div>
          <PurchaseDetails type={type} />
        </Styled.Root>
        <Protection type={type} />
        <PlaceOrderButton
          type={type}
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
          buttonTitle={buttonTitle}
        />
      </React.Fragment>
    );
  }
);
