import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import * as Styled from './styled';

interface ITabPanel {
  compareValue: string;
  value: string;
  children: React.ReactNode;
}

export interface ITab {
  label?: string | React.ReactNode;
  value: string;
  children: React.ReactNode | string;
  icon?: React.ReactElement | string;
  disabled?: boolean;
  isDefault?: boolean;
}

interface ITabs {
  tabs: ITab[];
  marginBottom?: string;
  padding?: string;
  navigationKey?: string;
  onChange?: (key: string) => void;
  tabsPosition?: 'top' | 'bottom';
  hasNavigationBar?: boolean;
  hasBottomBorder?: boolean;
  withScrollableTabs?: boolean;
  preselectedTab?: string;
  rootStyles?: BoxProps['style'];
}

export const Tabs: React.FC<ITabs> = observer(
  ({
    tabs,
    navigationKey,
    onChange,
    tabsPosition = 'bottom',
    marginBottom,
    padding,
    hasNavigationBar = true,
    hasBottomBorder = true,
    withScrollableTabs = false,
    preselectedTab,
    rootStyles,
  }) => {
    const store = useMst();

    const [selectedTab, setSelectedTab] = React.useState<string>(
      preselectedTab || tabs[0]?.value
    );

    const tab = navigationKey ? store.pages.trading[navigationKey] : undefined;

    const handleChange = (
      _event: React.SyntheticEvent,
      newValue: string
    ): void => {
      setSelectedTab(newValue);
      navigationKey &&
        store.pages.trading.runInAction(() => {
          store.pages.trading[navigationKey] = newValue;
        });
      onChange && onChange(newValue);
    };

    return (
      <Styled.Container $tabsPosition={tabsPosition} style={rootStyles}>
        {tabs.map(t => (
          <TabPanel
            key={t.value}
            value={tab || selectedTab}
            compareValue={t.value}
          >
            {t.children}
          </TabPanel>
        ))}
        {hasNavigationBar && (
          <Styled.AppBar>
            <Styled.Tabs
              $marginBottom={marginBottom}
              $padding={padding}
              $tabsPosition={tabsPosition}
              $hasBottomBorder={hasBottomBorder}
              variant='scrollable'
              value={tab || selectedTab}
              onChange={handleChange}
              scrollButtons={withScrollableTabs}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              {tabs.map(t => (
                <Styled.Tab
                  key={t.value}
                  label={t.label}
                  value={t.value}
                  disabled={t.disabled}
                />
              ))}
            </Styled.Tabs>
          </Styled.AppBar>
        )}
      </Styled.Container>
    );
  }
);

const TabPanel: React.FC<ITabPanel> = ({ value, compareValue, children }) => {
  return (
    <Box
      overflow='auto'
      width='100%'
      height='100%'
      hidden={value !== compareValue}
    >
      {value === compareValue && <Box height='100%'>{children}</Box>}
    </Box>
  );
};
