import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  calcPositionPl,
  getLossPipsFromRate,
  getLossRateFromPips,
  getMaxDigitWithDot,
  getProfitPipsFromRate,
  getProfitRateFromPips,
  getStepBasedOnTickSize,
  handleIndicesPipSize,
} from '@trader/utils';
import {
  IChangeFieldEvent,
  ICommonOrderType,
  IInitialTradingFormValues,
  TAddProtectionType,
  TOptions,
} from '@trader/types';
import { useI18next } from '@trader/services';
import { Controller, Select } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useTriggerTradingFormFields } from '@trader/hooks';
import { maxOrderPipsValue, minOrderPipsValue } from '@trader/constants';

import { useGetMinMaxValues } from './useGetMinMaxValues';
import { Profit } from './components/profit';
import { Loss } from './components/loss';

import * as Styled from './styled';

interface IProtection extends ICommonOrderType {
  openPrice?: string;
}

export const Protection: React.FC<IProtection> = observer(
  ({ openPrice, type }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { control, register, setValue, trigger, getValues } =
      useFormContext<IInitialTradingFormValues>();
    const { triggerProfitFields, triggerLossFields } =
      useTriggerTradingFormFields();

    const { maxTakeProfit, minTakeProfit, minStopLoss, maxStopLoss } =
      useGetMinMaxValues(type);

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;

    const stopLossType = getValues().stopLossType;
    const takeProfitType = getValues().takeProfitType;
    const isStopLoss = getValues().isStopLoss;
    const isTakeProfit = getValues().isTakeProfit;

    const pipSize = handleIndicesPipSize(
      instrument.pipSize,
      instrument?.category || undefined,
      instrument?.minOrderSizeIncrement
    );

    const step = getStepBasedOnTickSize(pipSize, instrument.tickSize);

    const options: TOptions = [
      {
        title: translate('COMMON.LABELS.RATE'),
        value: 'Rate',
        id: 'Rate',
      },
      {
        title: translate('COMMON.LABELS.PIPS'),
        value: 'Pips',
        id: 'Pips',
      },
    ];

    const handleChangeType = (
      event: IChangeFieldEvent<TOptions>,
      protectionType: 'stopLossType' | 'takeProfitType'
    ) => {
      const value = event.target.value[0].value as TAddProtectionType;
      const triggerNameRate =
        protectionType === 'takeProfitType' ? 'takeProfit' : 'stopLoss';
      const triggerNamePips =
        protectionType === 'takeProfitType' ? 'takeProfitPips' : 'stopLossPips';

      setValue(protectionType, value);
      trigger([triggerNameRate, triggerNamePips]);
    };

    const handleToggle = (
      protectionType: 'isTakeProfit' | 'isStopLoss',
      value: boolean
    ) => {
      const triggerNameRate =
        protectionType === 'isTakeProfit' ? 'takeProfit' : 'stopLoss';
      const triggerNamePips =
        protectionType === 'isTakeProfit' ? 'takeProfitPips' : 'stopLossPips';

      setValue(protectionType, value);
      trigger([triggerNameRate, triggerNamePips]);
    };

    const handleChangeTakeProfit = useCallback(
      (event: IChangeFieldEvent) => {
        const value = event.target.value;

        trading.updateEditMode('TPEditMode', 'Manual');

        setValue(
          'takeProfitPips',
          getProfitPipsFromRate(
            value,
            instrument.tickSize,
            trading.side,
            instrument.ask,
            instrument.bid,
            pipSize,
            instrument.category || undefined,
            getValues().orderType !== 'Market' ? getValues().price : undefined
          )
        );
        triggerProfitFields();

        const profit = calcPositionPl({
          side: getValues().side,
          currentPrice: +getValues().takeProfit,
          openPrice: +(openPrice || getValues().price),
          quantity: +getValues().amount,
          pipSize: instrument.pipSize,
          tickValue: instrument.tickValue,
          tickSize: instrument.tickSize,
          contractSize: instrument.contractSize,
          calcMode: instrument.calcMode,
          conversionRate: trading.conversionRate,
        });
        trading.updateProfit(profit);
      },
      [trading.side, instrument.bid, instrument.ask]
    );

    const handleChangeTakeProfitPips = useCallback(
      (event: IChangeFieldEvent) => {
        const value = event.target.value;

        trading.updateEditMode('TPEditMode', 'Manual');

        setValue(
          'takeProfit',
          getProfitRateFromPips(
            value,
            instrument.tickSize,
            trading.side,
            instrument.ask,
            instrument.bid,
            pipSize,
            instrument.category || undefined,
            instrument.pipSize,
            getValues().orderType !== 'Market' ? getValues().price : undefined
          )
        );

        triggerProfitFields();

        const profit = calcPositionPl({
          side: getValues().side,
          currentPrice: +getValues().takeProfit,
          openPrice: +(openPrice || getValues().price),
          quantity: +getValues().amount,
          pipSize: instrument.pipSize,
          tickValue: instrument.tickValue,
          tickSize: instrument.tickSize,
          contractSize: instrument.contractSize,
          calcMode: instrument.calcMode,
          conversionRate: trading.conversionRate,
        });
        trading.updateProfit(profit);
      },
      [trading.side, instrument.bid, instrument.ask]
    );

    const handleChangeStopLoss = useCallback(
      (event: IChangeFieldEvent) => {
        const value = event.target.value;

        trading.updateEditMode('SLEditMode', 'Manual');

        setValue(
          'stopLossPips',
          getLossPipsFromRate(
            value,
            instrument.tickSize,
            trading.side,
            instrument.ask,
            instrument.bid,
            pipSize,
            instrument.category || undefined,
            getValues().orderType !== 'Market' ? getValues().price : undefined
          )
        );
        triggerLossFields();

        const loss = calcPositionPl({
          side: getValues().side,
          currentPrice: +getValues().stopLoss,
          openPrice: +(openPrice || getValues().price),
          quantity: +getValues().amount,
          pipSize: instrument.pipSize,
          tickValue: instrument.tickValue,
          tickSize: instrument.tickSize,
          contractSize: instrument.contractSize,
          calcMode: instrument.calcMode,
          conversionRate: trading.conversionRate,
        });
        trading.updateLoss(loss);
      },
      [trading.side, instrument.bid, instrument.ask]
    );

    const handleChangeStopLossPips = useCallback(
      (event: IChangeFieldEvent) => {
        const value = event.target.value;

        trading.updateEditMode('SLEditMode', 'Manual');

        setValue(
          'stopLoss',
          getLossRateFromPips(
            value,
            instrument.tickSize,
            trading.side,
            instrument.ask,
            instrument.bid,
            pipSize,
            instrument.category || undefined,
            instrument.pipSize,
            getValues().orderType !== 'Market' ? getValues().price : undefined
          )
        );

        triggerLossFields();

        const loss = calcPositionPl({
          side: getValues().side,
          currentPrice: +getValues().stopLoss,
          openPrice: +(openPrice || getValues().price),
          quantity: +getValues().amount,
          pipSize: instrument.pipSize,
          tickValue: instrument.tickValue,
          tickSize: instrument.tickSize,
          contractSize: instrument.contractSize,
          calcMode: instrument.calcMode,
          conversionRate: trading.conversionRate,
        });
        trading.updateLoss(loss);
      },
      [trading.side, instrument.bid, instrument.ask]
    );

    return (
      <Styled.Root>
        <Styled.Content>
          <Styled.Protection>
            <Styled.TakeProfit>
              <Styled.Toggle
                size='small'
                shouldHideControlInfo
                label={translate('COMMON.LABELS.TAKE_PROFIT')}
                checked={isTakeProfit}
                onChange={event =>
                  handleToggle('isTakeProfit', event.target.checked)
                }
              />
              {isTakeProfit && (
                <Styled.InputWrapper>
                  {takeProfitType === 'Rate' && (
                    <Controller
                      type='tradingInput'
                      isFocus
                      shouldCheckActivityOfButtons
                      minValue={minTakeProfit}
                      maxValue={maxTakeProfit}
                      fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
                      step={step}
                      control={control}
                      customLabel={<Profit type={type} openPrice={openPrice} />}
                      middleComponent={
                        <Styled.Select>
                          <Select
                            options={options}
                            value={options.filter(
                              o => o.value === takeProfitType
                            )}
                            onChange={event =>
                              handleChangeType(event, 'takeProfitType')
                            }
                          />
                        </Styled.Select>
                      }
                      onCustomChange={handleChangeTakeProfit}
                      {...register('takeProfit')}
                    />
                  )}
                  {takeProfitType === 'Pips' && (
                    <Controller
                      type='tradingInput'
                      shouldCheckActivityOfButtons
                      minValue={minOrderPipsValue}
                      maxValue={maxOrderPipsValue}
                      fixDigitAfterDot={0}
                      step={1}
                      onCustomChange={handleChangeTakeProfitPips}
                      control={control}
                      customLabel={<Profit type={type} openPrice={openPrice} />}
                      middleComponent={
                        <Styled.Select>
                          <Select
                            options={options}
                            value={options.filter(
                              o => o.value === takeProfitType
                            )}
                            onChange={event =>
                              handleChangeType(event, 'takeProfitType')
                            }
                          />
                        </Styled.Select>
                      }
                      {...register('takeProfitPips')}
                    />
                  )}
                </Styled.InputWrapper>
              )}
            </Styled.TakeProfit>
            <Styled.StopLoss>
              <Styled.Toggle
                size='small'
                shouldHideControlInfo
                label={translate('COMMON.LABELS.STOP_LOSS')}
                checked={isStopLoss}
                onChange={event =>
                  handleToggle('isStopLoss', event.target.checked)
                }
              />
              {isStopLoss && (
                <Styled.InputWrapper>
                  {stopLossType === 'Rate' && (
                    <Controller
                      type='tradingInput'
                      isFocus
                      shouldCheckActivityOfButtons
                      minValue={minStopLoss}
                      maxValue={maxStopLoss}
                      fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
                      step={step}
                      control={control}
                      onCustomChange={handleChangeStopLoss}
                      customLabel={<Loss type={type} openPrice={openPrice} />}
                      middleComponent={
                        <Styled.Select>
                          <Select
                            options={options}
                            value={options.filter(
                              o => o.value === stopLossType
                            )}
                            onChange={event =>
                              handleChangeType(event, 'stopLossType')
                            }
                          />
                        </Styled.Select>
                      }
                      {...register('stopLoss')}
                    />
                  )}
                  {stopLossType === 'Pips' && (
                    <Controller
                      type='tradingInput'
                      shouldCheckActivityOfButtons
                      minValue={minOrderPipsValue}
                      maxValue={maxOrderPipsValue}
                      fixDigitAfterDot={0}
                      step={1}
                      onCustomChange={handleChangeStopLossPips}
                      control={control}
                      customLabel={<Loss type={type} openPrice={openPrice} />}
                      middleComponent={
                        <Styled.Select>
                          <Select
                            options={options}
                            value={options.filter(
                              o => o.value === stopLossType
                            )}
                            onChange={event =>
                              handleChangeType(event, 'stopLossType')
                            }
                          />
                        </Styled.Select>
                      }
                      {...register('stopLossPips')}
                    />
                  )}
                </Styled.InputWrapper>
              )}
            </Styled.StopLoss>
          </Styled.Protection>
        </Styled.Content>
      </Styled.Root>
    );
  }
);
