import styled, { css } from 'styled-components';
import { Typography } from '@trader/components';

export const Root = styled(Typography)`
  ${({ theme }) => css`
    height: 54px;
    text-align: center;
    justify-content: center;
    align-content: center;

    font-size: ${theme.typography.medium.fontSize};
    color: ${theme.palette.gray[800]};

    span {
      padding-left: 5px;
      color: #519ed5;
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`;
