import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  TInstrumentEntity,
  TOrderMetricEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  adjustByTickSize,
  getLimitPrice,
  handleIndicesPipSize,
} from '@trader/utils';

import { useTriggerTradingFormFields } from './useTriggerTradingFormFields';
import { TCommonOrderType } from '@trader/types';
import { defaultPipSize } from '@trader/constants';

export const useUpdatePricesInRealTime = (type: TCommonOrderType) => {
  const store = useMst();
  const { setValue, formState, getValues } = useFormContext();
  const { triggerAllPriceRelatedFields, triggerPriceField } =
    useTriggerTradingFormFields();

  const modal = store.ui.modal;

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const isPriceTouched = formState.touchedFields.price;
  const isPendingOrder = getValues().orderType !== 'Market';

  const orderId = type === 'modifyOrder' && modal.options.get('orderId');
  const positionId = type === 'modifyOrder' && modal.options.get('positionId');

  const orderMetric =
    store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
  const positionMetric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const pipSize = handleIndicesPipSize(
    instrument.pipSize ?? defaultPipSize,
    instrument.category || undefined,
    instrument.minOrderSizeIncrement
  );

  const price =
    orderMetric?.stopPrice ||
    orderMetric?.limitPrice ||
    positionMetric?.openPrice ||
    undefined;

  useEffect(() => {
    if (trading.priceEditMode === 'Chart') {
      setValue('price', trading.chartPrice, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      triggerPriceField();
    }
  }, [trading.chartPrice, trading.priceEditMode]);

  useEffect(() => {
    if (trading.priceEditMode === 'Chart') {
      return;
    }

    if (!isPriceTouched) {
      const limitPrice = getLimitPrice(
        instrument.stopsLevel,
        instrument.tickSize,
        pipSize,
        instrument.bid,
        instrument.ask,
        getValues().side
      );

      setValue(
        'price',
        price?.toString() ||
          adjustByTickSize(
            limitPrice,
            pipSize,
            instrument.tickSize,
            getValues().side,
            'price'
          )
      );
    }
  }, [
    price,
    getValues().side,
    instrument.bid,
    instrument.ask,
    isPriceTouched,
    trading.priceEditMode,
  ]);

  useEffect(() => {
    setValue('ask', instrument.ask);
    isPendingOrder ? triggerPriceField() : triggerAllPriceRelatedFields();
  }, [isPendingOrder, instrument.ask]);

  useEffect(() => {
    setValue('bid', instrument.bid);
    isPendingOrder ? triggerPriceField() : triggerAllPriceRelatedFields();
  }, [isPendingOrder, instrument.bid]);

  useEffect(() => {
    isPendingOrder && triggerAllPriceRelatedFields();
  }, [getValues().price]);
};
