import { TPaymentStatus, TTransferType } from '@trader/api';
import { useI18next } from '@trader/services';

export const useTranslationMaps = () => {
  const { translate } = useI18next();

  const statusTranslationMap: Record<TPaymentStatus, string> = {
    Success: translate('COMMON.LABELS.SUCCESS'),
    Failure: translate('COMMON.LABELS.FAILURE'),
    Pending: translate('COMMON.LABELS.PENDING'),
    Canceled: translate('COMMON.LABELS.CANCELED'),
    TimeOut: translate('COMMON.LABELS.TIME_OUT'),
  };
  const transferTypesTranslationMap: Record<TTransferType, string> = {
    AccountsBalanceToZero: translate('TRANSFER_TYPES.ACCOUNTS_BALANCE_TO_ZERO'),
    Adjustment: translate('TRANSFER_TYPES.ADJUSTMENT'),
    AdjustmentConversionFee: translate(
      'TRANSFER_TYPES.ADJUSTMENT_CONVERSION_FEE'
    ),
    Bonus: translate('TRANSFER_TYPES.BONUS'),
    Bust: translate('TRANSFER_TYPES.BUST'),
    CfdRollover: translate('TRANSFER_TYPES.CFD_ROLLOVER'),
    CfdRolloverConversionFee: translate(
      'TRANSFER_TYPES.CFD_ROLLOVER_CONVERSION_FEE'
    ),
    CfdRolloverFeePerPosition: translate(
      'TRANSFER_TYPES.CFD_ROLLOVER_FEE_PER_POSITION'
    ),
    CfdRolloverPerPosition: translate(
      'TRANSFER_TYPES.CFD_ROLLOVER_PER_POSITION'
    ),
    ChangeMarginParameters: translate(
      'TRANSFER_TYPES.CHANGE_MARGIN_PARAMETERS'
    ),
    CloseCommission: translate('TRANSFER_TYPES.CLOSE_COMMISSION'),
    Commission: translate('TRANSFER_TYPES.FEE'),
    Fee: translate('TRANSFER_TYPES.FEE'),
    Deposit: translate('TRANSFER_TYPES.DEPOSIT'),
    DividendsConversionFee: translate(
      'TRANSFER_TYPES.DIVIDENDS_CONVERSION_FEE'
    ),
    DividendsPerPosition: translate('TRANSFER_TYPES.DIVIDENDS_PER_POSITION'),
    Financing: translate('TRANSFER_TYPES.FINANCING'),
    FinancingConversionFee: translate(
      'TRANSFER_TYPES.FINANCING_CONVERSION_FEE'
    ),
    FixBalance: translate('TRANSFER_TYPES.FIX_BALANCE'),
    GslPremium: translate('TRANSFER_TYPES.GSL_PREMIUM'),
    ManualCorrection: translate('TRANSFER_TYPES.MANUAL_CORRECTION'),
    OpenCommission: translate('TRANSFER_TYPES.OPEN_COMMISSION'),
    OvernightSwapConversionFee: translate(
      'TRANSFER_TYPES.OVERNIGHT_SWAP_CONVERSION_FEE'
    ),
    OvernightSwapFee: translate('TRANSFER_TYPES.OVERNIGHT_SWAP_FEE'),
    OvernightSwapPerPosition: translate(
      'TRANSFER_TYPES.OVERNIGHT_SWAP_PER_POSITION'
    ),
    PortfolioManagementCommission: translate(
      'TRANSFER_TYPES.PORTFOLIO_MANAGEMENT_COMMISSION'
    ),
    PortfolioPerformanceCommission: translate(
      'TRANSFER_TYPES.PORTFOLIO_PERFORMANCE_COMMISSION'
    ),
    PositionUpdate: translate('TRANSFER_TYPES.POSITION_UPDATE'),
    SplitCorrectionPerPosition: translate(
      'TRANSFER_TYPES.SPLIT_CORRECTION_PER_POSITION'
    ),
    StockSplitAdjustment: translate('TRANSFER_TYPES.STOCK_SPLIT_ADJUSTMENT'),
    StockSplitConversionFee: translate(
      'TRANSFER_TYPES.STOCK_SPLIT_CONVERSION_FEE'
    ),
    Withdrawal: translate('TRANSFER_TYPES.WITHDRAWAL'),
    TransferOut: translate('TRANSFER_TYPES.TRANSFER_OUT'),
    TransferIn: translate('TRANSFER_TYPES.TRANSFER_IN'),
  };

  return {
    statusTranslationMap,
    transferTypesTranslationMap,
  };
};
