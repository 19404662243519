import React from 'react';
import styled, { css } from 'styled-components';
import {
  Box,
  BoxProps,
  FormControlLabel as FormControlLabelMui,
  Checkbox as CheckboxMui,
} from '@mui/material';

import { conditionApply } from '@trader/utils';

import { ContainerControlInfo } from '../controlInfo/styled';
import { ICommonStyledProps } from '@trader/types';

const opacity = 0.3;

export const Checkbox = styled(CheckboxMui)``;

export const FormControlLabel = styled(FormControlLabelMui)`
  ${({ theme }) => css`
    align-items: flex-start;
    user-select: none;
    margin-right: 0;

    span {
      ${theme.breakpoints.down('sm')} {
        font-size: ${theme.typography.default.fontSize};
      }
    }
  `}
`;

interface IContainerProps extends BoxProps, ICommonStyledProps {
  checked?: boolean;
  children?: React.ReactNode;
}

export const Container = styled<React.FC<IContainerProps>>(
  ({ checked, $isDisabled, $isError, ...rest }) => <Box {...rest} />
)`
  ${({ checked, $isDisabled, $isError, theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: -6px;
    }

    .MuiFormControlLabel-label {
      align-self: center;
    }

    ${Checkbox} {
      ${conditionApply(
        [$isError],
        css`
          color: ${theme.palette.error.main};
        `
      )}

      ${conditionApply(
        [checked && $isDisabled],
        css`
          color: ${theme.palette.primary.main};
          opacity: ${$isDisabled ? opacity : 1};
        `
      )}

      ${conditionApply(
        [checked],
        css`
          color: ${theme.palette.purchaseButtons.green};
        `
      )}
    }

    ${ContainerControlInfo} {
      margin-left: 15px;
      margin-top: -12px;
    }
  `}
`;
