import styled, { css } from 'styled-components';
import { Wrapper, Typography, Button } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 380px;
    height: 130px;
    padding: 35px 36px 0 36px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Content = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    width: 100%;
    margin-bottom: 10px;
    white-space: normal;
    overflow: visible;
  `};
`;

export const Body = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: space-between;
    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

interface IButton {
  $isFailed?: boolean;
}

export const Cta = styled(Button)<IButton>`
  ${({ theme, $isFailed }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.common.white};
    background: ${$isFailed
      ? theme.palette.text.primary
      : theme.palette.primary.main};
    font-weight: ${theme.typography.fontWeightMedium};

    &:hover {
      background: ${$isFailed
        ? theme.palette.text.primary
        : theme.palette.primary.main};
    }

    > span {
      text-transform: uppercase;
    }
  `};
`;
