import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    position: relative;

    > label {
      display: flex;
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      line-height: 23px;

      > div {
        margin-left: 10px;
      }
    }

    input {
      width: 100%;
      border: 1px solid ${theme.palette.inputField.border};
      border-radius: 5px;
      padding: 4.5px 20px 4.5px 10px;
      color: ${theme.palette.inputField.text};
      background: ${theme.palette.inputField.background};
      font-size: ${theme.typography.default.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};

      &::placeholder {
        color: ${theme.palette.common.black};
      }

      &:disabled {
        background-color: ${theme.palette.inputField.disabledBg};
        color: ${theme.palette.inputField.disabledText};

        &::placeholder {
          color: ${theme.palette.inputField.disabledText};
        }
      }

      &:focus {
        outline: none;
        border: 1px solid ${theme.palette.primary.main};
      }
    }
  `};
`;

export const Icon = styled(ExpandMoreIcon)<{ $isDisabled?: boolean }>`
  ${({ theme, $isDisabled }) => css`
    position: absolute;
    right: 5px;
    width: 16px;
    ${conditionApply(
      [$isDisabled],
      css`
        color: ${theme.palette.inputField.disabledText};
      `
    )}
  }
  `};
`;
