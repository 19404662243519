import React from 'react';

import * as Styled from './styled';

interface ILineChart {
  values: number[];
  chartValue: number;
}

export const LineChart: React.FC<ILineChart> = ({ values, chartValue }) => {
  const getFilledWidth = () => {
    if (!values.length) return 0;

    const min = Math.min(...values);
    const max = Math.max(...values);

    if (max === min) return 0;

    // eslint-disable-next-line no-magic-numbers
    const percentage = (chartValue * 100) / max;
    return +percentage.toFixed(2);
  };

  return <Styled.LineChart $filledWidth={getFilledWidth()} />;
};
