import { localStorageService } from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';

import { getTimeInSeconds } from '../dateUtils';

export const setUserActivityToStorage = () => {
  localStorageService.set(
    LOCAL_STORAGE_KEYS.leftPageTimestamp,
    JSON.stringify(new Date().getTime())
  );
};

export const checkUserActivitySecondsFromStorage = (
  secondsWithNoActivity: number,
  setSeconds: (seconds: number) => void
) => {
  const currentTime = new Date().getTime();

  const leftPageTimestamp = localStorageService.get(
    LOCAL_STORAGE_KEYS.leftPageTimestamp
  );

  if (!leftPageTimestamp) {
    return 0;
  }

  const timeInSeconds = getTimeInSeconds(currentTime - +leftPageTimestamp);

  setSeconds(timeInSeconds + secondsWithNoActivity);
};
