import React from 'react';
import { FormControlLabel as ControlLabel } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';

import { ControlInfo } from '../controlInfo';

import * as Styled from './styled';

export interface ISwitcher
  extends Omit<SwitchProps, 'helperText' | 'onChange' | 'title' | 'checked'> {
  shouldHideControlInfo?: boolean;
  isError?: boolean;
  helperText?: string;
  label?: string;
  leftLabel?: string;
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // remove second arg: checked for one signature of diff inputs onChange cb
}

export const Switcher = React.forwardRef<HTMLButtonElement, ISwitcher>(
  (props, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange && props.onChange(event);
    };

    return (
      <Styled.Container
        className={props.className}
        checked={!!props.value}
        $isDisabled={props.disabled}
        $isError={props.isError}
      >
        {props.leftLabel && (
          <Styled.LeftLabel className='switcher-left-label'>
            {props.leftLabel}
          </Styled.LeftLabel>
        )}
        <ControlLabel
          disabled={props.disabled}
          style={{ margin: 0 }}
          control={
            <Styled.Switcher
              ref={ref}
              {...getValidProps(props)}
              checked={!!props.value}
              onChange={handleChange}
              onBlur={props.onBlur}
              color='primary'
            />
          }
          label={props.label}
        />
        <ControlInfo
          helperText={props.helperText}
          isError={props.isError}
          isDisabled={props.disabled}
          isHide={props.shouldHideControlInfo}
        />
      </Styled.Container>
    );
  }
);

const getValidProps = (props: ISwitcher): Partial<ISwitcher> => {
  const {
    type, // 'switcher'
    label,
    className,
    shouldHideControlInfo,
    helperText,
    isError,
    leftLabel,
    ...rest
  } = props;
  return rest;
};
