import { useTheme } from '@mui/material';
import React from 'react';

import * as Styled from './styled';

export interface IRadioButton {
  className?: string;
  value?: string;
  label: string | React.ReactNode;
  disabled?: boolean;
  helperText?: string;
  isError?: boolean;
}

export const RadioButton = React.forwardRef<HTMLInputElement, IRadioButton>(
  ({ helperText, isError, ...props }, ref) => {
    const theme = useTheme();

    return (
      <Styled.LabelContainer
        {...props}
        ref={ref}
        label={props.label}
        control={
          <Styled.Radio
            icon={<Styled.Icon />}
            checkedIcon={<Styled.CheckedIcon />}
          />
        }
        sx={{
          '& .MuiFormControlLabel-label': {
            width: '100%',
            fontSize: theme.typography.medium.fontSize,
          },
        }}
      />
    );
  }
);
RadioButton.defaultProps = {
  disabled: false,
};
