import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';

interface IType {
  $isBuy: boolean;
  $variant: 'shrink' | 'fit-content';
}

export const Type = styled(Typography)<IType>`
  ${({ theme, $isBuy, $variant }) => css`
    font-weight: ${theme.typography.fontWeightBold};
    text-transform: uppercase;
    color: ${theme.palette.white.main};
    background: ${$isBuy
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
    max-width: 80px;
    padding: 0 5px;
    box-sizing: border-box;
    height: 18px;
    border-radius: 3px;

    ${conditionApply(
      [$variant === 'shrink'],
      css`
        width: auto;
        margin: 0 auto;
      `
    )}

    ${conditionApply(
      [$variant === 'fit-content'],
      css`
        width: fit-content;
        max-width: unset;
        margin: 0;
      `
    )}
  `};
`;
