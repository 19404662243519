import styled, { css } from 'styled-components';

export const LineChart = styled('div')<{ $filledWidth: number }>`
  ${({ theme, $filledWidth }) => css`
    width: 125px;
    height: 9px;
    background-color: ${theme.palette.gray.main};
    border-radius: 5px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${$filledWidth}%;
      height: 100%;
      background-color: ${theme.palette.green.dark};
      border-radius: 5px;
    }
  `};
`;
