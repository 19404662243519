import React, { useEffect, useState } from 'react';

import { convertDateToUTC } from '@trader/utils';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

const oneSecond = 1000;

export const Time: React.FC = React.memo(() => {
  const [state, setState] = useState(new Date());
  const { currentLng } = useI18next();

  useEffect(() => {
    const timer = setInterval(() => {
      setState(new Date());
    }, oneSecond);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Styled.Time>{convertDateToUTC(state, true, currentLng)}</Styled.Time>;
});
