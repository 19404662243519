import { useEffect } from 'react';

import {
  betaMaxSideBarWidth,
  LOCAL_STORAGE_KEYS,
  MODAL_TYPES,
} from '@trader/constants';
import { useMst } from '@trader/store';
import { localStorageService } from '@trader/services';
import { useMediaQuery, useTheme } from '@mui/material';
import { EDesignType } from '@trader/types';

/**
 * Initializes the beta design experience for users.
 */
export const useInitializeBetaDesign = () => {
  const store = useMst();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAuth = store.auth.isAuth;

  useEffect(() => {
    // do not use beta design for phones
    if (isMobile) {
      store.app.runInAction(() => {
        store.app.designType = EDesignType.STABLE;
      });
    } else {
      const isBetaDesignInitialized = localStorageService.get(
        LOCAL_STORAGE_KEYS.isBetaDesignInitialized
      ) as null | 'true';

      if (isAuth && isBetaDesignInitialized !== 'true') {
        // Switch to beta.
        store.ui.sidebar.updateLeftBarWidth(betaMaxSideBarWidth);
        store.app.toggleDesignType();

        store.ui.modal.open(MODAL_TYPES.switchingToBetaDesign, {
          shouldHideCloseButton: true,
        });
        localStorageService.set(
          LOCAL_STORAGE_KEYS.isBetaDesignInitialized,
          'true'
        );
      }
    }
  }, [isAuth, isMobile]);
};
