import React from 'react';
import { BoxProps } from '@mui/material';

import { CircularProgressBar, Typography } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IGoalReached extends BoxProps {
  value?: string | number;
  hasTitle?: boolean;
  hasProgressBar?: boolean;
}

export const GoalReached: React.FC<IGoalReached> = ({
  value,
  hasTitle,
  hasProgressBar,
  ...boxProps
}) => {
  const { translate } = useI18next();
  const fixedValue = Number((value as number)?.toFixed(0)) || 0;

  if (hasProgressBar) {
    return <CircularProgressBar value={fixedValue} text={`${fixedValue}%`} />;
  }

  if (value !== undefined && !hasTitle) {
    return <Styled.ValueLabel variant='tiny'>{fixedValue}%</Styled.ValueLabel>;
  }

  return (
    <Styled.Root {...boxProps}>
      <Typography variant='tiny' textTransform='capitalize'>
        {translate('COMMON.LABELS.GOAL_REACHED')}
      </Typography>
      <Typography fontWeight='bold'>{fixedValue}%</Typography>
    </Styled.Root>
  );
};
